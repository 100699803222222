import SMPNum from "@/SMPNum";
import SupportSkillsConstant from "@/constants/SupportSkillsConstants";

class Chain5 {
     constructor(service, successor) {
          this.service = service;
          this.successor = successor;
     }
     handleRequest(payload){
          //get only required param
          const {
               cumulatedGoldWon,
               // goldRemainFromHeroSpent,
               ratioAllocatedToSupport,
               ratioAllocatedToSupportLevel,
               // ratioSupportSkill,
               // totalGoldHeroSkillHaveSpent,
               isUseSupportSkillDmg,
               petList,
               gameLevel,
               isUsePetSkillDmg,
               itemImpactBonus,
               teamBattleInfo,
               isUseHeroSkillDmg,
               percentControlHeroDmgSkill,
               heroActiveSkillList,
               heroBossSkillList,
               isUseThunderAttackSkill,
               balSupportLevelBelowBoss,
               levelSimulateData,
          } = payload;


          //define gold available for #SUPPORT
          const goldAvailableForSupport = this.service.computeGoldAvailableFromRatio(cumulatedGoldWon, ratioAllocatedToSupport);
          let goldForSupportLevel = this.service.computeGoldAvailableFromRatio(goldAvailableForSupport, ratioAllocatedToSupportLevel);
          // const goldAvailableForSupportSkill = this.service.computeGoldAvailableFromRatio(goldAvailableForSupport, ratioSupportSkill);
          // let goldForSupportUnlock = this.service.computeGoldAvailableFromRatio(goldAvailableForSupport, 100 - (ratioAllocatedToSupportLevel + ratioSupportSkill));

          //unlock support to get free level, so we count goldUnlock and goldLevel was merged
          //  const goldAvailableForSupportUnlock = goldForSupportLevel;//SMPNum.plus(goldForSupportLevel, goldForSupportUnlock);

          const unlockFlyingSupportCounter = this.service.getFlyingSupportPossibleUnlock(heroBossSkillList);

          //define #SUPPORT COUNT
          // const {
          //      totalUnlockSupport: supportCount,
          //      goldRemain,
          //      supportIdsUnlock,
          //      //nextUnlockInfo
          // } = this.service.definePossibleUnlockSupportCounter(goldAvailableForSupportUnlock, gameLevel, unlockFlyingSupportCounter, balSupportLevelBelowBoss);

          // const goldAvailableForSupportLevel = goldRemain;

          //support list
          //const supportList = this.service.supportList(supportIdsUnlock);

          //get support progress from json
          const {supportList, supportCount, supportLevel} = this.service.getupportListProgress(levelSimulateData);

          // let balanceLevel = gameLevel - balSupportLevelBelowBoss;
          // if(balanceLevel < 1) {
          //      balanceLevel = 1;
          // }

          // const {
          //      levelPossible: supportLevel,
          //      //skillPossibleLevel: supportSkillLevel,
          //      supportUnlockAndLvSpent,
          //      totalSkillSpent,
          // } = this.service.defineLevelForSupportPossibleReach(goldAvailableForSupportLevel, goldAvailableForSupportSkill, supportList, supportCount, balanceLevel);

          // let totalSupportSpent = SMPNum.plus(supportUnlockAndLvSpent, totalSkillSpent);
          // let goldAfterSupportSpent = new SMPNum(0);
          // if(SMPNum.greaterThan(goldAvailableForSupport, totalSupportSpent)){
          //      goldAfterSupportSpent = SMPNum.minus(goldAvailableForSupport, totalSupportSpent);
          // }

          // const cumulatedGoldSupportSpent = totalSupportSpent;
          // const goldRemainFromSupportSpent = goldAfterSupportSpent;
          const supportActiveCount = this.service.kpiSupportActiveCounter(supportCount);

          const skillGoldBonus = this.service.defineSupportBonusSkill(SupportSkillsConstant.GOLD_DROP, supportList);
          const skillTapDmg = this.service.defineSupportBonusSkill(SupportSkillsConstant.TAP_DAMAGE, supportList);
          const skillSupportDmg = this.service.defineSupportBonusSkill(SupportSkillsConstant.SUPPORT_DAMAGE, supportList);
          const skillAllDmg = this.service.defineSupportBonusSkill(SupportSkillsConstant.ALL_DAMAGE, supportList);
          const skillCatBonus = this.service.defineSupportBonusSkill(SupportSkillsConstant.CAT_BONUS, supportList);
          const skillCriticalChance = this.service.defineSupportBonusSkill(SupportSkillsConstant.CRITICAL_CHANCE, supportList);
          const skillManaBonus = this.service.defineSupportBonusSkill(SupportSkillsConstant.MANA_BONUS, supportList);
          const skillCriticalDmg = this.service.defineSupportBonusSkill(SupportSkillsConstant.CRITICAL_DMG, supportList);
          const skillTapDmgFromTotalDps = this.service.defineSupportBonusSkill(SupportSkillsConstant.TAP_DAMAGE_TOTAL_DPS, supportList);

          const supportSkills = {
               skillGoldBonus,
               skillTapDmg,
               skillSupportDmg,
               skillAllDmg,
               skillCatBonus,
               skillCriticalChance,
               skillManaBonus,
               skillCriticalDmg,
               skillTapDmgFromTotalDps,
          };

          //support level update
          //let possibleLevel = supportLevel.ToIntValue();
          //let goldForMoreLevel = new SMPNum(0);
          //goldForMoreLevel.setFromSMPNum(goldRemainFromSupportSpent);
          let supportsHaveUnlock = [];
          supportList.forEach(supports => {
               supports.forEach(support =>{
                    if(support.isUnlock){//possibleLevel > 0){
                         supportsHaveUnlock.push(support);
                         // let moreLevelSpent = this.service.defineLevelForOneSupportPossibleReach(goldForMoreLevel, support.possibleLevel);
                         // support.possibleLevel = moreLevelSpent.levelPossible;
                         // goldForMoreLevel = SMPNum.minus(goldForMoreLevel, moreLevelSpent.supportLevelSpent);
                         //
                         // if(support.isUnlock){
                         //      supportsHaveUnlock.push(support);
                         // }
                    }
                    // else{
                    //      support.possibleLevel = 0;
                    // }
               });
          });
          //goldRemainFromSupportSpent.setFromSMPNum(goldForMoreLevel);

          this.service.defineBestSupportTeam(teamBattleInfo, supportsHaveUnlock, isUseHeroSkillDmg);//isUseHeroSkillDmg equal to use flying support skill

          //update best support
          let balanceLevel = gameLevel - balSupportLevelBelowBoss;
          if(balanceLevel < 1) {
               balanceLevel = 1;
          }
          this.service.defineBestSupportLevelProgress(supportList, teamBattleInfo, balanceLevel, goldForSupportLevel)


          let heroSkillPayload = {
               isUseHeroSkillDmg,
               percentControlHeroDmgSkill,
               heroActiveSkillList,
               heroBossSkillList,
               isUseThunderAttackSkill
          };

          const supportPayload = {supportList, supportCount, supportLevel, isUseSupportSkillDmg, itemImpactBonus,teamBattleInfo};
          const petPayload = {petList, isUsePetSkillDmg};
          const supportDps = this.service.computeSupportDpsStandard(supportPayload, petPayload, heroSkillPayload, unlockFlyingSupportCounter);
          const allSupportDps = this.service.computeAllSupportDps(supportList);

          const heroHpCapSessionFromSupport = this.service.getHeroHpCapSessionFromSupport(teamBattleInfo, supportsHaveUnlock);
          const lastSupportUnlockList = supportsHaveUnlock;

          //ending gold
          const goldEnding = SMPNum.fromNum(0);//SMPNum.plus(goldRemainFromHeroSpent, goldRemainFromSupportSpent);

          //prepare new payload
          let newPayload = {
               // goldAvailableForSupportUnlock,
               // goldAvailableForSupport,
               // goldAvailableForSupportLevel,
               // goldAvailableForSupportSkill,
               supportCount,
               // goldRemain,
               supportLevel,
               supportActiveCount,
               supportSkills,
               goldEnding,
               supportDps,
               allSupportDps,
               supportList,
               // cumulatedGoldSupportSpent,
               // goldRemainFromSupportSpent,
               heroHpCapSessionFromSupport,
               unlockFlyingSupportCounter,
               lastSupportUnlockList,
          };

          //merge old and new to return payload
          let returnPayload = Object.assign({}, payload, newPayload)

          //forward to another successor or return
          if (this.successor) {
               return this.successor.handleRequest(returnPayload);
          } else {
               return returnPayload;
          }
     }
}
export default Chain5;