let SMPNum = require("../../SMPNum");

let MARKET_OVER_NEED_COLOR = 'orange';
let MARKET_REQUIRE_ITEM_COLOR = 'red';
let MARKET_REQUIRE_ENOUGH_COLOR = 'green';

class KpiItemRequireUtils {
    constructor(gamePlayService) {
        this.gamePlayService = gamePlayService;
    }

    getGamePlayRequireStatus(itemRequirePayload){
        let require = [];
        if(itemRequirePayload){

            if(itemRequirePayload.hpRequire){
                require.push('hp');
            }
            if(itemRequirePayload.dmgRequire){
                require.push('dmg');
            }
        }
        return require;
    }

    getMarketItemMatchWithGamePlayRequireStatus(itemRequirePayload, marketWillBonusPayload){
        let require = [];
        if(itemRequirePayload){
            if(itemRequirePayload.hpRequire && SMPNum.greaterThan(itemRequirePayload.hpRequire.require, marketWillBonusPayload.hpItemBonus)){
                require.push('hp');
            }
            if(itemRequirePayload.dmgRequire && SMPNum.greaterThan(itemRequirePayload.dmgRequire.require, marketWillBonusPayload.dmgItemBonus)){
                require.push('dmg');
            }
        }
        return require;
    }

    getGamePlayRequireStatusWithAmount(itemRequirePayload){
        let require = [];
        if(itemRequirePayload){

            if(itemRequirePayload.hpRequire){
                require.push('hp : '+itemRequirePayload.hpRequire.require.ToReadableAlphabetV2());
            }
            if(itemRequirePayload.dmgRequire){
                require.push('dmg : '+itemRequirePayload.dmgRequire.require.ToReadableAlphabetV2());
            }

            let reviveRequire = '';
            if(itemRequirePayload.reviveHeroRequire){
                reviveRequire = 'revive: Hero';
            }
            if(itemRequirePayload.reviveSupportRequire){
                if(reviveRequire === ''){
                    reviveRequire = 'revive: Support';
                } else {
                    reviveRequire += ', Support';
                }
            }
            if(reviveRequire !== ''){
                require.push(reviveRequire);
            }
        }
        return require;
    }

    getMarketShopItemStatusWithAmount(gameLevel, itemRequirePayload, marketWillBonusPayload){
        let require = [];
        if(marketWillBonusPayload){
            if(marketWillBonusPayload.hpItemBonus && !marketWillBonusPayload.hpItemBonus.isZero){
                let hpFromItemBaseTarget = this.gamePlayService.getBossDmgBalance(gameLevel);
                require.push(this.getMarketShopItemStatusWithAmountHelper('hp', itemRequirePayload.hpRequire, marketWillBonusPayload.hpItemBonus, hpFromItemBaseTarget));
            }
            if(marketWillBonusPayload.dmgItemBonus && !marketWillBonusPayload.dmgItemBonus.isZero){
                let dmgFromItemBassTarget = this.gamePlayService.getBossHP(gameLevel);
                require.push(this.getMarketShopItemStatusWithAmountHelper('dmg', itemRequirePayload.dmgRequire, marketWillBonusPayload.dmgItemBonus, dmgFromItemBassTarget));
            }

            let reviveItemMsg = '';
            if(marketWillBonusPayload.reviveHeroItem && marketWillBonusPayload.reviveHeroItem > 0){
                let color = itemRequirePayload.reviveHeroRequire ? MARKET_REQUIRE_ENOUGH_COLOR : MARKET_OVER_NEED_COLOR;
                reviveItemMsg = this.getTextInHtmlColor(`revive : Hero`, color);
            }
            if(marketWillBonusPayload.reviveSupportItem && marketWillBonusPayload.reviveSupportItem > 0){
                let color = itemRequirePayload.reviveSupportRequire ? MARKET_REQUIRE_ENOUGH_COLOR : MARKET_OVER_NEED_COLOR;
                if(reviveItemMsg === ''){
                    reviveItemMsg = this.getTextInHtmlColor(`revive : Hero`, color);
                } else {
                    reviveItemMsg += this.getTextInHtmlColor(`, Support`, color);
                }
            }
            if(reviveItemMsg !== ''){
                require.push(reviveItemMsg);
            }
        }
        return require;
    }

    getMarketItemMatchWithGamePlayRequireStatusWithAmount(gameLevel, itemRequirePayload, marketWillBonusPayload){
        let require = [];
        if(itemRequirePayload){
            if(itemRequirePayload.hpRequire && SMPNum.greaterThan(itemRequirePayload.hpRequire.require, marketWillBonusPayload.hpItemBonus)){
                let hpFromItemBaseTarget = this.gamePlayService.getBossDmgBalance(gameLevel);
                require.push(this.getMarketItemMatchWithGamePlayRequireStatusWithAmountHelper('hp', itemRequirePayload.hpRequire, marketWillBonusPayload.hpItemBonus, hpFromItemBaseTarget));
            }
            if(itemRequirePayload.dmgRequire && SMPNum.greaterThan(itemRequirePayload.dmgRequire.require, marketWillBonusPayload.dmgItemBonus)){
                let dmgFromItemBassTarget = this.gamePlayService.getBossHP(gameLevel);
                require.push(this.getMarketItemMatchWithGamePlayRequireStatusWithAmountHelper('dmg', itemRequirePayload.dmgRequire, marketWillBonusPayload.dmgItemBonus, dmgFromItemBassTarget));
            }

            let reviveItemMsg = '';
            if(itemRequirePayload.reviveHeroRequire && marketWillBonusPayload.reviveHeroItem === undefined){
                reviveItemMsg = this.getTextInHtmlColor(`revive : Hero`, MARKET_REQUIRE_ITEM_COLOR);
            }
            if(itemRequirePayload.reviveSupportRequire && marketWillBonusPayload.reviveSupportItem === undefined){
                if(reviveItemMsg === ''){
                    reviveItemMsg = this.getTextInHtmlColor(`revive : Hero`, MARKET_REQUIRE_ITEM_COLOR);
                } else {
                    reviveItemMsg += this.getTextInHtmlColor(`, Support`, MARKET_REQUIRE_ITEM_COLOR);
                }
            }
            if(reviveItemMsg !== ''){
                require.push(reviveItemMsg);
            }
        }
        return require;
    }

    getMarketItemMatchWithGamePlayRequireWithoutAnyMarketItem(gameLevel, itemRequirePayload, marketWillBonusPayload){
        let payload = {
            hpRequirePercent: 0,
            dmgRequirePercent: 0,
            hpMarketPercent: 0,
            dmgMarketPercent: 0,
            reviveHeroRequire: 0,
            reviveSupportRequire: 0,
        };
        if(itemRequirePayload){
            if(itemRequirePayload.hpRequire){
                let hpFromItemBaseTarget = this.gamePlayService.getBossDmgBalance(gameLevel);
                payload.hpRequirePercent = this.getMarketItemMatchWithGamePlayRequireAmountHelper(itemRequirePayload.hpRequire, hpFromItemBaseTarget);
                payload.hpMarketPercent = this.getRequireInPercentage(marketWillBonusPayload.hpItemBonus, hpFromItemBaseTarget).ToDoubleValue();
            }
            if(itemRequirePayload.dmgRequire){
                let dmgFromItemBassTarget = this.gamePlayService.getBossHP(gameLevel);
                payload.dmgRequirePercent = this.getMarketItemMatchWithGamePlayRequireAmountHelper(itemRequirePayload.dmgRequire, dmgFromItemBassTarget);
                payload.dmgMarketPercent = this.getRequireInPercentage(marketWillBonusPayload.dmgItemBonus, dmgFromItemBassTarget).ToDoubleValue();
            }
            if(itemRequirePayload.reviveHeroRequire){
                payload.reviveHeroRequire = itemRequirePayload.reviveHeroRequire;
            }
            if(itemRequirePayload.reviveSupportRequire){
                payload.reviveSupportRequire = itemRequirePayload.reviveSupportRequire;
            }
        }
        return payload;
    }

    getRequireInPercentage(amount, target){
        let ps = SMPNum.fromNum(100);
        let ms = SMPNum.multSmpNum(amount, ps);
        if(!target.isZero && SMPNum.greaterThan(ms, target)){
            return SMPNum.divSmpNum(ms, target);
        } else {
            return SMPNum.fromNum(0);
        }
    }

    getTextInHtmlColor(text, color){
        return '<font color='+color+'>'+text+'</font>';
    }

    getMarketShopItemStatusWithAmountHelper(title ,requirePayLoad, itemBonus, bonusItemBaseTarget){
        if(requirePayLoad){
            if(SMPNum.greaterThan(itemBonus, requirePayLoad.require)){
                let noneUse = SMPNum.minus(itemBonus, requirePayLoad.require);
                let rqPercent = this.getRequireInPercentage(noneUse, bonusItemBaseTarget);
                let textEnough = this.getTextInHtmlColor(`${title} : ${itemBonus.ToReadableAlphabetV2()}`, MARKET_REQUIRE_ENOUGH_COLOR);
                if(rqPercent.ToReadableAlphabetV2() !== '0'){
                    let noneUseText = this.getTextInHtmlColor(` (^${rqPercent.ToReadableAlphabetV2()}%)`, MARKET_OVER_NEED_COLOR);
                    return `${textEnough} ${noneUseText}`;
                } else {
                    return textEnough;
                }
            } else {
                return this.getTextInHtmlColor(`${title} : ${itemBonus.ToReadableAlphabetV2()}`, MARKET_REQUIRE_ITEM_COLOR);
            }
        } else {
            return `${title} : ${itemBonus.ToReadableAlphabetV2()}`;
        }
    }

    getMarketItemMatchWithGamePlayRequireStatusWithAmountHelper(title ,requirePayLoad, itemBonus, bonusItemBaseTarget){
        let rq = SMPNum.minus(requirePayLoad.require, itemBonus);
        let rqPercent = this.getRequireInPercentage(rq, bonusItemBaseTarget);
        let rqPercentText = `(${rqPercent.ToReadableAlphabetV2()}%)`;
        return this.getTextInHtmlColor(`${title} : ${rq.ToReadableAlphabetV2()} ${rqPercentText}`, MARKET_REQUIRE_ITEM_COLOR);
    }

    getMarketItemMatchWithGamePlayRequireAmountHelper(requirePayLoad, bonusItemBaseTarget){
        return this.getRequireInPercentage(requirePayLoad.require, bonusItemBaseTarget).ToDoubleValue();
    }
}

export default KpiItemRequireUtils;