import axios from "axios";import NetworkUtils from "@/NetworkUtils";
import store from "../../store";
const authorize =  `Bearer ${store.getters.getToken}`;
const config = {
    headers: { Authorization: authorize }
};

export default {
    async loadLevelDataLocal(level) {
        //let data = require(`../assets/levelsSimulator/userdata_RLV_${level}.json`);
        let data = await axios.get(NetworkUtils.HOST + "/autoplay-json/getByLevel?level="+level, config);
        if (data !== undefined && data.data.length > 0) {
            return data.data[0];
        }
        return undefined;
    },

    async loadAutoPlayStateDataLocal(level){
        //let data = require(`../assets/json/Automate_GameState.json`);
        let data = await axios.get(NetworkUtils.HOST + "/autoplay-game-state/getByLevel?level="+level, config);
        if (data !== undefined && data.data.length > 0) {
            return data.data.find(d => d.stage === level);
        }
        return undefined;
    }
}