<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-card elevation="0" min-width="1000" light>
        <HeaderPopup
                @save="save()"
                @close="close()"
                :pCanSave="pCanSave"
        >
            SIBLING GENERATION
        </HeaderPopup>

        <v-container class="grey lighten-5">
            <v-form ref="form" class="mx-2" lazy-validation>
                <v-row v-if="pCanSave">
                    <v-col>
                        <h4>Start Level</h4>
                        <v-row no-gutters dense class="my-3 ml-3">
                            <v-col>
                                <v-text-field
                                        v-model="itemGroupData.config.startLevel"
                                        label="level"
                                        clearable
                                        dense
                                        :rules="rulesNotEmptyAndHigherThan('start level', 0)"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col>
                        <h4>End Level</h4>
                        <v-row no-gutters dense class="my-3 ml-3">
                            <v-col>
                                <v-text-field
                                        v-model="itemGroupData.config.endLevel"
                                        label="level"
                                        clearable
                                        dense
                                        :rules="rulesNotEmpty('end level')"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col v-if="!isDurationType">
                        <h4>Start bonus percentage</h4>
                        <v-row no-gutters dense class="my-3 ml-3">
                            <v-col>
                                <v-combobox
                                        label="percentage"
                                        :items="refsStartPercentage"
                                        v-model="itemGroupData.config.startBonusPercentage"
                                        clearable
                                        hide-selected
                                        dense
                                        :rules="rulesNotEmptyAndHigherThan('Start bonus', 0)"
                                ></v-combobox>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col v-if="!isDurationType">
                        <h4>Until bonus percentage</h4>
                        <v-row no-gutters dense class="my-3 ml-3">
                            <v-col>
                                <v-combobox
                                        label="percentage"
                                        :items="refsUntilPercentage"
                                        v-model="itemGroupData.config.untilBonusPercentage"
                                        clearable
                                        hide-selected
                                        dense
                                        :rules="rulesNotEmpty('until bonus')"
                                ></v-combobox>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col v-if="isDurationType">
                        <h4>{{titleBonusDirect}}</h4>
                        <v-row no-gutters dense class="my-3 ml-3">
                            <v-col>
                                <v-combobox
                                        :label="titleBonusUnit"
                                        :items="refsDurationBonus"
                                        v-model="itemGroupData.config.durationBonus"
                                        clearable
                                        hide-selected
                                        dense
                                        :rules="rulesNotEmpty('duration bonus')"
                                ></v-combobox>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col v-if="isDurationType">
                        <h4>Level Range of Direct value type</h4>
                        <v-row no-gutters dense class="my-3 ml-3">
                            <v-col>
                                <v-combobox
                                        label="level range"
                                        :items="refsLevelRange"
                                        v-model="itemGroupData.config.levelRangeSelect"
                                        clearable
                                        hide-selected
                                        dense
                                        :rules="rulesNotEmpty('level range')"
                                ></v-combobox>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <v-row v-if="pCanSave">
                    <v-col>
                        <v-btn
                                class="ma-2"
                                color="success"
                                @click="generateSibling()"
                        >
                            Generate
                        </v-btn>
                        <v-btn
                                color="red"
                                @click="clearSibling()"
                        >
                            Clear
                        </v-btn>
                    </v-col>
                    <v-col>
                        Parent Behaviour:
                        <v-list class="pa-0">
                            <v-list-item v-for="behaviour in itemData.behaviours" :key="behaviour.behaviourBean.join">
                                {{ renderDetailBean(behaviour) }} {{renderGameplayImpact(behaviour)}}
                            </v-list-item>
                        </v-list>
                    </v-col>
                </v-row>

                <v-row v-if="generateMsg">
                    <v-col cols="0">
                        <v-card-text>
                            {{generateMsg}}
                        </v-card-text>
                    </v-col>
                </v-row>

                <v-virtual-scroll
                        :item-height="getHeightSiblingItem()"
                        :height="computeWindowHeight"
                        :items="this.optimizedList"
                        v-if="siblingUtils!==undefined"
                >
                    <template v-slot:default="{ item }">
                        <v-row v-if="item.length > 0" dense no-gutters class="mt-3">
                            <v-col
                                    :cols=1
                                    v-for="it in item"
                                    :key="it.renderId"
                                    class="mx-16"
                            >
                                <SiblingCardItem :pItemData="it" :pLevelCardData="getLevelCardData(it)"></SiblingCardItem>
                            </v-col>
                        </v-row>
                    </template>
                </v-virtual-scroll>

            </v-form>
        </v-container>
        <SiblingItemUpdateForm :pSiblingUtils="this.siblingUtils" />

        <v-overlay :value="isLoading">
            <v-progress-circular
                    indeterminate
                    size="64"
            ></v-progress-circular>
        </v-overlay>
        <v-snackbar
                v-model="snackbar"
                :timeout="timeoutSnackbar"
                :color="colorSnackbar"
                centered
        >
            {{ textSnackbar }}

            <template v-slot:action="{ attrs }">
                <v-btn
                        color="blue"
                        text
                        v-bind="attrs"
                        @click="snackbar = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </v-card>
</template>

<script>
    import SMPNum from "../../../../SMPNum";
    import HeaderPopup from "../../HeaderPopupComponent.vue";
    import SiblingCardItem from "../components/SiblingCardItem.vue";
    import SiblingItemMultipleImpactUtils from "../../../Utils/SiblingItemMultipleImpactUtils";
    import GamePlayImpactType from "@/item-editor/constants/GamePlayImpactType";
    import SiblingItemUpdateForm from "../components/SiblingItemUpdateForm";
    import ItemApi from "../../../dao/ItemApi.js"
    import {EVENT_ON_ITEM_SAVED, GlobalEvents} from "@/item-editor/events/GlobalEvents";
    import UGSItemInventoryModel from "../../../classes/UGSItemInventoryModel";

    let valueTypes = [];
    GamePlayImpactType.valueTypes.forEach(v => {
        valueTypes.push(v);
    });

    export default {
        name: "SiblingGenerationForm",
        props: {
            'pItemGroupData':{
                type: Object,
                required: true
            },
            'pCanSave':{
                type: Boolean,
                default: true,
                required: false
            },
        },
        components: {
            HeaderPopup,
            SiblingCardItem,
            SiblingItemUpdateForm,
        },
        data(){
            return {
                optimizedList:[],
                siblingUtils:null,
                itemData:null,
                itemGroupData:null,
                refsStartPercentage:[],
                refsUntilPercentage:[],
                generateMsg: '',
                refsLevelRange:[],
                isDurationType:false,
                refsDurationBonus: [],
                titleBonusDirect: "Duration bonus",
                titleBonusUnit: "seconds",
                siblingConfig: null,
                snackbar: false,
                textSnackbar: '',
                colorSnackbar: 'success',
                timeoutSnackbar: 2000,
                isLoading: false,
            }
        },
        methods:{
            save(){
                let successful = this.$refs.form.validate();
                if (successful){
                    if(this.itemGroupData.items.length === 0){
                        this.setGenerateMsg("sibling can't be empty");
                    } else {
                        this.pushGroupItems();
                    }
                }
            },
            close(){
                this.$emit('close');
            },
            rulesNotEmptyAndHigherThan (fieldName, min) {
                return [
                    v => (!!v || fieldName + ' is required!') && (v > min || 'Invalid')
                ];
            },
            rulesNotEmptyAndLower (fieldName, min) {
                return [
                    v => (!!v || fieldName + ' is required!') && (v <= min || 'Invalid')
                ];
            },
            rulesNotEmpty (fieldName) {
                return [
                    v => !!v || fieldName + ' is required!'
                ];
            },
            valuePercentageBonus() {
                let refs = [];
                refs.push(20);
                refs.push(30);
                refs.push(40);
                refs.push(50);
                return refs;
            },
            valuePercentageUntilBonus() {
                let refs = [];
                refs.push(2);
                refs.push(5);
                refs.push(10);
                refs.push(15);
                return refs;
            },
            valueLevelRangeListSelect(){
                let refs = [];
                refs.push(5);
                refs.push(10);
                refs.push(20);
                refs.push(30);
                refs.push(50);
                refs.push(80);
                refs.push(100);
                refs.push(300);
                refs.push(500);
                refs.push(1000);
                return refs;
            },
            valueDurationBonusListSelect(){
                let refs = [];
                refs.push(2);
                refs.push(3);
                refs.push(4);
                refs.push(5);
                refs.push(6);
                refs.push(10);
                return refs;
            },

            initSiblingUtil(){
                let gamePlayDataService = this.$store.state.gamePlayDataService;
                this.siblingUtils = new SiblingItemMultipleImpactUtils(gamePlayDataService);
            },

            initDefaultSiblingData(){
                this.itemGroupData = this.pItemGroupData;
                this.itemData = this.itemGroupData.item;

                this.refsStartPercentage = this.valuePercentageBonus();
                this.refsUntilPercentage = this.valuePercentageUntilBonus();

                if(this.itemGroupData.config.configOnMultiple === undefined){
                    this.siblingUtils.defineDefaultConfig(this.itemGroupData.config, this.itemData);
                }

                let firstConfig = this.itemGroupData.config.configOnMultiple[0];
                this.isDurationType = firstConfig.isDurationType;

                if(this.isDurationType){
                    this.refsDurationBonus = this.valueDurationBonusListSelect();
                    this.refsLevelRange = this.valueLevelRangeListSelect();
                }

                let inputTitle = this.siblingUtils.getDirectTitleFirstBehInputBonus(this.itemData.behaviours[0]);
                this.titleBonusDirect = inputTitle.title;
                this.titleBonusUnit = inputTitle.unit;
            },

            validateConfigData(){
                let intStartLevel = parseInt(this.itemGroupData.config.startLevel);
                let intEndLevel = parseInt(this.itemGroupData.config.endLevel);
                let intStartBonus = parseInt(this.itemGroupData.config.startBonusPercentage);
                let intEndBonus = parseInt(this.itemGroupData.config.untilBonusPercentage);
                let fDurationBonus = 0.0;
                let intLevelRange = 0;
                if(this.isDurationType){
                    intLevelRange = parseInt(this.itemGroupData.config.levelRangeSelect);
                    fDurationBonus = parseFloat(this.itemGroupData.config.durationBonus);
                }

                this.itemGroupData.config.startLevel = intStartLevel;
                this.itemGroupData.config.endLevel = intEndLevel;
                this.itemGroupData.config.startBonusPercentage = intStartBonus;
                this.itemGroupData.config.untilBonusPercentage = intEndBonus;
                this.itemGroupData.config.levelRangeSelect = intLevelRange;
                this.itemGroupData.config.durationBonus = fDurationBonus;

                this.itemGroupData.config.configOnMultiple.forEach(configMT =>{
                    configMT.durationBonus = fDurationBonus;
                    configMT.startBonusPercentage = intStartBonus;
                });
            },

            generateSibling() {
                this.isLoading = true;
                setTimeout(() => this.waitToAvoidBugGenerate(), 200);
            },
            waitToAvoidBugGenerate(){
                let successful = this.$refs.form.validate();
                if (successful) {
                    this.validateConfigData();
                    let waitTime = 0;
                    if(this.optimizedList.length > 0){
                        waitTime = 200;
                    }
                    this.clearSibling();
                    let cloneList = this.siblingUtils.generateSiblingItemsByConfig(this.itemGroupData.config, this.itemData);
                    this.itemGroupData.items = cloneList;
                    this.itemGroupData.config.genSiblingReady = true;
                    if(cloneList.length === 0){
                        if (this.siblingUtils.isNoneImplementCatch) {
                            this.setGenerateMsg(this.siblingUtils.msgCatch);
                        } else {
                            this.setGenerateMsg("No item list on generate");
                        }
                    } else {
                        this.initSiblingItemCard(waitTime);
                    }
                } else {
                    this.clearSibling();
                    this.setGenerateMsg("Input field not valid");
                }
                this.isLoading = false;
            },
            clearSibling(){
                this.setGenerateMsg('');
                this.itemGroupData.items = [];
                this.optimizedList = [];
            },

            initSiblingItemCard: function (waitTime) {
                let toDisplay = this.itemGroupData.items;
                this.optimizedList = [];
                setTimeout(() => this.waitToShowList(toDisplay), waitTime);
            },

            waitToShowList(toDisplay){
                let rowCount = 1;
                let tempRow = [];
                for (let i = 0; i < toDisplay.length; i++) {
                    tempRow.push(toDisplay[i]);
                    if (rowCount === 4 || i === toDisplay.length - 1) {
                        this.optimizedList.push(tempRow);
                        rowCount = 0;
                        tempRow = [];
                    }
                    rowCount++;
                }
            },

            renderGameplayImpact: function (behaviour) {
                if (behaviour.gamePlayImpact.value < 0 || !behaviour.gamePlayImpact.valueType) {
                    return '';
                }
                let des = "";
                let bonus = behaviour.gamePlayImpact.value;
                let bean = behaviour.gamePlayImpact.bean;
                if(bean !== null && bean.valueType !== null && bean.valueType.value === "DirectDisplayValue"){
                    des += bean.valueType.text;
                } else if(behaviour.gamePlayImpact.valueType === "DirectValue"){
                    des = SMPNum.fromPowerString(bonus).ToReadableAlphabetV2();
                } else {
                    des = bonus.toFixed(2);
                }
                des += " "
                    + GamePlayImpactType.valueTypeSymbols[behaviour.gamePlayImpact.valueType];

                if (behaviour.gamePlayImpact.periodValue > 0) {
                    des += ' every ' + behaviour.gamePlayImpact.periodValue
                        + " "
                        + GamePlayImpactType.periodTypeSymbols[behaviour.gamePlayImpact.periodType];
                }

                return des;
            },
            renderDetailBean(behaviour){
                let detail = "" + behaviour.behaviourBean.des.details;
                if(behaviour.type === "inc_powerup_capacity"){
                    let skillType = behaviour.gamePlayImpact.skillType;
                    if(skillType !== null) {
                        if (detail.includes("Power up")) {
                            detail = detail.replace("Power up", skillType);
                        } else {
                            detail += " " + behaviour.gamePlayImpact.skillType;
                        }
                    } else if (detail.includes("Power up")) {
                        detail = detail.replace("Power up", "all power-up");
                    }
                }
                return detail;
            },

            getLevelCardData(item){
                let lv = item.gameLevel.levelStart;
                let beh = item.behaviours[0];
                let refBonus = this.siblingUtils.getBonusTextOnLevelOfImpact(item, beh, lv);
                let valueType = beh.gamePlayImpact.valueType;
                let levelData = {
                    level: lv,
                    bonus: refBonus.bonus,
                    bonusType: valueType,
                    base: refBonus.base,
                    percentage: refBonus.percentage,
                    baseType: refBonus.baseType,
                    costInGold: refBonus.costInGold,
                };

                if(item.behaviours.length > 1){
                    levelData.joinLevelDatas = [];
                    for(let index = 1;index<item.behaviours.length;index++){
                        let beh = item.behaviours[index];
                        let refBonus = this.siblingUtils.getBonusTextOnLevelOfImpact(item, beh, lv);
                        let valueType = beh.gamePlayImpact.valueType;
                        levelData.joinLevelDatas.push({
                            bonus: refBonus.bonus,
                            bonusType: valueType,
                            base: refBonus.base,
                            percentage: refBonus.percentage,
                            baseType: refBonus.baseType,
                            costInGold: refBonus.costInGold,
                        });
                    }
                }

                return levelData;
            },

            getHeightSiblingItem(){
                if(this.itemData.behaviours.length > 1){
                    return 330;
                }
                return 270;
            },

            setGenerateMsg(msg){
                this.generateMsg = msg;
            },

            pushGroupItems(){
                let environmentIds = this.itemGroupData.item.pushStatus;
                let dataPush = {
                    backEndData: this.itemGroupData,
                    economyData: {
                        environmentIds: environmentIds,
                        itemEconomy: environmentIds.length === 0 ? undefined : new UGSItemInventoryModel(this.itemGroupData)
                    },
                };

                ItemApi.updateItemGroups(dataPush)
                    .then(result => {
                        console.log("Result : ", result);
                        this.snackbar = true;
                        this.textSnackbar = "New item was created!";
                        this.colorSnackbar = 'success';

                        setTimeout(() => {
                            this.isLoading = false;
                            this.close();
                            this.$emit('siblingGenerated');
                            GlobalEvents.$emit(EVENT_ON_ITEM_SAVED);
                        }, 1500);

                    })
                    .catch(error => {
                        this.snackbar = true;
                        this.textSnackbar = "Failed to create a new item: " + error;
                        this.colorSnackbar = 'red';

                        setTimeout(() => {
                            this.isLoading = false;
                        }, 200);
                    })
            },
        },
        computed:{
            computeWindowHeight() {
                const height = window.innerHeight || document.documentElement.clientHeight ||
                    document.body.clientHeight;
                return height * 0.55;
            },
        },
        created() {
            this.initSiblingUtil();
            this.initDefaultSiblingData();
            this.initSiblingItemCard(0);
        }
    }
</script>

<style scoped>

</style>