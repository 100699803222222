
export let BehaviourTwiceTypes = [
    [
        "inc_size_hero",
        "inc_powerup_capacity",
    ],
    [
        "inc_size_hero",
        "inc_dps",
    ],
    [
        "inc_size_hero",
        "damage_enemy"
    ],
    [
        "inc_size_hero",
        "inc_mana",
    ],
    [
        "inc_size_hero",
        "inc_pet_active_time",
    ],

    [
        "damage_enemy",
        "inc_dps",
    ],
    [
        "damage_enemy",
        "inc-speed-support",
    ],
    [
        "damage_enemy",
        "inc_critical_hit_ratio",
    ],

    [
        "inc-gold-drop",
        "inc_gold_enemy_drop",
    ],
    [
        "sheep-enemy",
        "cure_sheep",
    ],
    [
        "freeze-enemy",
        "cure_freeze",
    ],
    [
        "poison-enemy",
        "cure_poison",
    ],
    [
        "sheep-enemy",
        "cure_sheep",
    ],

    [
        "inc_mana_refill_speed",
        "inc_mana",
    ],
    [
        "inc_mana_refill_speed",
        "inc_powerup_capacity",
    ],

    [
        "holding_tap",
        "inc_critical_hit_ratio",
    ],
    [
        "holding_tap",
        "inc-speed-support",
    ],

    [
        "auto_tap",
        "inc_critical_hit_ratio",
    ],
    [
        "auto_tap",
        "inc-speed-support",
    ],
];

export let BehaviourTripleTypes = [
    [
        "inc_size_hero",
        "inc_powerup_capacity",
        "inc_dps",
    ],
    [
        "inc_size_hero",
        "inc_dps",
        "damage_enemy"
    ],
    [
        "inc_size_hero",
        "inc_powerup_capacity",
        "inc_mana",
    ],

    [
        "damage_enemy",
        "inc_critical_hit_ratio",
        "inc-speed-support",
    ],
    [
        "damage_enemy",
        "inc-speed-support",
        "inc_dps",
    ],

    [
        "inc_mana_refill_speed",
        "inc_mana",
        "inc_powerup_capacity",
    ],

    [
        "holding_tap",
        "inc_critical_hit_ratio",
        "inc-speed-support",
    ],

    [
        "auto_tap",
        "inc_critical_hit_ratio",
        "inc-speed-support",
    ],
];

export default {BehaviourTwiceTypes, BehaviourTripleTypes};
