import {BehaviourByNames} from "@/item-editor/constants/BehaviourTypes";
let SiblingGamePlayConstant = require("@/item-editor/constants/SiblingGamePlayConstant");

class AutomateItemConfigUtils  {

    constructor (simulatorItemRequireList, automateCreateItemOptions) {
        this.simulatorItemRequireList = simulatorItemRequireList;
        this.automateCreateItemOptions = automateCreateItemOptions;
        this.initItemUtils();
    }

    applyConfig(config){
        if(this.isHasImplementImpact(config.impactType)){
            if(this.isBonusForReviveHero(config.impactType)){
                if(this.isGenerateBalanceItem()){
                    let ls = this.simulatorItemRequireList.filter(s => s.reviveHeroRequire > 0).sort((a, b) => (a.level < b.level));
                    this.defineConfig(config, ls, 'reviveHeroRequire');
                    //allow can customize
                    if(this.getIsCanCustomRevive()){
                        config.isApplySimulator = false;
                    }
                } else {
                    config.itemRootCount = 0;
                    config.isApplySimulator = false;
                }
            } else if(this.isBonusForReviveSupport(config.impactType)){
                if(this.isGenerateBalanceItem()){
                    let ls = this.simulatorItemRequireList.filter(s => s.reviveSupportRequire > 0).sort((a, b) => (a.level < b.level));
                    this.defineConfig(config, ls, 'reviveSupportRequire');
                    //allow can customize
                    if(this.getIsCanCustomRevive()){
                        config.isApplySimulator = false;
                    }
                } else {
                    config.itemRootCount = 0;
                    config.isApplySimulator = false;
                }
            } else if(this.isBonusForDmgRequire(config.impactType)){
                if(this.isGenerateBalanceItem()){
                    let ls = this.simulatorItemRequireList.filter(s => s.dmgRequirePercent > 0).sort((a, b) => (a.level < b.level));
                    this.defineConfig(config, ls, 'dmgRequirePercent');
                } else {
                    config.itemRootCount = 0;
                    config.isApplySimulator = false;
                }
            } else if(this.isBonusForHpRequire(config.impactType)){
                if(this.isGenerateBalanceItem()){
                    let ls = this.simulatorItemRequireList.filter(s => s.hpRequirePercent > 0).sort((a, b) => (a.level < b.level));
                    this.defineConfig(config, ls, 'hpRequirePercent');
                } else {
                    config.itemRootCount = 0;
                    config.isApplySimulator = false;
                }
            } else if(this.isBonusForHalfRequire(config.impactType)){
                if(this.isIncludeOptionItem()){
                    let ls = this.simulatorItemRequireList.filter(s => s.dmgRequirePercent > 0).sort((a, b) => (a.level < b.level));
                    this.defineConfig(config, ls, 'dmgRequirePercent', this.getMultiplierForOptional());//0.5);
                } else {
                    config.itemRootCount = 0;
                    config.isApplySimulator = false;
                }
            } else if(this.isBonusForGoldFarmingRequire(config.impactType)){
                if(this.isIncludeOptionItem()){
                    let ls = this.simulatorItemRequireList.filter(s => s.dmgRequirePercent > 0).sort((a, b) => (a.level < b.level));
                    this.defineConfig(config, ls, 'dmgRequirePercent', this.getMultiplierForGold());//2.0);
                } else {
                    config.itemRootCount = 0;
                    config.isApplySimulator = false;
                }
            }
        }
    }

    isGenerateBalanceItem(){
        return this.automateCreateItemOptions.isGenBalanceItem;
    }

    isIncludeOptionItem(){
        return this.automateCreateItemOptions.isIncludeOptional;
    }

    getMultiplierForGold(){
        return this.automateCreateItemOptions.optionalRatioGoldItem;
    }

    getMultiplierForOptional(){
        return this.automateCreateItemOptions.optionalRatioItem;
    }

    getIsCanCustomRevive(){
        return this.automateCreateItemOptions.isCustomRevive;
    }
    //==============================================
    //=========local================================
    initItemUtils(){
        this.doneImplementImpact = [];
        this.impactsForDmgRequire = [];
        this.impactsForHpRequire = [];
        this.impactsHalfForRequire = [];
        this.impactForGoldFarmingRequire = [];

        this.addImpactToListDMGRequire(BehaviourByNames.damageEnemy);

        this.addImpactToListHpRequire(BehaviourByNames.incHp);
        this.addImpactToListHpRequire(BehaviourByNames.fillHp);

        this.addImpactToListHalfRequire(BehaviourByNames.incCriticalHitRatio);
        this.addImpactToListHalfRequire(BehaviourByNames.incDps);
        this.addImpactToListHalfRequire(BehaviourByNames.incSpeedSupport);

        this.addImpactToListGoldFarmingRequire(BehaviourByNames.incGoldEnemyDrop);
        this.addImpactToListGoldFarmingRequire(BehaviourByNames.incGoldDrop);

        // this.addImpactToListHalfRequire(BehaviourByNames.cureFire);
        // this.addImpactToListHalfRequire(BehaviourByNames.cureFreeze);
        // this.addImpactToListHalfRequire(BehaviourByNames.curePoison);
        // this.addImpactToListHalfRequire(BehaviourByNames.cureSheep);
        this.addImpactToListHalfRequire(BehaviourByNames.fillMana);
        this.addImpactToListHalfRequire(BehaviourByNames.incMana);
        this.addImpactToListHalfRequire(BehaviourByNames.incManaRefillSpeed);
        this.addImpactToListHalfRequire(BehaviourByNames.freezeEnemy);
        this.addImpactToListHalfRequire(BehaviourByNames.incPetActiveTime);
        this.addImpactToListHalfRequire(BehaviourByNames.incPowerUpCapacity);
        this.addImpactToListHalfRequire(BehaviourByNames.poisonEnemy);
        this.addImpactToListHalfRequire(BehaviourByNames.sheepEnemy);

        this.addImpactToListReviveRequire(BehaviourByNames.reviveHero);
        this.addImpactToListReviveRequire(BehaviourByNames.reviveSupport);

        /*
            incSpeedSupport: "inc-speed-support",
    incGoldDrop: "inc-gold-drop",
    damageEnemy: "damage_enemy",
    freezeEnemy: "freeze-enemy",
    poisonEnemy: "poison-enemy",
    sheepEnemy: "sheep-enemy",
    incMana: "inc_mana",
    incManaRefillSpeed: "inc_mana_refill_speed",
    incHeroSize: "inc_size_hero",
    incCriticalHitRatio: "inc_critical_hit_ratio",
    incGoldEnemyDrop: "inc_gold_enemy_drop",
    incPetActiveTime: "inc_pet_active_time",
    reviveHero: "revive_hero",
    reviveSupport: "revive_support",
    cureFreeze: "cure_freeze",
    curePoison: "cure_poison",
    cureSheep: "cure_sheep",
    cureFire: "cure_fire",
    incPowerUpCapacity: "inc_powerup_capacity",
    incHp: "inc_hp",
    fillHp: "fill_hp",
    fillMana: "fill_mana",
    incDps: "inc_dps",
    incBagSlot: "inc_bag_slot",
    autoTap: "auto_tap",
    holdingTap: "holding_tap"

         */
    }

    addImpactToListDMGRequire(type){
        this.impactsForDmgRequire.push(type);
        this.doneImplementImpact.push(type);
    }

    addImpactToListHpRequire(type){
        this.impactsForHpRequire.push(type);
        this.doneImplementImpact.push(type);
    }

    addImpactToListHalfRequire(type){
        this.impactsHalfForRequire.push(type);
        this.doneImplementImpact.push(type);
    }

    addImpactToListGoldFarmingRequire(type){
        this.impactForGoldFarmingRequire.push(type);
        this.doneImplementImpact.push(type);
    }

    addImpactToListReviveRequire(type){
        this.doneImplementImpact.push(type);
    }

    defineConfig(config, dataList, key, multiplier = 1) {
        if (dataList.length > 0) {
            //define start item level appear
            config.startLevel = dataList[0].level;

            //define end level
            config.endLevel = dataList[dataList.length - 1].level;

            //for dynamic item on level range
            config.lstGeneric = [];
            let startLevel = 0;
            let percent = 0.0;
            let nextPercent = 0.0;
            let minPercent = 0.0;
            let maxPercent = 0.0;
            if (dataList.length === 1) {
                percent = parseFloat((dataList[0][key]*multiplier).toFixed(2));
                minPercent = percent;
                maxPercent = percent + 10;
                if(this.isAllowAppear(config.impactType, dataList[0].level)){
                    config.lstGeneric.push({
                        startLevel: dataList[0].level,
                        minBonus: percent,
                        maxBonus: maxPercent,
                        endLevel: dataList[0].level + 10
                    });
                }
            } else {
                percent = parseFloat((dataList[0][key]*multiplier).toFixed(2));
                minPercent = percent;
                startLevel = dataList[0].level;
                let lastGroup1KItem = Math.floor(startLevel / 1000) * 1000;
                for (let index = 1; index < dataList.length; index++) {
                    let nextGroup1KItem = Math.floor(dataList[index].level / 1000) * 1000;
                    nextPercent = parseFloat((dataList[index][key]*multiplier).toFixed(2));
                    if (percent < nextPercent || nextGroup1KItem > lastGroup1KItem) {
                        maxPercent = percent + 10;
                        if(this.isAllowAppear(config.impactType, startLevel)){
                            config.lstGeneric.push({
                                startLevel: startLevel,
                                minBonus: percent,
                                maxBonus: maxPercent,
                                endLevel: dataList[index].level
                            });
                        }
                        percent = nextPercent;
                        startLevel = dataList[index].level;
                    } else {
                        if (minPercent > nextPercent) {
                            minPercent = nextPercent;
                        }

                        if (index === dataList.length - 1) {
                            maxPercent = percent + 10;
                            if(this.isAllowAppear(config.impactType, startLevel)){
                                config.lstGeneric.push({
                                    startLevel: startLevel,
                                    minBonus: percent,
                                    maxBonus: maxPercent,
                                    endLevel: dataList[index].level
                                });
                            }
                        }
                    }
                    lastGroup1KItem = nextGroup1KItem;
                }
            }

            config.minBonus = minPercent;
            config.maxBonus = maxPercent;
            config.genItemCount = config.lstGeneric.length;
            config.isApplySimulator = true;
            config.itemRootCount = config.lstGeneric.length;

            //console.log("gen " + config.impactType, config.lstGeneric);
        }
    }

    isAllowAppear(impType, startLevel){
        if(this.isRequireHeroSkillUnlock(impType)){
            //automate static, first power up unlocked at stage 120
            if(startLevel < SiblingGamePlayConstant.min_stage_hero_skill_unlock){
                //console.log(' -> imp '+impType+ ' not allow level: '+startLevel);
                return false;
            }
        } else if (impType === BehaviourByNames.incPetActiveTime){
            if(startLevel < SiblingGamePlayConstant.min_stage_pet_unlock){
                //console.log(' -> imp '+impType+ ' not allow level: '+startLevel);
                return false;
            }
        } else if (this.isBossStuntItem(impType)){
            if(startLevel < SiblingGamePlayConstant.min_stage_boss_stunt_item){
                //console.log(' -> imp '+impType+ ' not allow level: '+startLevel);
                return false;
            }
        }
        return true;
    }

    isRequireHeroSkillUnlock(impType){
        return impType === BehaviourByNames.incPowerUpCapacity
                || impType === BehaviourByNames.incMana
                || impType === BehaviourByNames.incManaRefillSpeed
                || impType === BehaviourByNames.fillMana;
    }

    isBossStuntItem(impType){
        return impType === BehaviourByNames.sheepEnemy
            || impType === BehaviourByNames.poisonEnemy
            || impType === BehaviourByNames.freezeEnemy;
    }

    isHasImplementImpact(impType){
        return this.doneImplementImpact.includes(impType);
    }

    isBonusForDmgRequire(impType){
        return this.impactsForDmgRequire.includes(impType);
    }

    isBonusForHpRequire(impType){
        return this.impactsForHpRequire.includes(impType);
    }

    isBonusForHalfRequire(impType){
        return this.impactsHalfForRequire.includes(impType);
    }

    isBonusForGoldFarmingRequire(impType){
        return this.impactForGoldFarmingRequire.includes(impType);
    }

    isBonusForReviveHero(impType){
        return impType === BehaviourByNames.reviveHero;
    }

    isBonusForReviveSupport(impType){
        return impType === BehaviourByNames.reviveSupport;
    }
}

export default AutomateItemConfigUtils;
