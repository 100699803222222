import { plus } from "../../../../SMPNum";

class Chain6 {
     constructor(service, successor) {
          this.service = service;
          this.successor = successor;
     }
     handleRequest(payload){
          //get only required param
          const  {
               gameLevel,
               heroLevelAfterFarm,
               levelThatZoneBossAppear,
               goldAvailableForSupportUnlock,
               goldAvailableForSupportLevel,
               goldAvailableForSupport,
               goldAvailableForSupportSkill,
               diamondAvailableForPet,
               hitBossPerSecond,
               hitPerSecond,
               ratioSupportSkill,
               isUseSupportSkillDmg,
               isUsePetSkillDmg,
               isUsingPet,
               supportList,
               itemImpactBonus,
               marketItemWillBonus,
               timeRequireForDmgItem,
               timeRequireForHpItem,
               bossInfo,
               teamBattleInfo,
               heroHpCapSessionFromSupport,
               petList,
               isUseHeroSkillGold,
               isUseHeroSkillDmg,
               percentControlHeroGoldSkill,
               percentControlHeroDmgSkill,
               heroActiveSkillList,
               heroBossSkillList,
               isUseThunderAttackSkill,
               isUsePetAllianceSkill,
               unlockFlyingSupportCounter,
               balSupportLevelBelowBoss,
               supportCount,
               dataAutomateGameState,
          } = payload;

          //param for hero skill
          let heroSkillPayload = {
               isUseHeroSkillGold,
               isUseHeroSkillDmg,
               percentControlHeroGoldSkill,
               percentControlHeroDmgSkill,
               isUseThunderAttackSkill,
               isUsePetAllianceSkill,
          };

          //============== BUILD PAYLOAD ==================================
          let params = {
               gameLevel,
               heroLevelAfterFarm,
               goldAvailableForSupportUnlock,
               goldAvailableForSupportLevel,
               goldAvailableForSupport,
               goldAvailableForSupportSkill,
               diamondAvailableForPet,
               hitBossPerSecond,
               hitPerSecond,
               ratioSupportSkill,
               isUseSupportSkillDmg,
               isUsePetSkillDmg,
               isUsingPet,
               supportList,
               itemImpactBonus,
               teamBattleInfo,
               petList,
               heroSkillPayload,
               unlockFlyingSupportCounter,
               balSupportLevelBelowBoss,
               supportCount
          };

          //base hero dmg with bonus
          const hrBaseDmg = this.service.heroBaseDMGIncludeBonus(params);
          const heroDmgSessionGainBoss = this.service.getHeroDMGSessionResultFromGainBoss(hrBaseDmg, bossInfo, teamBattleInfo);
          const heroDmgSessionFromPet = this.service.getHeroDmgSessionResultFromPets(hrBaseDmg, bossInfo, teamBattleInfo);
          const heroBaseDmg = this.service.getBaseHeroDMGWithMultipleSession(hrBaseDmg, heroDmgSessionGainBoss, heroDmgSessionFromPet);
          const heroBaseDps = this.service.computeHeroDps(heroBaseDmg, hitPerSecond);

          //include hero skill bonus
          heroSkillPayload.heroActiveSkillList = heroActiveSkillList;
          heroSkillPayload.heroBossSkillList = heroBossSkillList;
          const heroGoldSkillBonus = this.service.getHeroSkillGoldBonus(heroSkillPayload);

          //============= COMPUTE USING PAYLOAD =====================
          //on ghost mode include element bonus from hero vs pet
          params.heroDmgSessionFromPet = heroDmgSessionFromPet;
          const timeToKillGhost = this.service.timeToKillGhost(params);

          //on boss mode include element bonus from hero vs boss
          params.heroDmgSessionGainBoss = heroDmgSessionGainBoss;
          const timeToKillBoss = this.service.timeToKillBoss(params);

          params.gameLevel = levelThatZoneBossAppear;
          const timeToKillZoneBoss = this.service.timeToKillZoneBoss(params);

          const heroHp = this.service.getHeroHp(heroLevelAfterFarm);

          if(!heroHpCapSessionFromSupport.bonusHp.isZero){
               let totalHp = plus(heroHp, heroHpCapSessionFromSupport.bonusHp);
               heroHp.setFromSMPNum(totalHp);
          }

          const hpItemBonus = this.service.getTotalHpItemBonus(itemImpactBonus, heroLevelAfterFarm);
          const finalHeroHp = this.service.getTotalHeroHpWithItemBonus(heroHp, hpItemBonus);

          const timeHeroPossibleDieWithNormalBoss = this.service.getTimeHeroPossibleDieWithNormalBoss(finalHeroHp, gameLevel);
          const timeHeroPossibleDieWithZoneBoss = this.service.getTimeHeroPossibleDieWithZoneBoss(finalHeroHp, levelThatZoneBossAppear);

          const bossDps = this.service.getBossDps(gameLevel, finalHeroHp);
          const zoneBossDps = this.service.getZoneBossDps(levelThatZoneBossAppear, finalHeroHp);

          const generatedHeroHpPerSecond = plus(this.service.getGeneratedHeroHpPerSecond(finalHeroHp), hpItemBonus);
          const expectedGeneratedHpWithBoss = this.service.getExpectedGeneratedHp(generatedHeroHpPerSecond, timeHeroPossibleDieWithNormalBoss);
          const expectedGeneratedHpWithZoneBoss = this.service.getExpectedGeneratedHp(generatedHeroHpPerSecond, timeHeroPossibleDieWithZoneBoss);

          const heroPossibleSurvive = this.service.getPossibleSurvive(finalHeroHp, bossDps, generatedHeroHpPerSecond);
          const heroPossibleSurviveZoneBoss = this.service.getPossibleSurvive(finalHeroHp, zoneBossDps, generatedHeroHpPerSecond);

          //for kpi game play and market place status
          let kpiBossType = "NormalBoss";
          let timeHeroPossibleDieBossOrZoneBoss = timeHeroPossibleDieWithNormalBoss;
          let timeToKillBossOrZoneBoss = timeToKillBoss;
          if( gameLevel === levelThatZoneBossAppear){
              timeHeroPossibleDieBossOrZoneBoss = timeHeroPossibleDieWithZoneBoss;
              timeToKillBossOrZoneBoss = timeToKillZoneBoss;
               kpiBossType = "ZoneBoss";
          }

          bossInfo.kpiBossType = kpiBossType;

          //require of game play
          const itemRequirePayload = {
               hpRequire: this.service.getHpItemRequirePayload(timeHeroPossibleDieBossOrZoneBoss, heroHp, zoneBossDps, timeRequireForHpItem),
               dmgRequire: this.service.getDmgItemRequirePayload(timeToKillBossOrZoneBoss, params, timeRequireForDmgItem)
          };
          if(dataAutomateGameState){
               if(dataAutomateGameState.reviveBestHero > 0){
                    itemRequirePayload.reviveHeroRequire = 1;
               }
               if(dataAutomateGameState.reviveBestSupport > 0){
                    itemRequirePayload.reviveSupportRequire = 1;
               }
          }

          //item impact in market place
          const marketWillBonusPayload = {
               hpItemBonus: this.service.getTotalHpItemBonus(marketItemWillBonus, heroLevelAfterFarm),
               dmgItemBonus: this.service.getDmgItemWillBonusOnMarketPlace(marketItemWillBonus, params),
               reviveHeroItem: marketItemWillBonus.reviveHeroItem,
               reviveSupportItem: marketItemWillBonus.reviveSupportItem
          };
         
          //prepare new payload
          let newPayload = {
               timeToKillGhost,
               timeToKillBoss,
               timeToKillZoneBoss,
               heroHp,
               timeHeroPossibleDieWithNormalBoss,
               timeHeroPossibleDieWithZoneBoss,
               bossDps,
               zoneBossDps,
               generatedHeroHpPerSecond,
               expectedGeneratedHpWithBoss,
               expectedGeneratedHpWithZoneBoss,
               heroPossibleSurvive,
               heroPossibleSurviveZoneBoss,
               hpItemBonus,
               itemRequirePayload,
               marketWillBonusPayload,
               bossInfo,
               heroBaseDmg,
               heroBaseDps,
               heroDmgSessionGainBoss,
               heroDmgSessionFromPet,
               heroSkillPayload,
               heroGoldSkillBonus,
          }

          //merge old and new to return payload
          let returnPayload = Object.assign({}, payload, newPayload);
          //forward to another successor or return
          if (this.successor) {
               return this.successor.handleRequest(returnPayload);
          } else {
               return returnPayload;
          }
     }
}
export default Chain6;