import HeroAndWeaponSupportUtils from "./HeroAndWeaponSupportUtils";
import SiblingItemMultipleImpactUtils from "./SiblingItemMultipleImpactUtils";
import UGSItemUtils from "./UGSItemUtils";
import EquipmentDataLoader from "@/item-editor/dao/EquipmentDataLoader";
import ShieldDataLoader from "@/item-editor/dao/ShieldDataLoader";
import HeroAndWeaponDataLoader from "@/item-editor/dao/HeroAndWeaponDataLoader";
import BehaviourJoinTargetOwnerList from "@/item-editor/constants/data-rules/BehaviourJoinTargetOwnerList";
import ValueTypeGroupByBehaviourType from "@/item-editor/constants/data-rules/BehaviourWithValueAndPeriod";
import BehaviourAndPeriodUtils from "@/item-editor/Utils/BehaviourAndPeriodUtils";
import ActiveSkillTypes from "@/item-editor/constants/ActiveSkillType";
import BehaviourUtils from "@/item-editor/Utils/BehaviourUtils";
import GamePlayGroupItem from "@/item-editor/classes/GamePlayGroupItem";
import LifeTimeUtils from "@/item-editor/Utils/LifeTimeUtils";
import ItemIconFileLoader from "@/item-editor/dao/ItemIconsFileLoader";
import {BehaviourTwiceTypes, BehaviourTripleTypes} from "@/item-editor/constants/BehaviourMultipleTypes";
import {BehaviourByNames} from "@/item-editor/constants/BehaviourTypes";
import {ItemCategoryNames} from "@/item-editor/constants/ItemCategories";
import {PeriodTypeShortcut, ValueTypeShortcut} from "@/item-editor/constants/GamePlayImpactType";
import GamePlayImpactType from "@/item-editor/constants/GamePlayImpactType";
import {ItemLifeTimeShortcut} from "@/item-editor/constants/ItemLifeTime";
import SMPNum from "@/SMPNum";
import lodash from 'lodash';
import {PeriodTypeNames} from "@/item-editor/constants/GamePlayImpactType";
import ItemModes from "@/item-editor/constants/ItemModes";

class AutomateItemGeneratorUtils{

    constructor(automateItemCreatedData, groupAutomateItems, gamePlayDataService, refWeaponNames, refPotionNames){
        this.isLoading = false;
        this.currentConfigIndex = 0;
        this.config = {};
        this.automateItems = [];
        this.countGenericItemPerGroup = 0;
        this.groupItemIdsWillRemove = [];
        this.itemNameSelectPerGenerate = [];
        this.generateMsg = "";
        this.gamePlayService = gamePlayDataService;

        this.groupAutomateItems = groupAutomateItems;
        this.refPotionNames = refPotionNames;
        this.refWeaponNames = refWeaponNames;

        this.refActiveSkillTypes = ActiveSkillTypes;
        this.automateItemCreatedData = automateItemCreatedData;
        this.refBehaviourTypes = BehaviourJoinTargetOwnerList;
        this.refTwiceImpact = BehaviourTwiceTypes;
        this.refTripleImpact = BehaviourTripleTypes;
        this.siblingUtils = new SiblingItemMultipleImpactUtils(gamePlayDataService);
    }

    async loadData(){
        this.totalIconList= await ItemIconFileLoader.loadIconRefData();

        this.refHeroAndWeaponData = await HeroAndWeaponDataLoader.loadData();
        this.refEquipmentData = await EquipmentDataLoader.loadData();
        this.refShieldData = await ShieldDataLoader.loadData();
        this.heroAndWeaponSupportUtils = new HeroAndWeaponSupportUtils(this.refHeroAndWeaponData, this.refEquipmentData, this.refShieldData);
    }

    prepareGenerateItems(){
        this.isLoading = true;
        this.automateItems = [];
        this.itemNameSelectPerGenerate = [];
        this.setGenerateMsg('');
        this.optimizedList = [];
        this.automateItems = [];
        this.currentConfigIndex = 0;
    }

    startGenerateAllImpactType(){
        if(this.currentConfigIndex < this.automateItemCreatedData.length){{
            this.isLoading = true;
            this.config = this.automateItemCreatedData[this.currentConfigIndex];
            let groupAutomate = this.groupAutomateItems.find(g => g.impactType === this.config.impactType);
            let isChangeByCounter = groupAutomate && this.config.itemRootCount !== groupAutomate.items.length;
            if(this.config.itemRootCount > 0 || this.config.itemRootCount !== this.config.groupsIds.length || isChangeByCounter){
                this.generateOnConfigSelected();
                this.currentConfigIndex++;
            } else{
                this.currentConfigIndex++;
                this.startGenerateAllImpactType()
            }
        }}else{
            //finish generate items
            this.isLoading = false;
        }
    }

    clearGroupItemRefs(){
        this.groupItemIdsWillRemove = [];
    }

    generateOnConfigSelected() {
        let itemRootData = this.getRootItemNewDataByIconData({
            name: "Wp_2001.png",//"sword_02.png",
            type: "sword",
            category: "weapon",
        });

        this.validateInputInNumber();

        let countTarget = this.config.itemRootCount;
        let behBeanEquip = this.refBehaviourTypes.find(b =>
            b.type === this.config.impactType
            && b.join.toUpperCase().includes('EQUIPMENT'));
        let behBeanPotion = this.refBehaviourTypes.find(b =>
            b.type === this.config.impactType
            && b.join.toUpperCase().includes('POTION'));

        let refTwiceImp = this.getImpactsTwiceJoinByType(this.config.impactType);
        let refTripleImp = this.getImpactsTripleJoinByType(this.config.impactType);

        let resultByImpact = [];
        let groupAutomate = this.groupAutomateItems.find(g => g.impactType === this.config.impactType);

        let lastCategorySelected = "";
        let indexStartUseGroupIds = 0;//use to fix the item missing on second generate from all hero can support items feature
        for (let i = 0; i < countTarget; i++) {
            //update auto generate with multiple category
            let categorySelected = "ALL";
            if(this.config.categoriesSelected !== undefined && this.config.categoriesSelected.length > 0){
                if(this.config.categoriesSelected.includes("ALL")){
                    categorySelected = "ALL";
                } else {
                    let cateForRnd = [];
                    if(this.config.categoriesSelected.length > 1){
                        this.config.categoriesSelected.forEach( cate => {
                            if(cate !== lastCategorySelected){
                                cateForRnd.push(cate);
                            }
                        });
                    } else {
                        this.config.categoriesSelected.forEach( cate => {
                            cateForRnd.push(cate);
                        });
                    }

                    let randomIndex = Math.floor(Math.random() * cateForRnd.length);
                    categorySelected = cateForRnd[randomIndex];
                    lastCategorySelected = categorySelected;
                }
            }

            if (this.config.impactType === 'revive_hero') {
                categorySelected = 'POTION';
            }

            let heroesNeedToUseItem = this.heroAndWeaponSupportUtils.getListHeroesNeedToUseItem();
            let itemReTakeToList = null;
            while(heroesNeedToUseItem.length > 0){

                categorySelected = this.heroAndWeaponSupportUtils.getCategoryPossibleHero(categorySelected, heroesNeedToUseItem);

                this.iconListByTotalUsed = this.heroAndWeaponSupportUtils.getGroupIconCategory(categorySelected, this.totalIconList, heroesNeedToUseItem);

                this.defineIndexOfGenericItem(i + 1);

                let isExiting = false;
                if (this.config.groupsIds.length > indexStartUseGroupIds) {
                    let itemExit = groupAutomate.items.find(g => g.id === this.config.groupsIds[indexStartUseGroupIds]);
                    isExiting = itemExit !== undefined;
                    if (isExiting) {
                        let isChangeIconGroup = (categorySelected !== undefined
                            && categorySelected !== "ALL"
                            && !this.config.categoriesSelected.includes(itemExit.config.categoryGenerated));
                        if(isChangeIconGroup){
                            isExiting = false;
                            this.groupItemIdsWillRemove.push(itemExit);
                        } else {
                            //to update the exiting automate item
                            //to get join impact
                            let joinImpact = this.getSelectJoinImpact(refTwiceImp, refTripleImp, i / countTarget);
                            this.initUpdateExitData(itemExit, joinImpact, i + 1);
                            this.automateItems.push(itemExit);
                            resultByImpact.push(itemExit);
                            itemReTakeToList = itemExit;
                        }
                    }
                }

                if (!isExiting) {
                    //to generate new item
                    let cloneRootData = lodash.cloneDeep(itemRootData);
                    this.setIconByRandomize(cloneRootData);

                    //to get join impact
                    let joinImpact = [];
                    if(!this.isBagSlotConfig()){
                        joinImpact = this.getSelectJoinImpact(refTwiceImp, refTripleImp, i / countTarget);
                    }

                    if (cloneRootData.category === ItemCategoryNames.potion || behBeanEquip === undefined) {
                        this.initializeItemData(cloneRootData, behBeanPotion, joinImpact, i + 1, true);
                    } else {
                        this.initializeItemData(cloneRootData, behBeanEquip, joinImpact, i + 1, false);
                    }

                    cloneRootData.config.categoryGenerated = categorySelected;

                    this.automateItems.push(cloneRootData);
                    resultByImpact.push(cloneRootData);

                    let iconData = {
                        fullName: cloneRootData.items[0].iconName,
                        category: cloneRootData.category
                    };
                    heroesNeedToUseItem = this.heroAndWeaponSupportUtils.getHeroesWhoNotPossibleUse(iconData, heroesNeedToUseItem);
                } else if(itemReTakeToList){
                    let iconData = {
                        fullName: itemReTakeToList.item.iconName,
                        category: itemReTakeToList.category
                    };
                    heroesNeedToUseItem = this.heroAndWeaponSupportUtils.getHeroesWhoNotPossibleUse(iconData, heroesNeedToUseItem);
                    if(heroesNeedToUseItem.length > 0){
                        indexStartUseGroupIds++;
                    }
                } else {
                    //if exiting item means, this item is pass since the first generate
                    heroesNeedToUseItem = [];
                }
            }
            indexStartUseGroupIds++;
        }
        if(groupAutomate.items.length > resultByImpact.length){
            for(let i = resultByImpact.length;i<groupAutomate.items.length;i++){
                this.groupItemIdsWillRemove.push(groupAutomate.items[i]);
            }
        }
        groupAutomate.items = resultByImpact;
        if (this.siblingUtils.isNoneImplementCatch) {
            this.snackbar = true;
            this.textSnackbar = "Failed sibling automate: {" + this.siblingUtils.msgCatch + "}";
            this.colorSnackbar = 'red';

            setTimeout(() => {
                this.isLoading = false;
            }, 800);
        } else {
            setTimeout(() => this.startGenerateAllImpactType(), 100);
        }
    }

    validateInputInNumber(){
        this.config.startLevel = parseInt(this.config.startLevel);
        this.config.endLevel = parseInt(this.config.endLevel);
        this.config.minBonus = parseFloat(this.config.minBonus);
        this.config.maxBonus = parseFloat(this.config.maxBonus);
        this.config.itemRootCount = parseInt(this.config.itemRootCount);
        this.config.nextItemLvRatio = parseFloat(this.config.nextItemLvRatio);
        this.config.moreNextItemLvRatio = parseFloat(this.config.moreNextItemLvRatio);
        this.config.twiceImpactGenPercent = parseFloat(this.config.twiceImpactGenPercent);
        this.config.tripleImpactGenPercent = parseFloat(this.config.tripleImpactGenPercent);

        //define ratio to generate impact join
        this.config.twiceJoinOnCountRatio = (100 - this.config.twiceImpactGenPercent)/100;
        this.config.tripleJoinOnCountRatio = (100 - this.config.tripleImpactGenPercent)/100;

        //define level range
        this.config.levelRangePeriod = this.config.endLevel - this.config.startLevel;
        this.config.levelRangePeriodPerItem = this.config.endLevel - this.config.startLevel;//Math.round(this.config.levelRangePeriod/this.config.nextItemLvRatio)

        this.config.lstGeneric.forEach(c =>{
            c.startLevel = parseInt(c.startLevel);
            c.endLevel = parseInt(c.endLevel);
            c.minBonus = parseFloat(c.minBonus);
            c.maxBonus = parseFloat(c.maxBonus);
        });
    }

    getRootItemNewDataByIconData(iconData) {
        let iconNameClean = iconData.name;
        if (iconNameClean.includes(".png")) {
            iconNameClean = iconNameClean.substring(0, iconNameClean.length - 4)
        }
        let newItemData = new GamePlayGroupItem({},{
            iconName: iconNameClean,
            category: iconData.category,
            status: "new",
        });
        return newItemData;
    }

    getSelectJoinImpact(refTwice, refTriple, perToCheck){
        let refSelect = undefined;
        //check possible to add join impact
        if(perToCheck >= this.config.twiceJoinOnCountRatio){
            //add item start on 80% of target item, in twice impact
            if(perToCheck >= this.config.tripleJoinOnCountRatio){
                //add item join in triple impact
                if(refTriple.length > 0){
                    let randomIndex = Math.floor(Math.random() * refTriple.length);
                    refSelect = refTriple[randomIndex];
                }
            }

            //not select triple yet
            if(refSelect === undefined){
                let randomIndex = Math.floor(Math.random() * refTwice.length);
                refSelect = refTwice[randomIndex];
            }
        }

        if(refSelect === undefined){
            return [];
        } else {
            let impJoin = [];
            refSelect.forEach(ref =>{
                if(ref !== this.config.impactType){
                    let behBeanPotion = this.refBehaviourTypes.find(b =>
                        b.type === ref
                        && b.join.toUpperCase().includes('POTION'));

                    impJoin.push(behBeanPotion);
                }
            });
            return  impJoin;
        }
    }
    getImpactsTwiceJoinByType(type){
        let imps = [];
        this.refTwiceImpact.forEach(ref =>{
            if(ref.find(r => r === type)){
                imps.push(ref);
            }
        });
        return imps;
    }
    getImpactsTripleJoinByType(type){
        let imps = [];
        this.refTripleImpact.forEach(ref =>{
            if(ref.find(r => r === type)){
                imps.push(ref);
            }
        });
        return imps;
    }

    defineIndexOfGenericItem(count) {
        if (this.config.genItemCount === 0) {
            this.genericItemIndex = -1;
            this.countGenericItemPerGroup = 0;
        } else {
            this.countGenericItemPerGroup = Math.floor(this.config.itemRootCount / this.config.genItemCount);
            this.genericItemIndex = Math.ceil(count * this.config.genItemCount / this.config.itemRootCount) - 1;
        }
    }

    initializeItemData(itemGroupData, behaviourBean, joinImpact, count, isPotion) {
        let itemData = itemGroupData.item;
        this.setDefaultFormBehavior(itemData, joinImpact);
        this.updateItemBehavior(itemData, behaviourBean, joinImpact);
        this.setItemColor(itemData);
        this.setItemTarget(itemData);
        this.setImpactReceivers(itemData);
        this.setSignLifeTimeDirect(itemData);
        this.setEquipmentCodeByWeaponName(itemData, itemData.iconName);
        this.setItemName(itemData, isPotion);
        this.applySiblingConfig(itemGroupData, count, behaviourBean);

        if (this.isBagSlotConfig()) {
            itemData.modes = [];
        } else if(this.isEnemyDmgConfig()){
            itemData.modes = [ItemModes.Battle];
        }
    }

    initUpdateExitData(itemGroupData, joinImpact, count){
        let itemData = itemGroupData.item;
        let behaviourBean = itemData.behaviours[0].behaviourBean;
        this.setDefaultFormBehavior(itemData, joinImpact);
        this.updateItemBehavior(itemData, behaviourBean, joinImpact);
        this.setItemTarget(itemData);
        this.setImpactReceivers(itemData);
        this.setSignLifeTimeDirect(itemData);
        this.applySiblingConfig(itemGroupData, count, behaviourBean);
    }

    isBagSlotConfig(){
        return this.config.impactType === BehaviourByNames.incBagSlot;
    }

    isEnemyDmgConfig(){
        return this.config.impactType === BehaviourByNames.damageEnemy;
    }

    isDefineCustomLevelAppear(){
        return !this.config.isApplySimulator && (this.config.impactType === BehaviourByNames.reviveHero || this.config.impactType === BehaviourByNames.reviveSupport);
    }

    isKindOfGoldImpact() {
        return this.config.impactType === BehaviourByNames.incGoldDrop || this.config.impactType === BehaviourByNames.incGoldEnemyDrop;
    }

    setGenerateMsg(msg){
        this.generateMsg = msg;
    }

    //Sibling config
    applySiblingConfig(itemGroupData, count, behaviourBean){
        let itemData = itemGroupData.item;

        //to avoid generate item on level which not unlock yet
        let minLevelAppear = 0;
        let heroWhoCanUse = this.heroAndWeaponSupportUtils.heroesWhoCanUseItem(itemData);
        if(heroWhoCanUse.length === 1 && heroWhoCanUse.includes(3)){
            minLevelAppear = 20;
        }

        if(this.isDefineCustomLevelAppear()){
            let levelRangePerItem = Math.floor(this.config.levelRangePeriod / this.config.itemRootCount);
            let nextStartLevel = levelRangePerItem * (count-1);
            let startLevel = this.config.startLevel + nextStartLevel;
            if(startLevel < minLevelAppear){
                startLevel = minLevelAppear;
            }
            //to apply default level appear
            itemData.gameLevel = {
                levelStart: startLevel,
                levelEnd: startLevel + levelRangePerItem
            };
        } else if(this.genericItemIndex > -1){
            let indexFromCountOnCurrentGenIndex = ((count-1) % this.countGenericItemPerGroup);
            //console.log('gCount: '+this.countGenericItemPerGroup+' count: '+count+' index: '+this.genericItemIndex+" rootPower: "+(indexFromCountOnCurrentGenIndex));
            let nextStartLevel = Math.round((this.config.levelRangePeriod/this.config.nextItemLvRatio/100)* Math.pow(indexFromCountOnCurrentGenIndex, 2));
            //to apply default level appear
            let levelAppear = this.config.lstGeneric[this.genericItemIndex].startLevel + nextStartLevel;
            if(levelAppear < minLevelAppear){
                levelAppear = minLevelAppear;
            }
            itemData.gameLevel = {
                levelStart: levelAppear,
                levelEnd: this.config.lstGeneric[this.genericItemIndex].endLevel + nextStartLevel
            };
        } else {
            let index = count -1;
            let nextStartLevel = Math.round((this.config.levelRangePeriod/this.config.nextItemLvRatio/100)* Math.pow(index, 2));
            let startLevel = this.config.startLevel + nextStartLevel;
            if(startLevel < minLevelAppear){
                startLevel = minLevelAppear;
            }
            //to apply default level appear
            itemData.gameLevel = {
                levelStart: startLevel,
                levelEnd: startLevel + this.config.levelRangePeriodPerItem
            };
        }
        itemData.gameLevel.kpiLevel = UGSItemUtils.getKpiLevelByDirection(behaviourBean, itemData.gameLevel.levelStart);
        this.siblingUtils.defineDefaultConfig(itemGroupData.config, itemData);
        itemGroupData.config.endLevel = itemData.gameLevel.levelEnd;
        itemGroupData.config.impactType = behaviourBean.type;
    }

    //item behavior
    setDefaultFormBehavior(itemData, joinImpact) {
        let formBehaviour = {
            behaviourBean: null,
            type: "",
            gamePlayImpact: {
                bean:{},
                value: null,
                valueType: null,
                periodValue: null,
                periodType: null,
                skillType: null,
                visualEffectOfTargets: [],
                receivers: [],
            }
        };
        itemData.behaviours = [];
        itemData.behaviours.push(formBehaviour);

        //add join
        if(joinImpact.length > 0){
            for(let i=0;i<joinImpact.length;i++){
                itemData.behaviours.push(lodash.cloneDeep(formBehaviour));
            }
        }
    }
    updateItemBehavior(itemData, behaviourBean, joinImpact) {
        for(let index = 0;index<itemData.behaviours.length;index++){
            let formBehaviour = itemData.behaviours[index];
            let isCheckToUpdatePercentByPeriod = false;
            if(index === 0){
                formBehaviour.behaviourBean = lodash.cloneDeep(behaviourBean);
            } else {
                formBehaviour.behaviourBean = lodash.cloneDeep(joinImpact[index-1]);
            }
            if(formBehaviour){
                formBehaviour.gamePlayImpact.valueType = null;
                formBehaviour.gamePlayImpact.value = null;

                let refValueTypes = this.getValueTypeGroupByBehaviour(formBehaviour);
                let isPercentType = false;
                refValueTypes.forEach(r => {
                    if (r.value.includes("Percentage")) {
                        formBehaviour.gamePlayImpact.bean.valueType = r;
                        isPercentType = true;
                    }
                });
                if(this.isBagSlotConfig() && refValueTypes.length > 0){
                    formBehaviour.gamePlayImpact.bean.valueType = refValueTypes[0];
                    let valueSlot = this.config.lstGeneric[this.genericItemIndex].minBonus;
                    let valueTypeShortcut = ValueTypeShortcut[formBehaviour.gamePlayImpact.bean.valueType.value];
                    if (valueTypeShortcut) {
                        formBehaviour.gamePlayImpact.valueType = valueTypeShortcut.type;
                        formBehaviour.gamePlayImpact.value = valueSlot;
                    }
                } else if(!isPercentType && refValueTypes.length > 0){
                    let randomIndex = Math.floor(Math.random() * refValueTypes.length);
                    formBehaviour.gamePlayImpact.bean.valueType = refValueTypes[randomIndex];
                    let valueTypeShortcut = ValueTypeShortcut[formBehaviour.gamePlayImpact.bean.valueType.value];
                    if (valueTypeShortcut) {
                        formBehaviour.gamePlayImpact.valueType = valueTypeShortcut.type;
                        formBehaviour.gamePlayImpact.value = valueTypeShortcut.value;
                    }
                } else if(isPercentType) {
                    let valueTypeShortcut = ValueTypeShortcut[formBehaviour.gamePlayImpact.bean.valueType.value];
                    if (valueTypeShortcut) {
                        formBehaviour.gamePlayImpact.valueType = valueTypeShortcut.type;
                        //custom to set by input range
                        formBehaviour.gamePlayImpact.value = this.getBonusValueFromConfig();

                        isCheckToUpdatePercentByPeriod = true;
                    }
                }
                formBehaviour.type = formBehaviour.behaviourBean.type;
                this.checkAndSetPeriodType(itemData, formBehaviour);
                this.checkAndSetSkillType(formBehaviour);

                if(isCheckToUpdatePercentByPeriod){
                    this.updateBonusValueDependOnPeriodSelected(formBehaviour.gamePlayImpact);
                }
            }
        }
    }

    //we need to fix is, by include how to period type and period value to kpi
    //to fix issue, kpi estimate kill in 2s but after apply item on game play was not correct
    updateBonusValueDependOnPeriodSelected(gamePlayImpact){
        let percent = gamePlayImpact.value;
        let periodInDuration = 0;
        if(gamePlayImpact.periodType === PeriodTypeNames.NumOfGotHits){
            let gotHitCount = gamePlayImpact.periodValue;
            let enemyAttackSpeed = this.gamePlayService.ratiosConfig.hitPerSeconds;
            periodInDuration = gotHitCount * enemyAttackSpeed;
            if(this.isKindOfGoldImpact()) {
                //to fix, Too much gold bonus from item - 1 got hit will get 19 gold while gold drop only 17
                periodInDuration = periodInDuration / 10;
            }
            percent = percent * periodInDuration;
        } else if(gamePlayImpact.periodType === PeriodTypeNames.NumOfTaps){
            let tapCount = gamePlayImpact.periodValue;
            let hitPerSecond = this.gamePlayService.ratiosConfig.hitPerSeconds;
            periodInDuration = tapCount * 10 / hitPerSecond;
            if(this.isKindOfGoldImpact()) {
                //to fix, Too much gold bonus from item - bird gold drop amount 267.8 % every 5 taps
                periodInDuration = periodInDuration / 15;
            }
            percent = percent * periodInDuration;
        } else if(gamePlayImpact.periodTypes === PeriodTypeNames.DurationInSeconds){
            periodInDuration = gamePlayImpact.periodValue;
            percent = percent * periodInDuration;
        }

        gamePlayImpact.value = percent;
    }

    setItemColor(itemData){
        let mainBehaviour = this.getMainBehaviour(itemData);
        if (mainBehaviour){
            itemData.color = BehaviourUtils.getColorNameByCat(mainBehaviour.behaviourBean.cat);
        } else {
            itemData.color = null;
        }
    }
    setItemTarget(itemData){
        let mainBehaviour = this.getMainBehaviour(itemData);
        if (mainBehaviour) {
            itemData.targetSelect = mainBehaviour.behaviourBean.owners;
            itemData.usageRules = mainBehaviour.behaviourBean.usageRules;
        }
    }
    setImpactReceivers(itemData){
        if (itemData.behaviours.length > 0){
            itemData.behaviours.forEach(behaviour =>{
                behaviour.gamePlayImpact.receivers = [];
                behaviour.behaviourBean.receivers.forEach(rc => {
                    behaviour.gamePlayImpact.receivers.push(rc.value);
                });
            });
        }
    }
    setSignLifeTimeDirect(itemData){
        let mainBehave = this.getMainBehaviour(itemData);
        if (mainBehave) {
            if(itemData.behaviours.length > 1){
                //can set as only duration for multiple impact

                //to get impact life in biggest time
                let selectedLifeTime = undefined;
                for(let index = 0;index<itemData.behaviours.length;index++){
                    let refDLT = LifeTimeUtils.getDirectLifeTime(itemData.behaviours[index]);
                    if (refDLT !== null) {
                        if(selectedLifeTime === undefined || selectedLifeTime.value < refDLT.value){
                            selectedLifeTime = refDLT;
                        }
                    }
                }
                if (selectedLifeTime !== undefined) {
                    //add auto transfer impact duration to life time
                    itemData.lifeTime.bean = selectedLifeTime.bean;
                    this.assignLifeTime(itemData, selectedLifeTime);
                } else {
                    let refOnlyTimes = [];
                    for(let index = 0;index<itemData.behaviours.length;index++){
                        let refDLTShort = LifeTimeUtils.getLifeTimeShortcutList(itemData.behaviours[index], true);
                        refDLTShort.forEach(ref =>{
                            if(ref.value.includes('Duration')){
                                refOnlyTimes.push(ref);
                            }
                        });
                    }
                    if(refOnlyTimes.length === 0){
                        this.setGenerateMsg('life in time not found');
                    } else {
                        let randomIndex = Math.floor(Math.random() * refOnlyTimes.length);
                        itemData.lifeTime.bean = refOnlyTimes[randomIndex];

                        let lifeTime = ItemLifeTimeShortcut[itemData.lifeTime.bean.value];
                        this.assignLifeTime(itemData, lifeTime);
                    }
                }
            } else {
                //for single impact type
                let refDirectLifeTime = LifeTimeUtils.getDirectLifeTime(mainBehave);
                if (refDirectLifeTime !== null) {
                    //add auto transfer impact duration to life time
                    itemData.lifeTime.bean = refDirectLifeTime.bean;
                    this.assignLifeTime(itemData, refDirectLifeTime);
                } else {
                    //set by randomize
                    let refLifeTimes = LifeTimeUtils.getLifeTimeShortcutList(mainBehave, true);
                    let randomIndex = Math.floor(Math.random() * refLifeTimes.length);
                    itemData.lifeTime.bean = refLifeTimes[randomIndex];

                    let lifeTime = ItemLifeTimeShortcut[itemData.lifeTime.bean.value];
                    this.assignLifeTime(itemData, lifeTime);
                }
            }
        }
    }
    assignLifeTime(itemData, lifeTime){
        if (lifeTime){
            itemData.lifeTime.type = lifeTime.type;
            itemData.lifeTime.limit = lifeTime.value;
        }else {
            itemData.lifeTime.type = '';
            itemData.lifeTime.limit = 0;
        }
    }
    getBonusValueFromConfig(){
        if(this.genericItemIndex > -1){

            //get direct value without rnd
            return this.config.lstGeneric[this.genericItemIndex].minBonus;

            /*get by rnd in range
            let range = this.config.lstGeneric[this.genericItemIndex].maxBonus - this.config.lstGeneric[this.genericItemIndex].minBonus;
            range += 1;//to add possible random in max value
            let rnd = Math.floor(Math.random() * range);
            return this.config.lstGeneric[this.genericItemIndex].minBonus + rnd;*/
        } else{
            let range = this.config.maxBonus - this.config.minBonus;
            range += 1;//to add possible random in max value
            let rnd = Math.floor(Math.random() * range);
            return this.config.minBonus + rnd;
        }
    }
    getValueTypeGroupByBehaviour(formBehaviour) {
        if (!formBehaviour.behaviourBean) return [];
        let rules = ValueTypeGroupByBehaviourType[formBehaviour.behaviourBean.type]?.valueTypes;

        let refs = [];
        for (const valueTypeShortcutKey in ValueTypeShortcut) {
            let valueShortcut = ValueTypeShortcut[valueTypeShortcutKey];
            if (rules
                && rules.find(r => r === valueShortcut.type)){
                refs.push({text: valueShortcut.value + ' ' + valueShortcut.unit, value:valueTypeShortcutKey});
            }
        }
        return refs;
    }
    getMainBehaviour(itemData){
        if (itemData.behaviours.length > 0){
            return itemData.behaviours[0];
        } else {
            return null;
        }
    }
    checkAndSetPeriodType(itemData, formBehaviour){
        formBehaviour.gamePlayImpact.periodType = null;
        formBehaviour.gamePlayImpact.periodValue = null;

        if(this.shouldSetPeriodType(formBehaviour)){
            let isSet = this.shouldRequirePeriodTypeForEquip(formBehaviour);
            if(isSet && itemData.category === ItemCategoryNames.potion){
                isSet = Math.floor(Math.random() * 3) === 1;
            } else if(!isSet && itemData.category !== ItemCategoryNames.potion) {
                isSet = true;
            }

            if(isSet){
                let refPeriod = this.getPeriodRefs(formBehaviour);
                if(refPeriod.length > 0) {
                    let randomIndex = Math.floor(Math.random() * refPeriod.length);
                    formBehaviour.gamePlayImpact.bean.periodType = refPeriod[randomIndex];
                }

                if (formBehaviour.gamePlayImpact.bean.periodType) {
                    let periodTypeShortcut = PeriodTypeShortcut[formBehaviour.gamePlayImpact.bean.periodType.value];
                    if (periodTypeShortcut){
                        formBehaviour.gamePlayImpact.periodType = periodTypeShortcut.type;
                        formBehaviour.gamePlayImpact.periodValue = periodTypeShortcut.value;
                    }
                }
            }
        }
    }
    getPeriodRefs(formBehaviour) {
        return BehaviourAndPeriodUtils.getPeriodTypeList(formBehaviour);
    }
    shouldSetPeriodType(formBehaviour) {
        if (!formBehaviour.behaviourBean) return false;
        return ValueTypeGroupByBehaviourType[formBehaviour.behaviourBean.type]?.periodTypes;
    }
    shouldRequirePeriodTypeForEquip(formBehaviour) {
        if (!formBehaviour.gamePlayImpact) return false;
        if (formBehaviour.behaviourBean.type == null) return false;
        let valGroupBeType = ValueTypeGroupByBehaviourType[formBehaviour.behaviourBean.type];
        if(!valGroupBeType) return false;
        if(valGroupBeType.requirePeriodForEquip === undefined) return false;
        return valGroupBeType.requirePeriodForEquip;
    }
    checkAndSetSkillType(formBehaviour){
        if (this.shouldSetSkillType(formBehaviour)){
            let rndSet = Math.floor(Math.random() * 10) <= 8;
            //to possible automate generate increase powerup for all skills when none set skill
            if(rndSet){
                let randomIndex = Math.floor(Math.random() * this.refActiveSkillTypes.length);
                formBehaviour.gamePlayImpact.skillType = this.refActiveSkillTypes[randomIndex];
            }
        }
    }
    shouldSetSkillType(formBehaviour) {
        if (!formBehaviour.behaviourBean) return false;
        return ValueTypeGroupByBehaviourType[formBehaviour.behaviourBean.type]?.skillTypes;
    }
    setItemName(itemData, isPotion){
        let refName = [];
        if(isPotion){
            let minUsageCounter = this.findMinArrayUsage(this.refPotionNames);
            this.refPotionNames.forEach(ref =>{
                if(!this.itemNameSelectPerGenerate.find(s => s.name === ref.name) && ref.usage <= minUsageCounter){
                    refName.push(ref);
                }
            });
            if(refName.length === 0){
                this.refPotionNames.forEach(ref =>{
                    if(ref.usage <= minUsageCounter){
                        refName.push(ref);
                    }
                });
            }
        } else{
            let minUsageCounter = this.findMinArrayUsage(this.refWeaponNames);
            this.refWeaponNames.forEach(ref =>{
                if(!this.itemNameSelectPerGenerate.find(s => s.name === ref.name) && ref.usage <= minUsageCounter){
                    refName.push(ref);
                }
            });
            if(refName.length === 0){
                this.refWeaponNames.forEach(ref =>{
                    if(ref.usage <= minUsageCounter){
                        refName.push(ref);
                    }
                });
            }
        }

        let randomIndex = Math.floor(Math.random() * refName.length);
        let selectName = refName[randomIndex];

        this.itemNameSelectPerGenerate.push({
            name: selectName.name,
            usage: selectName.usage + 1,
        });

        itemData.title.en = selectName.name;
        itemData.title.fr = selectName.name_fr;
        itemData.title.es = selectName.name_es;
        itemData.title.km_u = selectName.name_km;
        let sampleDesc = this.getSampleDescription(itemData, false);
        itemData.description.en = sampleDesc;
        itemData.description.fr = sampleDesc;
        itemData.description.es = sampleDesc;
        itemData.description.km_u =this.getSampleDescription(itemData, true);
    }
    findMinArrayUsage(arr){
        let len = arr.length;
        let min = Infinity;
        while (len--) {
            if (arr[len].usage < min) {
                min = arr[len].usage;
            }
        }
        return min;
    }
    getSampleDescription(itemData, isKhmer) {
        let description = "";

        //impact
        let behavArray = [];

        itemData.behaviours.forEach(behaviour => {
            if(isKhmer){
                if (behaviour.behaviourBean.des && behaviour.behaviourBean.des.details_km){
                    behavArray.push(behaviour.behaviourBean.des.details_km + " " + this.renderGameplayImpact(behaviour, isKhmer));
                }
            } else {
                if (behaviour.behaviourBean.des && behaviour.behaviourBean.des.details){
                    behavArray.push(behaviour.behaviourBean.des.details + " " + this.renderGameplayImpact(behaviour, isKhmer));
                }
            }

        });

        let descBehav = this.getSplitDescriptionOnArray(behavArray, isKhmer);
        if (descBehav !== "") {
            description += descBehav;
        }

        return description;
    }
    renderGameplayImpact(behaviour, isKhmer) {
        if (behaviour.gamePlayImpact.value < 0 || !behaviour.gamePlayImpact.valueType) {
            return '';
        }
        let des = "";
        let bonus = behaviour.gamePlayImpact.value;
        let bean = behaviour.gamePlayImpact.bean;
        if(bean !== null && bean.valueType !== null && bean.valueType.value === "DirectDisplayValue"){
            des += bean.valueType.text;
        } else if(behaviour.gamePlayImpact.valueType === "DirectValue"){
            des = SMPNum.fromPowerString(bonus).ToReadableAlphabetV2();
        } else {
            des = bonus.toFixed(2);
        }

        if(isKhmer){
            des +=GamePlayImpactType.valueTypeKhmerSymbols[behaviour.gamePlayImpact.valueType];
        } else {
            des += " ";
            des +=GamePlayImpactType.valueTypeSymbols[behaviour.gamePlayImpact.valueType];
        }

        let txtEvery = ' every ';
        if(isKhmer){
            txtEvery = ' រាល់ ';
        }

        if (behaviour.gamePlayImpact.periodValue > 0) {
            des += txtEvery + behaviour.gamePlayImpact.periodValue
                + " ";
            if(isKhmer){
                des += GamePlayImpactType.periodTypeKhmerSymbols[behaviour.gamePlayImpact.periodType];
            } else {
                des += GamePlayImpactType.periodTypeSymbols[behaviour.gamePlayImpact.periodType];
            }
        }

        return des;
    }
    getSplitDescriptionOnArray(array, isKhmer) {
        let desc = "";

        for (let i = 0; i < array.length; i++) {
            if (desc !== "") {
                if (i === array.length - 1) {
                    if(isKhmer) {
                        desc += "នឹង";
                    } else {
                        desc += " and ";
                    }
                } else {
                    if(isKhmer){
                        desc += " "
                    } else {
                        desc += ", ";
                    }
                }
            }
            desc += array[i];
        }
        return desc;
    }

    //icon
    setIconByRandomize(itemRootData){
        if(this.isBagSlotConfig()){
            itemRootData.items[0].iconName = "inventory_00";//this.getCleanIconName(iconData.name);
            itemRootData.items[0].category = ItemCategoryNames.potion;
            itemRootData.category = ItemCategoryNames.potion;
        } else {
            if(this.iconListByTotalUsed.length === 0){
                this.setGenerateMsg("No icons for randomize");
                console.log('no icons for randomize');
            } else {
                let randomIndex = Math.floor(Math.random() * this.iconListByTotalUsed.length);
                let iconData = this.iconListByTotalUsed[randomIndex];
                itemRootData.items[0].iconName = this.heroAndWeaponSupportUtils.getCleanIconName(iconData.name);
                itemRootData.items[0].category = iconData.category;
                itemRootData.category = iconData.category;
            }
        }
    }
    setEquipmentCodeByWeaponName(itemData, name){
        let weaponId = "";
        let foundInWeapon = this.refEquipmentData.find(ref => ref.weaponName === name);
        if (foundInWeapon) {
            weaponId = foundInWeapon.weaponId;
        }
        else {
            let foundInShield = this.refShieldData.find(ref => ref.weaponName === name);
            if (foundInShield) {
                weaponId = foundInShield.weaponId;
            }
        }
        itemData.equipmentCode = weaponId.toString();
    }
}

export default AutomateItemGeneratorUtils;