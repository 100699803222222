class SMPNum2 {

    constructor(doubleValue) {
        this.DEFINE_ZERO = "number-is-zero";
        this._main = 2;
        if (doubleValue) {
            this.setNumDouble(doubleValue);
            this.originalDouble = doubleValue;
        } else {
            this.originalDouble = 0;
            this.isZero = true;
            this._power = 0;
        }

    }

    static main() {
        return 2.0
    }

    //constructor
    static fromDouble(doubleNumber) {
        if (doubleNumber.toString() === "NaN") {
            throw ("SMPNumber cannot init with NaN number!");
        }
        if (!isFinite(doubleNumber)) {
            throw ("fromDouble(doubleNumber): SMPNumber cannot init with infinity number! : " + doubleNumber);
        }
        if (doubleNumber < 0) {
            throw new ("SMPNumber cannot init with negative number! " + doubleNumber);
        }
        let obj = new SMPNum2();
        if (doubleNumber === 0) {
            obj.isZero = true;
        } else {
            obj.isZero = false;
            obj.setPower(Math.log10(doubleNumber / obj._main));
            if (!isFinite(obj._power)) {
                throw ("_power is infinity number!");
            }
            //CheckAndRoundInit();
        }
        return obj;
    }

    static fromNum(doubleNumber) {
        return SMPNum2.fromDouble(doubleNumber);
    }

    static fromPowerDouble(doublePower) {
        let newNum = new SMPNum2();
        newNum.setPower(doublePower);
        return newNum;
    }

    static pow(smpNum, power) {
        if (SMPNum2.IsNull(smpNum)) {
            throw  "pow exception main side";
        }
        let newMain = new SMPNum2();
        //newMain._power =
        newMain.setPower(smpNum._power);

        let res = new SMPNum2(1);
        while (power !== 0) {

            if ((power & 1) !== 0) {
                res = SMPNum2.multSmpNum(res, newMain);
            }
            //res *= newMain;
            power >>= 1;
            //ATTENTION redefinition d'operator.
            newMain = SMPNum2.multSmpNum(newMain, newMain);
        }

        return res;
    }

    PowfromInt(intPower) {
        let res = SMPNum2.pow(this, intPower);
        return res;
    }

    static fromPowerStringOrZero(str){
        if(str === '' || str === 'number-is-zero'){
            let newNum = new SMPNum2();
            newNum.isZero = true;
            return newNum;
        } else {
            return this.fromPowerString(str);
        }
    }

    static fromPowerString(stringPower) {
        let newNum = new SMPNum2();
        if (newNum._power === newNum.DEFINE_ZERO) {
            newNum.isZero = true;
        } else {
            newNum.pPowerFromString(stringPower);
        }

        return newNum;
    }

    static GetReadableAsLetterFormat(_CurrentMoney) {

        let result = null;

        ////////console.log(_CurrentMoney);
        if (_CurrentMoney == null) {
            _CurrentMoney = "";
        }
        //Variables for final return
        let _sMoneyReturned = "";
        let _sMoneyFindMain = "";
        let _sMoneyFindSecond = "";

        let _sTypeOfMoney = "";
        // STEP 1 :Check if the string contain a money
        _CurrentMoney = SMPNum2.GetRealValue(_CurrentMoney);

        if (_CurrentMoney === "Infinity") {
            result = "MAX";
        }

        if (_CurrentMoney == null) {
            //Debug.LogError ("_CurrentMoney is null");
            result = null;
        }

        //step 2 : GET THE RIGHT LETTER OF NUMBER
        if (_CurrentMoney.length > 3) {
            //Try to chatch the right type of money
            let _iTypeOfMoney = parseInt(_CurrentMoney.length / 3);


            if (_CurrentMoney.length % 3 === 0) {
                if (_iTypeOfMoney - 1 >= SMPNum2.DefaultTypeOfMoney().length) {
                    let result;
                    result = parseFloat(_CurrentMoney);
                    if (result) {
                        return result.toFixed() + '0.##E+0'
                        //return result.ToString ("0.##E+0");
                    } else {
                        return "MAX";
                    }

                }
                _sTypeOfMoney = SMPNum2.DefaultTypeOfMoney() [_iTypeOfMoney - 1];
            } else {
                if (_iTypeOfMoney >= SMPNum2.DefaultTypeOfMoney().length) {
                    let result;
                    //if (double.TryParse (_CurrentMoney, out result)) {
                    result = parseFloat(_CurrentMoney);

                    if (result) {
                        //return result.ToString ("0.##E+0");
                        return result.toFixed() + '0.##E+0'
                    } else {
                        return "MAX";
                    }

                }
                _sTypeOfMoney = SMPNum2.DefaultTypeOfMoney() [_iTypeOfMoney];
            }
        }
        //Step 3 : Resize the string for number
        if (_CurrentMoney.length > 0) {
            //Take the Main digit (before ",")
            let _iDigitMain = _CurrentMoney.length % 3;
            if (_iDigitMain !== 0) {
                for (let i = 0; i < _iDigitMain; i++) {
                    _sMoneyFindMain += _CurrentMoney [i];
                }

                _sMoneyReturned += _sMoneyFindMain;
            } else {
                for (let i = 0; i < 3; i++) {
                    if (i < _CurrentMoney.length) {
                        _sMoneyFindMain += _CurrentMoney[i];
                    }
                }
                _iDigitMain = _sMoneyFindMain.length;
                _sMoneyReturned += _sMoneyFindMain;
            }
            //Take the second digit (after ","
            if (_CurrentMoney.length > 0) {
                for (let i = 0 + _iDigitMain; i < 2 + _iDigitMain; i++) {
                    if (i < _CurrentMoney.length) {
                        _sMoneyFindSecond += _CurrentMoney[i];
                    }
                }
                // Add "," if needed
                if (_iDigitMain > 0 && _sMoneyFindSecond !== "")
                    _sMoneyReturned += ".";
                //Add to the main string the second digit string
                _sMoneyReturned += _sMoneyFindSecond;
            }
            //Add the type of money to the Main string
            _sMoneyReturned += _sTypeOfMoney;
            //return _sMoneyReturned;
            result = _sMoneyReturned;
        } else {
            result = "";
        }
        //return "";


        return result
    }

//static utils
    static IsNull(smpNumnumber) {
        if (smpNumnumber === null || smpNumnumber === undefined) {
            return true;
        }
        return false;
    }


//privates
    pPowerFromString(stringPower) {
        let powerDouble = parseFloat(stringPower);
        if (isNaN(powerDouble)) {
            throw "Try to cast invalid string to double!";
        } else {
            this.isZero = false;
            this.setPower(powerDouble);
        }

    }

    pSupportLetterFormat(s_CurrentMoney) {
        ////////console.log(s_CurrentMoney);


        if (s_CurrentMoney == null) {
            return false;
        }
        // STEP 1 :Check if the string contain a money
        s_CurrentMoney = SMPNum2.GetRealValue(s_CurrentMoney);

        if (s_CurrentMoney === "Infinity") {
            return false;
        }

        if (s_CurrentMoney == null) {
            //Debug.LogError("_CurrentMoney is null");
            return false;
        }
        if (s_CurrentMoney.length > 3) {
            //Try to chatch the right type of money
            let _iTypeOfMoney = s_CurrentMoney.length / 3;

            if (s_CurrentMoney.length % 3 === 0) {
                if (_iTypeOfMoney - 1 >= SMPNum2.DefaultTypeOfMoney().length) {
                    return false;
                }
            } else {
                if (_iTypeOfMoney >= SMPNum2.DefaultTypeOfMoney().length) {
                    return false;

                }
            }
        }

        return true;


    }


///sttic
    static DefaultTypeOfMoney() {
        return [
            "",
            "K",
            "M",
            "B",
            "T"
        ]
    }

    static AlphabetTypeOfMoney() {
        return [
            "A",
            "B",
            "C",
            "D",
            "E",
            "F",
            "G",
            "H",
            "I",
            "J",
            "K",
            "L",
            "M",
            "N",
            "O",
            "P",
            "Q",
            "R",
            "S",
            "T",
            "U",
            "V",
            "W",
            "X",
            "Y",
            "Z",
        ]
    }

//getter speciials

    setNumDouble(doubleNumber) {
        if (!isFinite(doubleNumber)) {
            throw "setNumDouble(doubleNumber): SMPNumber cannot init with infinity number! : " + doubleNumber;
        }
        if (doubleNumber < 0) {
            throw "SMPNumber cannot init with negative number!";
        }
        if (doubleNumber === 0) {
            this.isZero = true;
        } else {
            this.isZero = false;
            this._power = Math.log10(doubleNumber / SMPNum2.main());
            //this._power = Math.log10(doubleNumber / this._main);
            if (!isFinite(this._power)) {
                throw "_power is infinity number!";
            }
            //CheckAndRoundInit();
        }
    }

    setFromSMPNum(number) {
        if (number === null || number === undefined) {
            throw  "SMPNumber cannot init with null number!";
        }
        if (number.isZero) {
            this.isZero = true;
        } else {
            this.isZero = false;
            this._power = number._power;
            //CheckAndRoundInit();
        }
    }

    round ()
    {
        if (!this.isZero)
        {
            let valueInDouble = this._main * Math.pow(10,this._power);
            if (valueInDouble < 1) valueInDouble = 1;
            if (valueInDouble < 1000)
            {
                valueInDouble = Math.round(valueInDouble);
                this._power = Math.log10(valueInDouble/this._main);
                if(!isFinite(this._power))
                {
                    console.log("_power is infinity number!");
                }
                //Debug.Log(" valueInDouble : " + valueInDouble);
                //valueInDouble = this._main * Math.pow(10,this._power);
                //Debug.Log(" after round : " + valueInDouble);
            }

        }

        return this;
    }

    setPower(doublePower) {
        if (!isFinite(doublePower)) {
            throw ("Cannot set power with infinity number!");
        }
        this.isZero = false;
        this._power = doublePower;
    }

//static operator
    static multSmpNum(leftSide, rightSide) {

        if (SMPNum2.IsNull(leftSide)) {
            throw "mult leftSide exception";
        }

        if (SMPNum2.IsNull(rightSide)) {
            throw "mult rightSide exception";
        }

        if (leftSide.isZero) {
            return leftSide;
        }
        if (rightSide.isZero) {
            return rightSide;
        }

        let result = new SMPNum2();

        let newMainDouble = SMPNum2.main() * SMPNum2.main();

        if (!isFinite(newMainDouble)) {
            throw "newMain is infinity!"
        }
        // if (double.IsInfinity(newMain))
        // {
        //     throw new OverflowException("newMain is infinity!");
        // }
        let newPower = Math.log10(newMainDouble / SMPNum2.main());
        result.setPower(leftSide._power + rightSide._power + newPower);

        return result;
    }

    //operator log
    static logSmpNum(smpNumValue) {
        //based on formula log(a*b) == log(a) + log(b)
        let res = Math.log10(2) + smpNumValue._power * Math.log10(10);
        return res; //res.toFixed(2);
    }

    static logBaseSmpNum(smpNumValue, baseNumber) {

        if (smpNumValue.ToDoubleValue() < baseNumber) {
            //return new SMPNum2(0);
            ////console.log(smpNumValue.ToDoubleValue());
            ////console.log(baseNumber);
            //throw 'PUTAIN UNE ERREUR A LA CON, IL FAUT VERIFIER ET FAIURE UN TRY CATCH POUR AFFICHER QUEL LEVEL EST MERDIQUE'
        }

        if (!smpNumValue) {
            throw "main side is null";
        }
        const logBase = (n, base) => {
            if (base === 1) {
                throw 'division by zero in logbase';
            }
           //return Math.log10(n) / Math.log10(base)

            if (n === 1) {
                //////console.log(n);
            }
            return Math.log10(n) / Math.log10(base)
        };


        //return logBase(smpNumValue,baseNumber);


        //second essai
        // let logNum = new SMPNum2(SMPNum2.logSmpNum(smpNumValue));
        // let logBase = new SMPNum2(Math.log(baseNumber));
        // let result = SMPNum2.divSmpNum(logNum,logBase);


        //SMPNum result = new SMPNum(1) * System.Math.Log(_main, baseNumber) + System.Math.Log(10, baseNumber) * b.Power;
        //premier essai

       // let t1 = new SMPNum2(logBase(smpNumValue._main, baseNumber));

        let t1 = logBase(smpNumValue._main, baseNumber);
        //let t2 = new SMPNum2(logBase(10, baseNumber));
        //let nPower = new SMPNum2(smpNumValue._power);

        let temp = logBase(10,baseNumber) * smpNumValue._power;
        //let t2 = new SMPNum2(temp);

        let temp2 = t1 + temp;

        //let debugMessage = smpNumValue.ToReadableAlphabetV2();

        if (temp2 === 0) {

            //////console.log('problem ' + debugMessage);
        }

        /*
        let result = SMPNum2.plus(
            t1,
            //SMPNum2.multSmpNum(nPower, t2)
            t2
        )*/

        if (temp2 < 0) {
            //TODO PUTAIN
       //     throw 'ERROR, resylt of logbase calculation < 0 base: ' + baseNumber + ': ' + smpNumValue.ToReadableAlphabetV2()
        }

        let result = new SMPNum2(temp2);

        //let result = new SMPNum2(1) * t1 + t2 * smpNumValue._power;

        return result;

        /*
        let logMain = logBase(smpNumValue._main,baseNumber);

        let logBaseNumber = logBase(10,baseNumber);
        let numValuePower = logBaseNumber * smpNumValue._power;

        //let result = SMPNum2.multSmpNum(new SMPNum2(1), new SMPNum2(logMain));
        let result = SMPNum2.plus(new SMPNum2(logMain),new SMPNum2(numValuePower));
        //return result.ToReadableAlphabetV2();
        return result;


         */


        // let logMain = logBase(smpNumValue._main,baseNumber);
        // let logBaseNumber = logBase(10,baseNumber);
        // let numValuePower = logBaseNumber + smpNumValue._power;
        //
        // let result = 1 * logMain + logBaseNumber * numValuePower;
        // return result;


        //let result = new SMPNum2(1) * System.Math.Log(_main, baseNumber) + System.Math.Log(10, baseNumber) * b.Power;
        //return result;
    }


    IsDoubleInifinity() {
        if (this.isZero) {
            return false;
        }
        let result = Math.pow(10, this._power) * this._main;
        if (!isFinite(result)) {
            return true;
        }

        return false;
    }

    ToDoubleIfPossible() {
        if (this.isZero) {
            return 0;
        }

        let result = Math.pow(10, this._power) * this._main;
        //double res = _main * System.Math.Pow(10, Power);
        if (!isFinite(result)) {
            throw "Cannot convert to double it's become INFINITY1";
        }
        return result;
    }

    //operator >
    static greaterThan(leftSide, rightSide) {
        // Debug.Log("Leftside power : " + leftSide.Power);
        // Debug.Log("RightSide power : " + rightSide.Power);

        if (!leftSide) {
            throw "leftSide is null ";
        }
        if (!rightSide) {
            throw "leftSide is null ";
        }


        if (leftSide.isZero) {
            return false;
        }

        if (rightSide.isZero && !leftSide.isZero) {
            return true;
        }

        if (rightSide.isZero && leftSide.isZero) {
            return false;
        }

        if (leftSide._power > rightSide._power) {
            return true;
        }


        return false;
    }


    //operator MINUS
    static minus(leftSide, rightSide) {

        if (SMPNum2.IsNull(leftSide)) {
            throw "mult leftSide exception";
        }

        if (SMPNum2.IsNull(rightSide)) {
            throw "mult rightSide exception";
        }


        if (leftSide.isZero) return rightSide;
        if (rightSide.isZero) return leftSide;

        if (rightSide._power > leftSide._power) {
            throw "LeftSide need to be bigger!";
            //return new SMPNum2(0);

        } else if (rightSide._power === leftSide._power) {
            return new SMPNum2(0);
        }

        if (!leftSide.IsDoubleInifinity() && !rightSide.IsDoubleInifinity()) {
            let left = leftSide.ToDoubleIfPossible();
            //left = System.Math.Floor(left);
            let right = rightSide.ToDoubleIfPossible();
            //right = System.Math.Floor(right);
            let result = left - right;
            return new SMPNum2(result);
        }


        let middlePower = (leftSide._power >= rightSide._power) ? leftSide._power : rightSide._power;
        let leftSidePower1 = leftSide._power - middlePower;
        let rightSidePower1 = rightSide._power - middlePower;
        let temp1 = Math.pow(10, leftSidePower1) - Math.pow(10, rightSidePower1);
        if (!isFinite(temp1)) {
            throw "Calculation! error justafter the temp1";
        }
        let newLeftSide = new SMPNum2();
        //newLeftSide._power = middlePower;
        newLeftSide.setPower(middlePower);

        let newRightSide = new SMPNum2(temp1);

        //newLeftSide = newLeftSide * newRightSide;
        newLeftSide = SMPNum2.multSmpNum(newLeftSide,newRightSide);

        return newLeftSide;
    }


    //operator PLUS
    static plus(leftSide, rightSide) {
        if (!leftSide) {
            throw "plus leftSide";
        }
        if (!rightSide) {
            throw "plus rightSide";
        }

        if (leftSide.isZero) return rightSide;
        if (rightSide.isZero) return leftSide;

        let middlePower = (leftSide._power >= rightSide._power) ? leftSide._power : rightSide._power;
        let leftSidePower1 = leftSide._power - middlePower;
        let rightSidePower1 = rightSide._power - middlePower;

// Debug.Log("middlePower : " + middlePower);
// Debug.Log("leftSidePower1 : " + leftSidePower1);
// Debug.Log("rightSidePower1 : " + rightSidePower1);

        let temp1 = Math.pow(10, leftSidePower1) + Math.pow(10, rightSidePower1);
        if (!isFinite(temp1)) {

            //////console.log ('did call SMPNum plus from left ' + leftSidePower1 +  'right ' + rightSidePower1);
            throw " Overflow Calculation! in SUM";
        }
//Debug.Log("Temp : " + temp1);
        let newLeftSide = new SMPNum2();
        newLeftSide.setPower(middlePower);

        let newRightSide = new SMPNum2(temp1);

        newLeftSide = SMPNum2.multSmpNum(newLeftSide, newRightSide);

        return newLeftSide;
    }


//operator division
    static divSmpNum(leftSide, rightSide) {

        if (!leftSide) {
            throw "leftSide null";
        }
        if (!rightSide) {
            throw "rightSide null";
        }

        if (leftSide.isZero) return leftSide;
        if (rightSide.isZero || rightSide === 0) {
            //console.log ('EXCEPTION DIV ');
            //console.log(rightSide.ToReadableAlphabetV2());
            //console.log(leftSide.ToReadableAlphabetV2());
            throw "Cannot devide by zero!";

        }

        let result = new SMPNum2();
        let newMain = SMPNum2.main() / SMPNum2.main();
        let newPower = Math.log10(newMain / SMPNum2.main());
        result.setPower(leftSide._power - rightSide._power + newPower);

        return result;
    }


//usable for game
    GetDisplayString() {
        let def = 0;
        if (this.isZero) {
            return def.toString();
        }
        let res = SMPNum2.main() * Math.pow(10, this._power);
        return SMPNum2.main() + "*" + "10^" + this._power + " == " + res.toString();
    }


    ToReadableV2() {

        if (this.isZero) {
            return "0";
        }

        let valueInDouble = this._main * Math.pow(10, this._power);
        if (isFinite && valueInDouble < 1000) {
            let floatFloorValue = Math.floor(valueInDouble);
            let doubleHowMany = valueInDouble - floatFloorValue;
            if (doubleHowMany >= 0.01) {
                //return valueInDouble.toString();//"0.00"
                return valueInDouble.toFixed(2);
            }
            //else if (howMany >= 0.001 )
            //{
            //	return valueInDouble.ToString("0.000");
            //}
            else {
                //return valueInDouble.toString();//"0.00"
                return valueInDouble.toFixed(2);
            }
        } else {
            // return 'to code';
        }


        if (this.pSupportLetterFormat(valueInDouble.toFixed(1))) {
            //return valueInDouble.ToString("0.00");
            let money = SMPNum2.GetReadableAsLetterFormat(valueInDouble.toFixed(2));
            return money;
        } else {

            //let  power = this._power;
            let final;
            //System.Math.Pow(10,Power) * _main;

            let myMain = this._main;
            let myPower = this._power;
            //Debug.Log(" myPower  :" + myPower.ToString());
            let floorPower = Math.floor(myPower);
            let powerRemaining = myPower - floorPower;
            myMain = myMain * Math.pow(10, powerRemaining);

            if (!(myMain < 10)) {
                myMain = myMain / 10; //because 2 = 0.2 * 10
                floorPower = floorPower + 1; //because 2 = 0.2 * 10, we divide main by 10 and increase power by 1
            }
            final = myMain.toFixed(2) + "e" + floorPower;
            return final;
        }
    }

    ToDoubleValue () {
        if (this.isZero) {
            return 0
        }
        return  this._main * Math.pow(10, this._power);
    }

    ToIntValue () {
        if (this.isZero) {
            return 0
        }else {
            return  Math.floor(this._main * Math.pow(10, this._power));
        }
    }


    static ToReadableTimeValue(seconds) {
            let suffix = 'seconds';
            let value = seconds;
            if (SMPNum2.greaterThan(seconds,new SMPNum2(60))) {
                value = SMPNum2.divSmpNum(seconds, new SMPNum2(60));
                suffix = 'minutes';

                if (SMPNum2.greaterThan(value, new SMPNum2(60))) {
                    value = SMPNum2.divSmpNum(value, new SMPNum2(60));
                    suffix = 'hours';

                    if (SMPNum2.greaterThan(value, new SMPNum2(24))) {
                        value = SMPNum2.divSmpNum(value, new SMPNum2(24));
                        suffix = 'days';

                        if (SMPNum2.greaterThan(value, new SMPNum2(7))) {
                            value = SMPNum2.divSmpNum(value, new SMPNum2(7));
                            suffix = 'week(s)';

                            if (SMPNum2.greaterThan(value, new SMPNum2(4))) {
                                value = SMPNum2.divSmpNum(value, new SMPNum2(4));
                                suffix = 'month(s)';
                                if (SMPNum2.greaterThan(value, new SMPNum2(12))) {
                                    value = SMPNum2.divSmpNum(value, new SMPNum2(12));
                                    suffix = 'year(s)';
                                    if (SMPNum2.greaterThan(value, new SMPNum2(100))) {
                                        value = SMPNum2.divSmpNum(value, new SMPNum2(100));
                                        suffix = 'century(s)';

                                        if (SMPNum2.greaterThan(value, new SMPNum2(10))) {
                                            value = SMPNum2.divSmpNum(value, new SMPNum2(10));
                                            suffix = 'millenaire(s)';
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            return value.ToReadableAlphabetV2() + ' ' + suffix;
    }


    ToReadableAlphabetV2(format) {

        // if(!format){
        //     return this.ToDoubleValue();
        // }

        if (this.isZero) {
            return "0";
        }

        let valueInDouble = this._main * Math.pow(10, this._power);
        if (isFinite(valueInDouble) && valueInDouble < 1000) {
            if (format) {
                //supposed to be :
                //- "0" => display 0
                // - "0.0" => display 0.0
                // - "0.00" => display 0.01
                //return valueInDouble.ToString(format);
                return valueInDouble.toString()
            }

            let floorValue = Math.floor(valueInDouble);
            //let floorValue = Math.ceil(valueInDouble);
            let howMany = valueInDouble - floorValue;
            if (howMany >= 0.01) {
                //return valueInDouble.ToString("0.00");
                try {
                    //let result = valueInDouble.toFixed(2);
                    //////console.log(result);
                }catch(e) {
                    //////console.log(e.stack);
                }

                return valueInDouble.toFixed(2);
            }
            //else if (howMany >= 0.001)
            //{
            //	return valueInDouble.ToString("0.000");
            //}
            else {
                return valueInDouble.toFixed(0);
            }
        }

        let power = this._power;
        let final;
        //System.Math.Pow(10,Power) * _main;

        let myMain = 1; //because we will convert 2 to power of 10 => log10(2)
        let myPower = power + Math.log10(this._main);
        let result = parseInt(Math.floor(myPower / 3));
        let remaining = myPower % 3;
        let remainingInRealValue = Math.pow(10, remaining);
        if (remainingInRealValue > 0) {
            myMain = myMain * remainingInRealValue;
        }

        if (result < 5) {
            let indexOfAlphabet = result;
            let alphabet = "";
            //Debug.Log("indexOfAlphabet : " + indexOfAlphabet);
            alphabet = SMPNum2.DefaultTypeOfMoney()[indexOfAlphabet];
            final = myMain.toFixed(2) + alphabet;
        } else {
            let indexOfAlphabet = result - 5;

            let alphabet = "";
            if (indexOfAlphabet < SMPNum2.AlphabetTypeOfMoney().length) {
                alphabet = SMPNum2.AlphabetTypeOfMoney()[indexOfAlphabet];
                final = myMain.toFixed(2) + alphabet + alphabet; // 2 of A => AA
            } else {
                let howManyTimeBigger = parseInt(indexOfAlphabet / SMPNum2.AlphabetTypeOfMoney().length);
                indexOfAlphabet = indexOfAlphabet % SMPNum2.AlphabetTypeOfMoney().length;

                alphabet = SMPNum2.AlphabetTypeOfMoney()[indexOfAlphabet];
                final = myMain.toFixed(2) + alphabet + (howManyTimeBigger + 2); //because default is 2 => AA
            }
        }


        return final;


    }


    StringForSave() {
        if (this.isZero) {
            return this.DEFINE_ZERO;
        }
        return this._power.toString();
    }

    static GetRealValue(s_CurrentMoney) {
        if (s_CurrentMoney == null) {
            //////console.log("_CurrentMoney is null");
            return null;
        }

        //LA Y A UNE GROSSE MERDE

        //Check if the string contain a money
        for (let i = 1; i <= SMPNum2.DefaultTypeOfMoney().length - 1; i++) {
            let s_CurrentMoneyUpper = s_CurrentMoney;//.ToUpper ();
            if (s_CurrentMoney.includes(SMPNum2.DefaultTypeOfMoney()[i]) || s_CurrentMoneyUpper.includes(SMPNum2.DefaultTypeOfMoney()[i])) {
                //Check the right letter and remove it
                //_CurrentMoney = _CurrentMoney.Replace(TypeOfMoney[i], "\0");
                //s_CurrentMoney = s_CurrentMoney.splice(s_CurrentMoney.length - SMPNum2.DefaultTypeOfMoney()[i].length);
                //s_CurrentMoney.substring( s_CurrentMoney.length - SMPNum2.DefaultTypeOfMoney()[i].length,s_CurrentMoney.length  );
                s_CurrentMoney = s_CurrentMoney.substring(0, s_CurrentMoney.length - 1);

                //Split string if 2 part number
                let _TempIndexSecond = 0;
                if (s_CurrentMoney.includes(".")) {
                    let _CurrentMoneySplited = s_CurrentMoney.split('.');
                    _TempIndexSecond = _CurrentMoneySplited [1].length;
                    s_CurrentMoney = _CurrentMoneySplited [0] + _CurrentMoneySplited [1];
                }
                //add number of 0 depend of letter
                if ((i * 3) - _TempIndexSecond > 0) {
                    //let  _TempStringForInsert = new string ('0', (i * 3) - _TempIndexSecond);

                    let _TempStringForInsert = '0';
                    for (let temp = 1; temp < (i * 3) - _TempIndexSecond; temp++) {
                        _TempStringForInsert = _TempStringForInsert + '0';
                    }

                    s_CurrentMoney = s_CurrentMoney + _TempStringForInsert;
                }
                return s_CurrentMoney;
            }
        }
        let result = parseFloat(s_CurrentMoney);
        if (result) {
            let floorValue = Math.floor(result);
            if (result - floorValue >= 0.01) {
                return result.toFixed(2);//"0.00"
            } else if (result - floorValue >= 0.001) {
                //return result.ToString("0.000");
                return result.toFixed(3)
            } else {
                //return result.oString ("0");
                return result.toFixed(0)
            }

        } else {
            //return double.PositiveInfinity.ToString ();
            return Number.POSITIVE_INFINITY;
        }
    }


}

module.exports = SMPNum2;
//export default SMPNum2
