let MultipleSessionDataStore = require("../../kpi-editor/Utils/MultipleSessionDataStore");
let MultipleSessionKpiUtils = require("../../kpi-editor/Utils/MultipleSessionKpiUtils");

let SMPMathGamePlay = require("../classes/SMPMathGamePlay");
let SMPHeroGamePlay = require("../classes/SMPHeroGamePlay");
let SMPHeroSkillGamePlay = require("../classes/SMPHeroSkillGamePlay");
let SMPPetGamePlay = require("../classes/SMPPetGamePlay");
let SMPSupportGamePlay = require("../classes/SMPSupportGamePlay");
let SMPEnemyGamePlay = require("../classes/SMPEnemyGamePlay");
let SiblingGamePlayConstant = require("../constants/SiblingGamePlayConstant");
let SMPNum = require("../../SMPNum");

class GamePlayDataServices {

    constructor(ratios, supportData, unlockSupportSetting, multipleSessionConfig){
        this.ratiosConfig = ratios;
        this.mathGamePlay = new SMPMathGamePlay(this.ratiosConfig);
        this.heroGamePlay = new SMPHeroGamePlay(this.mathGamePlay);
        this.heroSkillGamePlay = new SMPHeroSkillGamePlay(this.mathGamePlay);
        this.supportGamePlay = new SMPSupportGamePlay(supportData, unlockSupportSetting, this.mathGamePlay);
        this.petGamePlay = new SMPPetGamePlay();
        this.enemyGamePlay = new SMPEnemyGamePlay(this.mathGamePlay);

        this.multipleSessionDataStore = new MultipleSessionDataStore();
        this.multipleSessionKpiUtils = new MultipleSessionKpiUtils(multipleSessionConfig, this);
    }

    updateRatio(ratios){
        this.ratiosConfig = ratios;
        this.mathGamePlay.updateRatio(ratios);
    }

    //======== coin ==========
    getCoinDrop(monsterLevel){
        return this.mathGamePlay.GetUnBaseOnLevel(monsterLevel, "DropCoins");
    }
    //=========================

    //======== diamond ==========
    getDiamondStartUp(){
        let diamondStart = this.ratiosConfig.diamondGameStartup;
        if(!diamondStart || diamondStart === ""){
            diamondStart = 0;
        } else {
            diamondStart = parseInt(diamondStart);
        }
        return diamondStart;
    }
    getDiamondDrop(monsterLevel){
        let stagePerDiamond = this.ratiosConfig.stageCounterToDropDiamond;
        return Math.floor(monsterLevel/stagePerDiamond);
    }
    //=========================

    //======== hero ==========
    getHeroCost(heroLevel){
        return this.heroGamePlay.GetHeroCost(heroLevel);
    }
    getHeroDMG(heroLevel) {
        return this.heroGamePlay.GetHeroDMG(heroLevel);
    }
    getHeroCountUnlockOnLevel(monsterLevel){
        return this.heroGamePlay.GetHeroCountUnlockOnLevel(monsterLevel);
    }
    getCostUpdateHero(fromLevel, toLevel){
        return this.heroGamePlay.GetCostUpdateHero(fromLevel, toLevel);
    }
    getHeroHp(heroId, heroLevel){
        let multiplier = 3;
        let gameLevel = this.multipleSessionDataStore.bossStore.gameLevel;
        if(gameLevel < 10) multiplier = 10;
        let hpMatching = this.getBossDMGByType(heroLevel, 'ZoneBoss');
        hpMatching = SMPNum.multSmpNum(hpMatching, new SMPNum(multiplier));
        return hpMatching;
        /*old
        //this rule apply from Unity project (SMPPlayerLevelConfiguration)
        //one level boss possible attack 10 times
        let bossDmg = this.getBossDMGByType(heroLevel, 'ZoneBoss');
        return SMPNum.multSmpNum(bossDmg, new SMPNum(SiblingGamePlayConstant.bossAttackPerLevelCount));*/
    }
    getMaxLevelOnBaseCost(currentLevel, gold){
        return this.heroGamePlay.GetMaxLevelOnBaseCost(currentLevel, gold);
    }
    //=========================

    //====== hero skill =======
    getHeroSkillUnlockCost(skillId){
        return this.heroSkillGamePlay.GetNextCost(skillId, 0, 1);
    }
    getHeroSkillUpdateCost(skillId, currentLevel, lvAdd){
        return this.heroSkillGamePlay.GetNextCost(skillId, currentLevel, lvAdd);
    }
    //=========================

    //======== support ========
    updateSupportUnlockSetting(unlockSupportSetting){
        this.supportGamePlay.updateSupportUnlockSetting(unlockSupportSetting);
    }

    updateSupportSkillSetting(skillList, SupportSkillsConstant){
        return this.supportGamePlay.updateSupportSkillSetting(skillList, SupportSkillsConstant);
    }
    getSupportCost(supportLevel){
        return this.supportGamePlay.GetSupportCost(supportLevel);
    }
    getCostUnlockSupport(supportId){
        return this.supportGamePlay.GetCostUnlockSupport(supportId);
    }
    getCostUpdateSupport(fromLevel, toLevel){
        return this.supportGamePlay.GetCostUpdateSupport(fromLevel, toLevel);
    }
    getSupportDPS(supportLevel){
        return this.supportGamePlay.GetSupportDPS(supportLevel);
    }
    getSupportHp(supportId, supportLevel){
        return this.supportGamePlay.GetSupportHp(supportId, supportLevel);
    }
    getCostUnlockSkillSupport(supportId, skillId, evolveCounter){
        return this.supportGamePlay.GetCostUnlockSkill(supportId, skillId, evolveCounter);
    }
    getMaxLevelSupportOnBaseCost(currentLevel, gold){
        return this.supportGamePlay.GetMaxLevelSupportOnBaseCost(currentLevel, gold);
    }
    //=========================


    //======== enemy ==========
    isHpBossUseRatio(){
        return this.ratiosConfig.selectedHpBossOption === "Ratio";
    }
    getBossHP(monsterLevel) {
        if(this.isHpBossUseRatio()){
            return this.enemyGamePlay.getBossHP(monsterLevel);
        } else {
            let ghostHp = this.getGhostHP(monsterLevel);
            let normalBossHPMultiplier = this.ratiosConfig.normalBossHPMultiplier;
            return SMPNum.multSmpNum(ghostHp, SMPNum.fromNum(normalBossHPMultiplier));
        }
    }
    getZoneBossHP(monsterLevel) {
        if(this.isHpBossUseRatio()){
            return this.enemyGamePlay.getZoneBossHP(monsterLevel);
        } else {
            let ghostHp = this.getGhostHP(monsterLevel);
            let zoneBossHpRatio = this.ratiosConfig.zoneBossHPMultiplier;
            return SMPNum.multSmpNum(ghostHp, SMPNum.fromNum(zoneBossHpRatio));
        }
    }
    getGhostHP(monsterLevel){
        return this.enemyGamePlay.getGhostHP(monsterLevel);
    }
    getBossDMG(monsterLevel){
        return this.enemyGamePlay.getBossDMG(monsterLevel);
    }

    getDmgBaseOnElement(stage, bossType, finalHeroHp){
        //Hero
        let heroStore = this.multipleSessionDataStore.heroStore;
        let heroElement = heroStore.heroData.elementType;
        let bossElement = this.multipleSessionDataStore.bossStore.bossData.elementType;
        let gainNumber = this.multipleSessionKpiUtils.getForwardGainNumber(bossElement, heroElement);
        let percent = this.multipleSessionKpiUtils.getEnemyPercentByGainNumber(gainNumber);

        let originalDmg = this.getBossDMGByType(stage, bossType);
        let totalDamage = originalDmg;
        if (percent > 0 && stage > 10) {
            totalDamage = SMPNum.plus(originalDmg, SMPNum.multSmpNum(finalHeroHp, new SMPNum(percent)));
        }

        return totalDamage;
    }

    getBossDps(monsterLevel, finalHeroHp){
        //boss attack once every 2 seconds
        let bossDmg = this.getDmgBaseOnElement(monsterLevel, 'BigBoss', finalHeroHp);//this.getBossDMGByType(monsterLevel, 'BigBoss');
        let bossDps = SMPNum.multSmpNum(bossDmg, new SMPNum(0.5));
        return bossDps;
    }
    getZoneBossDps(monsterLevel, finalHeroHp){
        //boss attack once every 2 seconds
        let bossDmg = this.getDmgBaseOnElement(monsterLevel, 'ZoneBoss', finalHeroHp);//this.getBossDMGByType(monsterLevel, 'ZoneBoss');
        let bossDps = SMPNum.multSmpNum(bossDmg, new SMPNum(0.5));
        return bossDps;
    }

    getBossDmgBalance(monsterLevel){
        let bossDmg = this.getBossDMGByType(monsterLevel, 'ZoneBoss');
        let gotHitCount = new SMPNum(10);//one level boss possible attack 10 times

        return SMPNum.multSmpNum(bossDmg, gotHitCount);
    }
    getDmgBalanceToKillEnemy(monsterLevel){
        let bossHp = this.getBossHP(monsterLevel);
        let killInSecond = SiblingGamePlayConstant.kill_enemy_in_second;
        return SMPNum.divSmpNum(bossHp, new SMPNum(killInSecond));
    }
    getDurationBalanceToKillEnemy(){
        let killInSecond = SiblingGamePlayConstant.kill_enemy_in_second;
        return killInSecond;
    }
    getBossDMGByType(monsterLevel, bossType) {
        let dmg = this.getBossDMG(monsterLevel);
        let coefficient = 0;
        if(bossType === 'ZoneBoss'){
            coefficient = 1;
        } else if(bossType === 'BigBoss') {
            coefficient = 0.75;
        } else{
            coefficient = 0.25;
        }
        return SMPNum.multSmpNum(dmg, new SMPNum(coefficient));
    }
    //=========================


    //======== pet ============
    getPetCountUnlockOnLevel(monsterLevel){
        return this.petGamePlay.GetPetCountUnlockOnLevel(monsterLevel);
    }
    // GetDiamondCostForLevelUpPet(totalPet,totalLevel) {
    //     return this.petGamePlay.GetDiamondCostForLevelUpPet(totalPet, totalLevel);
    // }
    getDiamondCostUpdatePetLevel(level) {
        return this.petGamePlay.getDiamondCostUpdatePetLevel(level);
    }
    getPetDMG(heroTapDMG, petLevel, petData){
        return this.petGamePlay.getPetDMG(heroTapDMG, petLevel, petData);
    }
    getPetPassiveDMG(heroTapDMG, petLevel, petData){
        return this.petGamePlay.getPetPassiveDMG(heroTapDMG, petLevel, petData);
    }
    //=========================


    //========== merge with old SMPGamePlay

    GetFirstTermValueBySeriesName (seriesName) {
        return new SMPNum(this.mathGamePlay.GetSeriesFirstTermByLevel(1, seriesName));
    }

    DMGStandardSupport(supportLevel) {
        return this.getSupportDPS(supportLevel);
    }

    //========== Series name use as function ==================
    CostHero(heroLevel) {
        return this.getHeroCost(heroLevel);
        /*old
        let value = this.mathGamePlay.GetSeriesFirstTermByLevel(heroLevel, 'CostHero');
        let firstTermOfSequence = new SMPNum(value);
        let termMatchingLevel = this.mathGamePlay.GetTermValueForSeries(heroLevel, 'CostHero');
        let result = SMPNum.multSmpNum(termMatchingLevel, firstTermOfSequence);
        return result;*/
    }

    DropCoins(monsterLevel) {
        return this.getCoinDrop(monsterLevel);
        /*old
        //let value = 1;
        let value = this.mathGamePlay.GetSeriesFirstTermByLevel(monsterLevel, 'DropCoins');
        let firstTermOfSequence = new SMPNum(value);
        let termValue = this.mathGamePlay.GetTermValueForSeries(monsterLevel, 'DropCoins');
        return SMPNum.multSmpNum(firstTermOfSequence, termValue);
        */
    }

    CostSupport(targetSupportLevel) {
        return this.getSupportCost(targetSupportLevel);
        /*old
        let firstTermOfSequence = new SMPNum(1);
        let termMatchingLevel = this.mathGamePlay.GetTermValueForSeries(targetSupportLevel, 'CostSupport');
        let cost = SMPNum.multSmpNum(termMatchingLevel, firstTermOfSequence);

        return cost;*/
    }

    HPBoss(bossLevel) {
        return this.getBossHP(bossLevel);
        /*old
        let value = this.seriesFirstTermGhost;
        let firstTermOfSequence = new SMPNum(value);
        let termMatchingLevel = this.GetTermValueForSeries(bossLevel, 'HPBoss');
        let result = SMPNum.multSmpNum(termMatchingLevel, firstTermOfSequence);
        result = SMPNum.multSmpNum(new SMPNum(200),result);
        return result;*/
    }

    HPGhost(monsterLevel) {
        return this.getGhostHP(monsterLevel);
        /*old
        let value = this.seriesFirstTermGhost;
        let firstTermOfSequence = new SMPNum(value);
        let termMatchingLevel = this.GetTermValueForSeries(monsterLevel, 'HPGhost');
        let result = SMPNum.multSmpNum(termMatchingLevel, firstTermOfSequence);
        return result;*/
    }

    DMGHero(heroLevel) {
        return this.getHeroDMG(heroLevel);
        /*old
        let value = 1;
        let firstTermOfSequence = new SMPNum(value);
        let termMatchingLevel = this.GetTermValueForSeries(heroLevel, 'DMGHero');
        let result = SMPNum.multSmpNum(termMatchingLevel, firstTermOfSequence);
        return result;*/
    }
    //=========================================================
    //==============GAME PLAY KPI =============================
    //=========================================================
    kpiGameplayPlayerTapDamage(params) {
        let heroLevel = params?.heroLevel ?? 1;
        let tapDamagePercent = params?.tapDamagePercent ?? 0;
        let allDmgPercent = params?.allDmgPercent ?? 0;
        let petTapDmgPercent = params?.petTapDmgPercent ?? 0;
        let petAllDmgPercent = params?.petAllDmgPercent ?? 0;
        let dmgBonusFromSupportDps = params?.dmgBonusFromSupportDps ?? 0;
        let heroDmgSessionGainBoss = params.heroDmgSessionGainBoss;
        let heroDmgSessionFromPet = params.heroDmgSessionFromPet;
        let heroSkillDmgPercent = params.heroSkillDmgPercent;

        let baseDmg = this.getHeroDMG(heroLevel);
        let dmgMTS = this.getHeroDMGOnMultipleSession(baseDmg, heroDmgSessionGainBoss, heroDmgSessionFromPet);

        let totalPercent = (Number(tapDamagePercent) + Number(allDmgPercent) + Number(petTapDmgPercent) + Number(petAllDmgPercent))/100;

        //in game play, bonus not divide with 100 also
        totalPercent += heroSkillDmgPercent;

        let bonusFromOtherSkill = SMPNum.multSmpNum(dmgMTS, new SMPNum(totalPercent));
        let finalResult = SMPNum.plus(dmgMTS, bonusFromOtherSkill);
        finalResult = SMPNum.plus(finalResult, dmgBonusFromSupportDps);

        return finalResult;
    }

    getHeroDMGOnMultipleSession(baseDmg, heroDmgSessionGainBoss, heroDmgSessionFromPet){
        //Apply multiple session from pet elements
        if(heroDmgSessionFromPet){
            baseDmg = SMPNum.plus(baseDmg, heroDmgSessionFromPet.bonusDmg);
        }

        //Apply Multiple Session on battle boss
        if(heroDmgSessionGainBoss){
            if(heroDmgSessionGainBoss.isGain){
                baseDmg = SMPNum.plus(baseDmg, heroDmgSessionGainBoss.bonusDmg);
            } else if(SMPNum.greaterThan(baseDmg, heroDmgSessionGainBoss.bonusDmg)){
                baseDmg = SMPNum.minus(baseDmg, heroDmgSessionGainBoss.bonusDmg);
            }
        }
        return baseDmg;
    }


    validateDropCoinRatioBalance(){
        let startLv = 25;
        let stepLv = 25;
        let endLv = 10000;
        let msg = "";
        let lastdropCoin = new SMPNum(0);
        for(let lv=startLv; lv<=endLv; lv+=stepLv)
        {
            msg += `\n${lv}`;

            let coinFirst = this.mathGamePlay.mathCore.GetSeriesFirstTermByLevel(lv, 'DropCoins');
            msg += `\t${coinFirst.ToReadableAlphabetV2()}`;

            // var coinRatio = SMPMathCore.GetSeriesCommonRatioByLevel(lv, SequenceName.DropCoins);
            // msg += $"\t{coinRatio}";

            let dropCoin = this.mathGamePlay.GetUnBaseOnLevel(lv, 'DropCoins');
            if(dropCoin < lastdropCoin)
            {
                msg += `\t${dropCoin.ToReadableAlphabetV2()}[x]`;
            }
            else
            {
                msg += `\t${dropCoin.ToReadableAlphabetV2()}`;
            }
            lastdropCoin = dropCoin;

            let heroCost = this.mathGamePlay.GetUnBaseOnLevel(lv, 'CostHero');
            msg += `\t${heroCost.ToReadableAlphabetV2()}`;

            let ghostHp = this.mathGamePlay.GetUnBaseOnLevel(lv, 'HPGhost');
            msg += `\t${ghostHp.ToReadableAlphabetV2()}`;
        }
        // msg = "Level\tFirstTerm\tRatio\tDropCoin\tHeroCost\tDiffHero&Coin" + msg;
        msg = `Level\tFirstTerm\tDropCoin\tHeroCost\tCoin/Hero\tGhostHp` + msg;
        console.log(msg);
    }

    getMaxWaveCount(gameLevel, skillReduce){
        // +2 round every 450 levels
        let waveRound = Math.floor(gameLevel / 450) * 2;
        let MaxWaveCount = 10 + waveRound - skillReduce;
        if (MaxWaveCount < 6)
        {
            MaxWaveCount = 6;
        }

        return MaxWaveCount - 1;
    }
}

module.exports = GamePlayDataServices;