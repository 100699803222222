let SMPNum = require("@/SMPNum");

class Chain4 {
     constructor(service, successor) {
          this.service = service;
          this.successor = successor;
     }
     handleRequest(payload){
          //get only required param
          const {levelSimulateData, currentHeroLevel, cumulatedGoldWon, ratioAllocatedToHeroSkill, ratioAllocatedToSupport, heroList, petList, isUseHeroSkillDmg, isUsePetAllianceSkill, teamBattleInfo} = payload;

          //define gold available for hero
          const ratioAllocatedToHeroes = 100 - ratioAllocatedToSupport;
          const goldAvailableForHeroes = this.service.defineGoldAvailableForHeroes(cumulatedGoldWon, ratioAllocatedToHeroes);
          const goldAvailableForHeroSkill = this.service.defineGoldAvailableForHeroSkill(goldAvailableForHeroes, ratioAllocatedToHeroSkill);
          const goldAvailableForHeroLevels = SMPNum.minus(goldAvailableForHeroes, goldAvailableForHeroSkill);

          //define hero spent
          // const cumulatedGoldHeroLevelSpent = this.service.computeTotalHeroSpent(heroList);
          const {heroLevelAfterFarm, heroSpent} = this.service.getHeroLevelFromFarm(currentHeroLevel, goldAvailableForHeroLevels);

          let heroSelectID = teamBattleInfo.heroData.m_iID;
          const heroSkillsUnlockList = this.service.getHeroSkillProgress(heroList, heroSelectID, levelSimulateData);
          //const { totalGoldHeroSkillHaveSpent, heroSkillsUnlockList} = this.service.defineLevelForHeroSkillPossibleReach(heroList, heroSelectID, gameLevel, goldAvailableForHeroSkill);
          const heroActiveSkillList = this.service.getHeroActiveSkillList(heroSkillsUnlockList);
          const heroBossSkillList = this.service.getHeroBossSkillList(heroSkillsUnlockList);
          const heroPassiveSkillList = this.service.getHeroPassiveSkillList(heroSkillsUnlockList);

          // const cumulatedGoldHeroSpent = SMPNum.plus(cumulatedGoldHeroLevelSpent, totalGoldHeroSkillHaveSpent);

          //define remaining from hero spent
          const goldRemainFromHeroSpent = this.service.computeGoldRemainFromHeroSpent(goldAvailableForHeroes, heroSpent);

          //define pet join top3, base on hero skill
          this.service.definePetJoinTop3(petList, isUseHeroSkillDmg, isUsePetAllianceSkill, heroBossSkillList);

          //prepare new payload
          let newPayload = {
               goldAvailableForHeroes,
               // cumulatedGoldHeroSpent,
               goldRemainFromHeroSpent,
               // goldAvailableForHeroSkill,
               // totalGoldHeroSkillHaveSpent,
               heroSkillsUnlockList,
               heroActiveSkillList,
               heroBossSkillList,
               heroPassiveSkillList,
               heroLevelAfterFarm
          }

          //merge old and new to return payload
          let returnPayload = Object.assign({}, payload, newPayload)

          //forward to another successor or return
          if (this.successor) {
               return this.successor.handleRequest(returnPayload);
          } else {
               return returnPayload;
          }
     }
}

export default Chain4;