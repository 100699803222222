let SMPNum = require("../../SMPNum");

class SMPSupportGamePlay {
    constructor(supportData, unlockSupportSetting, mathGamePlay) {
        this.supportData = supportData;
        this.mathGamePlay = mathGamePlay;

        this.updateSupportUnlockSetting(unlockSupportSetting);

        /*test unlock cost
        console.log("CostSkillUnlock 1: "+this.GetCostUnlockSkill(1, 1,0).ToReadableAlphabetV2());
        console.log("CostSkillUnlock evolve: "+this.GetCostUnlockSkill(1, 8,0).ToReadableAlphabetV2());
        console.log("CostSkillUnlockAfterEvolve 1: "+this.GetCostUnlockSkill(1, 1,1).ToReadableAlphabetV2());
        console.log("CostSkillUnlockAfterEvolve evolve: "+this.GetCostUnlockSkill(1, 8,1).ToReadableAlphabetV2());
         */
    }

    updateSupportUnlockSetting(unlockSupportSetting){
        this.unlockSupportSetting = unlockSupportSetting;
        this.bindingSupportDataAndUnlockSetting();
    }

    updateSupportSkillSetting(skillList, SupportSkillsConstant){
        let isResetList = false;
        if(skillList === null){
            skillList = [];
            isResetList = true;
        }
        if(skillList.length > 0){
            if(skillList[0].support_id === undefined) {
                isResetList = true;
            }
            if(skillList[0].supportData === undefined){
                isResetList = true;
            }
        }

        let skillListView = [];
        if(isResetList){
            let skIndex = 0;
            this.supportData.forEach(s => {
                s.m_SupportsAbilityList.forEach(skill =>{
                    skillListView.push({
                        support_id: s.m_iID,
                        skill_id: skill.m_iID,
                        skillNameEn: skill.m_sName,
                        skillType: SupportSkillsConstant.bySkillTypeId(skill.m_skillType),
                        m_skillPercent: skill.m_skillPercent,
                        m_iCurrentLevel: skill.m_iCurrentLevel,
                        m_iIconId: skill.m_iIconId,
                        supportData:{
                            m_sName: s.m_sName,
                            m_fAttackSpeed: s.m_fAttackSpeed,
                            color: this.getSupportColor(s.m_iFruitType),
                        },
                        index: skIndex,
                    });
                    skIndex++;
                });

            });
        } else {
            skillList.forEach(skill =>{
                skillListView.push(skill);

                let support = this.supportData.find(s => s.m_iID === skill.support_id);
                let skillSupport = support.m_SupportsAbilityList.find(sk => sk.m_iID === skill.skill_id);
                skillSupport.m_skillPercent = skill.m_skillPercent;
                skillSupport.m_iCurrentLevel = skill.m_iCurrentLevel;
            });
        }
        return skillListView;
    }

    getSupportColor(m_iFruitType){
        if(m_iFruitType === 4){
            return "orange";
        } else if(m_iFruitType === 0){
            return "red";
        } else if(m_iFruitType === 5){
            return "purple";
        } else if(m_iFruitType === 3){
            return "blue";
        } else {
            return "none";
        }
    }

    bindingSupportDataAndUnlockSetting(){
        this.unlockSupportSetting.forEach(uns =>{
            let support = this.supportData.find(s => s.m_iID === uns.m_iID);
            support.kpiGameLevelShouldUnlock = uns.kpiGameLevelShouldUnlock;
        });
    }

    getSupportData(supportId){
        let supportSelect = null;
        this.supportData.forEach(support => {
            if(support.m_iID === supportId){
                supportSelect = support;
                return;
            }
        });
        return supportSelect;
    }

    getPossibleSupportUnlocked(gameLevel, unlockFlyingSupportCounter) {
        let availableSupport = [];
        this.supportData.forEach(s => {
            if(s.m_SupportStandType === 2){
                if(unlockFlyingSupportCounter > 0){
                    unlockFlyingSupportCounter--;
                    availableSupport.push(s);
                }
            }
            else if(gameLevel >= s.kpiGameLevelShouldUnlock){
                availableSupport.push(s);
            }
        });

        return availableSupport;
    }

    /*disable feature saving coin for next unlock support
    getNextPossibleSupportUnlocked(gameLevel) {
        let availableSupport = [];
        this.supportData.forEach(s => {
            if(s.m_SupportStandType !== 2){
                if(gameLevel < s.kpiGameLevelShouldUnlock){
                    availableSupport.push(s);
                }
            }
        });

        if(availableSupport.length > 0){
            if(availableSupport.length > 1){
                availableSupport = availableSupport.sort((a, b) => a.kpiGameLevelShouldUnlock < b.kpiGameLevelShouldUnlock ? -1 : 1);
            }
            return availableSupport[0];
        }
        return null;
    }*/

    GetSupportCost(supportLevel){
        return this.mathGamePlay.GetUnBaseOnLevel(supportLevel, "CostSupport");

        /*for detail on mathGamePlay.GetUnBaseOnLevel(supportLevel, "CostSupport");
        let firstTermOfSequence = GetSeriesFirstTermByLevel(level, sequenceName);;
        let termMatchingLevel = this.GetTermValueForSeries(level, sequenceName);
        return SMPNum.multSmpNum(termMatchingLevel, firstTermOfSequence);
        */

        /*in old kpi dashboard SMPGameplay.CostSupport
        let firstTermOfSequence = new SMPNum(1);
        let termMatchingLevel = this.GetTermValueForSeries(targetSupportLevel, 'CostSupport');
        let cost = SMPNum.multSmpNum(termMatchingLevel, firstTermOfSequence);
        return cost;
         */
    }

    GetCostUnlockSupport(supportId) {
        let support = this.getSupportData(supportId);
        let kpiGameLevel = 0;
        if(support === null){
            kpiGameLevel = 2500;
        } else {
            kpiGameLevel = support.kpiGameLevelShouldUnlock;
        }
        let baseCost = this.mathGamePlay.GetUnBaseOnLevel(kpiGameLevel, "DropCoins");
        let costAtUnlockedLevel = this.mathGamePlay.SumBaseOnCurrentLvAndTargetLv(1, kpiGameLevel - 1, "CostSupport");
        return SMPNum.plus(baseCost, costAtUnlockedLevel).round();
    }

    GetCostUpdateSupport(fromLevel, toLevel){
        let cost = this.mathGamePlay.SumBaseOnCurrentLvAndTargetLv(fromLevel, toLevel, "CostSupport");
        //cost.round();
        return cost;
    }

    GetMaxLevelSupportOnBaseCost(currentlv, gold){
        let level = this.mathGamePlay.GetMaxLevelThatCanBuyWithExistingGold(gold, currentlv, "CostSupport");
        return level;
    }

    GetCostUnlockSkill(supportId, skillId, evolveCounter){
        //this formula don't have in old kpi
        //we take from game play formula
        //but on GetCostUnlockSupport have change from game play, because this one was take from old kpiZ
        let cost = new SMPNum(0);
        this.supportData.forEach(d => {
            if(d.m_iID === supportId){
                d.m_SupportsAbilityList.forEach(skill => {
                    if(skill.m_iID === skillId){
                        let costBase = this.GetCostUnlockSupport(supportId);
                        let lvOnEvolve = 1000 * evolveCounter;

                        //unlock evolve
                        if(skill.m_skillType === 10){

                            cost = SMPNum.multSmpNum(SMPNum.multSmpNum(
                                                                    costBase,
                                                                    SMPNum.pow(SMPNum.fromNum(1.075),lvOnEvolve + skill.m_iCurrentLevel - 1).round())
                                                                , SMPNum.fromNum(10.75));
                        } else {

                            //have evolve ready
                            if(evolveCounter > 0){
                                let costOnLevel = this.GetSupportCost(lvOnEvolve + skill.m_iCurrentLevel);
                                cost = SMPNum.multSmpNum(costOnLevel, new SMPNum(9+evolveCounter));
                            } else {
                                //not evolve yet
                                let costOnLevel = this.GetSupportCost(skill.m_iCurrentLevel);
                                cost = SMPNum.multSmpNum(costOnLevel, new SMPNum(5));
                            }
                        }
                        return cost;
                    }
                });
            }
        });
        if(cost.isZero){
            console.log("error on match support data id "+supportId+" for skill id "+skillId);
        }
        return cost;

        /* Formula in c#
        //evolve skill cost
        //cost = new SMPNum((support.m_sCostBase * Math.Round(Math.Pow(1.075, support.m_iCurrentLevel - 1))) * 10.75);

        //normal skill cost
        // costOnLevel = GetCostUnlockSkill(support.m_sCostBase, support.m_SupportsAbilityList[totalUnlockSkills].m_iCurrentLevel);
        // if (support.m_evolved)
        // {
        //     //cost after evolved
        //     levelconfig.cost = costOnLevel * (9+support.m_evoledCounter);//start in 10
        // }
        // else
        // {
        //     levelconfig.cost = costOnLevel * 5;
        // }*/
    }

    GetSupportDPS(supportLevel){
        return this.mathGamePlay.GetUnBaseOnLevel(supportLevel, "DMGSupport");
    }

    GetSupportHp(supportId, supportLevel){
        let smp1 = SMPNum.fromNum(((supportId - 1) * 0.2 + 1) * supportLevel);
        let smp2 = new SMPNum(1.06);
        let hp = SMPNum.multSmpNum(smp1, smp2.PowfromInt(supportLevel));
        let maxHp = SMPNum.plus(hp, SMPNum.fromNum(20));
        return maxHp;
    }
}

module.exports = SMPSupportGamePlay;