let SMPNum = require("../../../src/SMPNum");
import HeroSkillDescription from "../constants/HeroSkillDescription";
import HeroSkillType from "../constants/HeroSkillType";

class HeroSkillKpiUtils {

    constructor (heroSkillList, gameDataService) {
        this.skillList = heroSkillList;
        this.gameDataService = gameDataService;
        //console.log(HeroSkillDescription[0]);
    }

    getHeroSkillUnlockCost(skillId){
        return this.gameDataService.getHeroSkillUnlockCost(skillId);
    }

    getHeroSkillUpdateCost(skillId, currentLv, lvAdd){
        return this.gameDataService.getHeroSkillUpdateCost(skillId, currentLv, lvAdd);
    }

    getHeroSkillDisplayFromProgress(listSkillUnlocked){
        let skillDisplays = [];
        this.skillList.forEach(sk => {
            let unkSkill = listSkillUnlocked.find(unk => unk.id === sk.m_iID);
            if(unkSkill !== undefined){
                let bonus = this.getBonusOnSkill(sk.m_iID, unkSkill.level);
                skillDisplays.push({
                    key: "heroSkill_" + sk.m_iID,
                    skill: sk,
                    levelPossible: unkSkill.level,
                    bonus: bonus,
                    bonusDisplay: this.getBonusDisplayOnSkill(sk.m_iID, bonus),
                });
            }
        });
        return {
            skillDisplays: skillDisplays,
        };
    }

    getHeroSkillUnlockedAndSpent(heroLevel, stageLevel, goldAvailable){
        let skillDisplays = [];
        let totalSpent = SMPNum.fromNum(0);
        let spent = SMPNum.fromNum(0);
        this.skillList.forEach(sk => {
            let isUnlock = false;
            if(sk.m_iID === HeroSkillType.PLOV_KAT){
                isUnlock = stageLevel >= sk.Level_Unlock;
            } else {
                isUnlock = heroLevel >= sk.Level_Unlock;
            }
            if(isUnlock){
                spent = SMPNum.plus(spent, this.getHeroSkillUnlockCost(sk.m_iID));
                //console.log(sk.m_sName+" cost: "+this.getHeroSkillUnlockCost(sk.m_iID).ToReadableAlphabetV2()+" unlock total spent: "+spent.ToReadableAlphabetV2());
                if(SMPNum.greaterThan(goldAvailable, spent)){
                    skillDisplays.push({
                        key: "heroSkill_" + sk.m_iID,
                        skill: sk,
                        levelPossible: 1,
                        bonusDisplay: "",
                    });
                    totalSpent.setFromSMPNum(spent);
                } else {
                    return;
                }
            }
        });
        return {
            skillDisplays: skillDisplays,
            totalSpent: totalSpent,
        };
    }

    definePossibleHeroSkillLevel(skillDisplayList, stageLevel, goldAvailable){
        //max active skill level is 99
        let maxLevel = 99;

        let goldRemain = new SMPNum(0);
        goldRemain.setFromSMPNum(goldAvailable);
        let goldSpent = new SMPNum(0);
        //console.log(goldRemain.StringForSave());
        skillDisplayList.forEach(skDis => {
            let lvOnGold = 0;
            if(skDis.skill.m_iID === HeroSkillType.PLOV_KAT){
                lvOnGold = this.gameDataService.heroSkillGamePlay.GetMaxLevelCanUnlockOnStage(stageLevel, 1);
            } else {
                lvOnGold = this.gameDataService.heroSkillGamePlay.GetMaxActiveSkillLevelOnBaseCost(skDis.skill.m_iID, 1, goldRemain);
                if(lvOnGold >= maxLevel){
                    lvOnGold = maxLevel - 1;
                }
            }

            let realCost = this.getHeroSkillUpdateCost(skDis.skill.m_iID, 1, lvOnGold);
            // console.log(skDis.skill.m_sName+" Remain: "+goldRemain.ToReadableAlphabetV2()+" cost to update 1->"+(lvOnGold+1)+": "+realCost.ToReadableAlphabetV2());
            if(SMPNum.greaterThan(goldRemain, realCost)){
                skDis.levelPossible = 1 + lvOnGold;
                skDis.bonus = this.getBonusOnSkill(skDis.skill.m_iID, skDis.levelPossible);
                skDis.bonusDisplay = this.getBonusDisplayOnSkill(skDis.skill.m_iID, skDis.bonus);
                goldRemain = SMPNum.minus(goldRemain, realCost);
                goldSpent = SMPNum.plus(goldSpent, realCost);
            } else {
                console.log("invalid on define possible hero skill level with gold on skill: "+skDis.skill.m_sName);
            }
        });

        return goldSpent;
    }

    getBonusOnSkill(skillId, level){
        let bonus = null;
        if(skillId === HeroSkillType.PLOV_KAT){
            //display = ""+(level)+" wave";
            bonus = level;
        } else {
            bonus = this.gameDataService.heroSkillGamePlay.GetSkillBonus(skillId, level);
        }
        return bonus;
    }

    getBonusDisplayOnSkill(skillId, bonus){
        let desc = HeroSkillDescription[skillId].value;
        if (desc.includes("[N]%"))
        {
            desc = desc.replace("[N]", bonus);
        }
        else if (desc.includes("[xN]"))
        {
            desc = desc.replace("[xN]", "x"+bonus);
        }
        else if (desc.includes("[-N]"))
        {
            desc = desc.replace("[-N]", "-"+bonus);
        }
        else if (desc.includes("[N]"))
        {
            desc = desc.replace("[N]", bonus);
        }

        return desc;
    }

    getHeroSkillPossibleByTypes(skillDisplayList, types){
        let skillResult = [];
        skillDisplayList.forEach(skDis => {
            if(types.includes(skDis.skill.availableType)){
                skillResult.push(skDis);
            }
        });
        return skillResult;
    }
}

export default HeroSkillKpiUtils;
