import { render, staticRenderFns } from "./GroupGoldCollect.vue?vue&type=template&id=3c5fe505&scoped=true&"
import script from "./GroupGoldCollect.vue?vue&type=script&lang=js&"
export * from "./GroupGoldCollect.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c5fe505",
  null
  
)

export default component.exports