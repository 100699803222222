import GamePlayItem from "./../classes/GamePlayItem";
import kpiLevelAndDirection from "./../constants/data-rules/kpiLevelAndDirectionList";
import {BehaviourByNames} from "@/item-editor/constants/BehaviourTypes";


export default {
    getSmpGemUnit(config){
        let bonus = config.startBonusPercentage;
        if(config.configOnMultiple){
            config.configOnMultiple.forEach(cfm => {
                bonus+=cfm.startBonusPercentage;
            });
        }
        if(config.impactType === BehaviourByNames.incGoldDrop
            || config.impactType === BehaviourByNames.incGoldEnemyDrop){
            let smpGem = Math.round(bonus / 100);//bonus 100% will cost 1 SMPGem, mean equal to gold per drop
            smpGem = Math.max(1, smpGem);
            return smpGem;
        } else if(config.impactType === BehaviourByNames.fillHp){
            let smpGem = Math.round(2 * (bonus / 100));//bonus 100% will cost 1 SMPGem, mean equal to gold per drop
            smpGem = Math.max(1, smpGem);
            return smpGem;
        } else {
            let smpGem = Math.round(10 * (bonus / 100));
            smpGem = Math.max(1, smpGem);
            return smpGem;
        }
    },

    getItemLevelConfig(gameLevel, levelRangeSelect){
        let levelStart = gameLevel.levelStart > 0 ? gameLevel.levelStart : 20;
        let levelEnd = gameLevel.levelEnd > 0 ? gameLevel.levelEnd : -1;
        let levelConfig = {
            'startLevel': levelStart,
            'levelRangePerLevel': levelRangeSelect,
            'kpiLevel': gameLevel.kpiLevel ? gameLevel.kpiLevel : levelStart,
            'endLevel' : levelEnd
        };
        return levelConfig;
    },

    getSiblingItems(baseGroupItem, baseItem, lvStart){
        let resultItems = [];
        let config = baseGroupItem.config;
        for (let gameLevel = lvStart; gameLevel < config.endLevel; gameLevel += config.levelRangeSelect) {
            let item = new GamePlayItem(baseItem);
            let levelConfig = this.getItemLevelConfig(item.gameLevel, config.levelRangeSelect);
            item.level = this.getItemLevelAndApplyItemGameLevel(gameLevel, item.gameLevel, levelConfig);
            item.levelConfig = levelConfig;
            resultItems.push(item);
        }
        return resultItems;
    },

    getItemLevelAndApplyItemGameLevel(gameLevel, itemGameLevel, levelConfig) {
        let itemLevel = this.getItemLevel(gameLevel, levelConfig);
        itemGameLevel.levelStart = levelConfig.startLevel + ((itemLevel - 1) * levelConfig.levelRangePerLevel);
        itemGameLevel.levelEnd = itemGameLevel.levelStart + levelConfig.levelRangePerLevel;
        //update new kpiLevel by new levelStart
        levelConfig.kpiLevel = this.getKpiLevelItemLevel(gameLevel, levelConfig);
        itemGameLevel.kpiLevel = levelConfig.kpiLevel;
        return itemLevel;
    },
    getItemLevel(gameLevel, levelConfig) {
        let level = 1;
        let levelRange = gameLevel - levelConfig.startLevel;
        if (levelRange > 0 && levelRange >= levelConfig.levelRangePerLevel) {
            level += Number.parseInt((Math.floor(levelRange * 1.0 / levelConfig.levelRangePerLevel)).toString());
        }
        return level;
    },
    getKpiLevelItemLevel(gameLevel, levelConfig) {
        //to fix old data don't have kpiLevel
        if (levelConfig.kpiLevel <= 0) {
            levelConfig.kpiLevel = levelConfig.startLevel;
        }
        let itemLevel = this.getItemLevel(gameLevel, levelConfig);
        let curKpiLevel = levelConfig.kpiLevel + levelConfig.levelRangePerLevel * (itemLevel - 1);

        //Apply kpiLevel limit, to too high item cost in other range
        curKpiLevel = this.getKpiLevelWithOutRangeLimit(gameLevel, curKpiLevel);

        return curKpiLevel;
    },

    getKpiLevelByDirection(behaviourBean, levelStart){
       let diKpi = kpiLevelAndDirection.filter((k) => {
           if(k.type === behaviourBean.type){
               return k.receivers.length === behaviourBean.receivers.length &&
                   k.receivers.every((v, i) => v.value === behaviourBean.receivers[i].value);
           } else{
               return false;
           }
        });
       if(diKpi && diKpi.length > 0){
           if(diKpi.length > 1) console.log('duplicate di kpi level const');
           return levelStart + diKpi[0].diKpiLevel;
       } else {
           console.log('no di kpi level const');
           return levelStart;
       }
    },

    getKpiLevelWithOutRangeLimit(startLevel, kpiLevel){
        let trim1KStartLv = Math.floor(startLevel / 1000);
        let trim1KKpi = Math.floor(kpiLevel / 1000);
        if(trim1KKpi > trim1KStartLv){
            return trim1KKpi * 1000;
        } else {
            return kpiLevel;
        }
    }
}
