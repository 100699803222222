let SMPNum = require("../../SMPNum");

let SequenceName = {
    "CostHero" : "CostHero",
    "DropCoins" : "DropCoins",
    "CostSupport" : "CostSupport",
    "HPGhost" : "HPGhost",
    "HPBoss" : "HPBoss",
    "HPZoneBoss": "HPZoneBoss",
    "DMGHero" : "DMGHero",
    "DMGBoss" : "DMGBoss"
}

class SMPMathCore {
    K_GAME_INTRO = 0;
    K_GAME_TUNNEL_1 = 1;
    K_GAME_TUNNEL_2 = 2;
    K_GAME_TUNNEL_3 = 3;

    resultSequenceDefault = [];

    isLoadedFromGamePlay = false;
    ratioSeriesCommon = [];
    ratioFirstTerm = [];

    constructor(ratios) {
        this.resultSequenceDefault = [];
        this.resultSequenceDefault.push(this.SequenceInterval(1, 100));
        this.resultSequenceDefault.push(this.SequenceInterval(101, 200));

        // this.resultSequenceDefault.push(this.SequenceInterval(101, 1000));
        // this.resultSequenceDefault.push(this.SequenceInterval(1001, 3000));
        // this.resultSequenceDefault.push(this.SequenceInterval(3001, 500000));
        // this.resultSequenceDefault.push(this.SequenceInterval(500001, 110000000));

        this.updateRatio(ratios);
    }

    GetMaxIntervalIndex(){
        //=> max interval from level 500001 -> 110000000
        return 500;
    }

    GetIntervalIndex(level){
        //  1->100 => 0
        //101->200 => 1
        //201->300 => 2
        return Math.floor((level - 1) / 100);
    }

    GetSequenceInteral(index){
        if (index >= this.GetMaxIntervalIndex()){
            //=> max interval from level 500001->110000000
            let seq = this.SequenceInterval(500001, 110000000);
            seq.indexInterval = index;
            return seq;
        } else {
            //0 =>   1->100
            //1 => 101->200
            //2 => 201->300
            let start = (index * 100) + 1;
            let seq = this.SequenceInterval(start, start + 99)
            seq.indexInterval = index;
            return seq;
        }
    }

    updateRatio(ratios){
        if (ratios) {
            let keyRatio = Object.keys(ratios);
            keyRatio.forEach(key => {
                let keyValue = Object.keys(ratios[key]);
                keyValue.forEach(kv => {
                    //console.log(key+"_"+kv+"_"+ratios[key][kv]);
                    this.SetRatioConfigByKey(key + "_" + kv, ratios[key][kv]);
                });
            });
            this.isLoadedFromGamePlay = true;
        }
    }

    SequenceInterval(s, m) {
        return {
            "start": s,
            "max": m,
            "indexInterval": 0
        };
    }

    GetSeriesCommonRatioGamePlay(indexInterval, seriesName) {
        //console.log("getRS: "+"SeriesCommon_" + seriesName + indexInterval);
        return this.ratioSeriesCommon["SeriesCommon_" + seriesName + indexInterval];
    }

    GetFirstTermRatioGamePlay(indexInterval, seriesName) {
        return this.ratioFirstTerm["FirstTerm_" + seriesName + indexInterval];
    }

    SetRatioSeriesCommon(key, value) {
        this.ratioSeriesCommon[key] = value;
        //console.log("setRS: "+key+"="+value);
    }

    SetRatioFirstTerm(key, value) {
        this.ratioFirstTerm[key] = value;
        //console.log("setFT: "+key+"="+value);
    }

    SetRatioConfigByKey(key, ratio) {
        let pre = "";
        let isCommon = false;
        if (key.includes("Common")) {
            key = key.replace(" Common", "");
            pre = "SeriesCommon_";
            isCommon = true;
        } else {
            key = key.replace(" FirstTerm", "");
            pre = "FirstTerm_";
        }
        if (key.includes("_INTRO")) {
            key = key.replace("_INTRO", "0");
        } else if (key.includes("_TUNNEL_")) {
            key = key.replace("_TUNNEL_", "");
        } else {
            key = key.replace("_DEFAULT", "4");
        }

        let keyType = pre + key;
        if (isCommon) {
            this.SetRatioSeriesCommon(keyType, ratio);
        } else {
            this.SetRatioFirstTerm(keyType, ratio);
        }
    }

    // getSequences(sequenceName) {
    //     switch (sequenceName) {
    //         case SequenceName.CostHero:
    //         case SequenceName.DropCoins:
    //         default :
    //             return this.resultSequenceDefault;
    //     }
    // }

    GetSeriesFirstTermByLevel(level, seriesName) {
        if (seriesName === SequenceName.DropCoins){
            let firstTerm = this.GetSeriesFirstTerm(this.GetIntervalIndex(level), seriesName).ToDoubleValue();//convert to float for allow it locat as negative number
            let diffFirst = level <= 12 ? 0 : level <= 100 ? 1.5 : 1;
            let multiDiff = level <= 12 ? 0 : level <= 100 ? 1 : 1;
            firstTerm += diffFirst;
            let dirSin = level <= 100 ? 1 : -1;

            //15.915494309 is perfect change between 25 level
            //15.5 is validated under 100 levels
            let ratio = firstTerm + multiDiff * Math.sin(dirSin * level / 15.915494309);
            //first term normal state from level 100 is 2.6

            return SMPNum.fromNum(ratio);
        } else if (seriesName === SequenceName.CostHero || seriesName === SequenceName.CostSupport) {
            let firstTerm = this.GetSeriesFirstTerm(this.GetIntervalIndex(level), seriesName).ToDoubleValue();//convert to float for allow it locat as negative number
            //firstTerm = level <= 1000 ? firstTerm  : firstTerm + 0.5* Math.FloorToInt(level/1000);
            if (level > 1000)
            {
                let diffK = Math.floor(level * 1.0 / 1000);
                let diff = 0.5 * diffK * diffK;
                firstTerm += diff;

                //to fix game play was always normal difficulty since level 4000 to 8000
                if (this.IsStayNormalLevelRange(level))
                {
                    firstTerm += 10;
                }
            }
            return SMPNum.fromNum(firstTerm);
        } else {
            return this.GetSeriesFirstTerm(this.GetIntervalIndex(level), seriesName);
        }
        //return this.GetSeriesFirstTerm(this.GetIntervalIndex(level), seriesName);
    }

    IsStayNormalLevelRange(level){
        if(level >= 4000){
            let trim10KLevel = level % 10000;
            return trim10KLevel >= 4000 && trim10KLevel <= 8000;
        }
        return false;
    }

    GetTermValueForSeries(level, seriesName) {

        let commonRatio = this.GetSeriesCommonRatioByLevel(level, seriesName);
        if (commonRatio === -1) {
            console.log("cant generate series without ratio");
        }

        //remove some line of code reduce new object and same result
        return new SMPNum(commonRatio).PowfromInt(level - 1);

        /*not apply optimize
        let rPowered = new SMPNum(commonRatio).Pow(level - 1);
        let firstTermOfSequence = new SMPNum(1);
        return firstTermOfSequence * rPowered;*/
    }

    // getSequenceIntervalInfoFor(level, sequenceName) {
    //     level = Math.floor(level);
    //     let seq = this.getSequences(sequenceName);
    //     let result = null;
    //     if (!seq) {
    //         return null;
    //     }
    //     seq.forEach((interval, index) => {
    //         if (level >= interval.start && level <= interval.max) {
    //             result = interval;
    //             result.indexInterval = index;
    //         }
    //     });
    //     return result;
    // }

    GetSeriesCommonRatioByLevel(level, seriesName) {
        return this.GetSeriesCommonRatio(this.GetIntervalIndex(level), seriesName);
        // let interval = this.getSequenceIntervalInfoFor(level, seriesName);
        // let indexInterval = interval != null ? interval.indexInterval : -1;
        //
        // let r = this.GetSeriesCommonRatio(indexInterval, seriesName);
        //
        // // if(seriesName === "HPBoss" || seriesName === "HPZoneBoss")
        // //     console.log(seriesName+" lv: "+level+" RatioByLv: "+r);
        //
        // return r;
    }

    GetSeriesFirstTerm(indexInterval, seriesName) {
        if (seriesName !== SequenceName.CostHero
            && seriesName !== SequenceName.CostSupport
            && seriesName !== SequenceName.DropCoins)
        {
            //indexInterval = indexInterval <= 1? indexInterval : 1;
            return SMPNum.fromNum(this.GetFirstTermRatioGamePlay(0, seriesName));
        }

        // indexInterval[0,1] => return normal
        // indexInterval[2,max] => return dynamic ratio = [0] + index * ([1]-[0])
        let ratio;
        if (indexInterval < 2)
        {
            ratio = this.GetFirstTermRatioGamePlay(indexInterval, seriesName);
        }
        else
        {
            let baseRatio = this.GetFirstTermRatioGamePlay(0, seriesName);
            let nextRatio = this.GetFirstTermRatioGamePlay(1, seriesName);
            ratio = baseRatio + (nextRatio - baseRatio) * indexInterval;
        }
        // Debug.Log($"FirstTerm seriesName: {seriesName} indexInterval: {indexInterval} ratio: {ratio}");
        return SMPNum.fromNum(ratio);
    }

    GetSeriesCommonRatio(indexInterval, seriesName) {
        if (seriesName !== SequenceName.CostHero
            && seriesName !== SequenceName.CostSupport
            && seriesName !== SequenceName.DropCoins)
        {
            //indexInterval = indexInterval <= 1 ? indexInterval : 1;
            return this.GetSeriesCommonRatioGamePlay(0, seriesName);
        }

        // indexInterval[0,1] => return normal
        // indexInterval[2,max] => return dynamic ratio = [0] + index * ([1]-[0])
        let ratio;
        if (indexInterval < 2)
        {
            ratio = this.GetSeriesCommonRatioGamePlay(indexInterval, seriesName);
        }
        else
        {
            let baseRatio = this.GetSeriesCommonRatioGamePlay(0, seriesName);
            let nextRatio = this.GetSeriesCommonRatioGamePlay(1, seriesName);
            ratio = baseRatio + (nextRatio - baseRatio) * indexInterval;
        }

        // Debug.Log($"Ratio seriesName: {seriesName} indexInterval: {indexInterval} ratio: {ratio}");
        return ratio;
    }

    GeometrySum(n, firstTerm, cmRatio, current = 1) {
        if (current <= 0) {
            console.log("Current must be bigger than zero!");
        }

        let u1 = firstTerm;
        let cmNum = SMPNum.fromNum(cmRatio);
        let u = SMPNum.multSmpNum(u1, cmNum.PowfromInt(current - 1));
        let sum = SMPNum.multSmpNum(u, SMPNum.divSmpNum(SMPNum.minus(cmNum.PowfromInt(n), SMPNum.fromNum(1)), SMPNum.minus(cmNum, SMPNum.fromNum(1))));

        // let u1 = firstTerm;
        // let cmNum = SMPNum.fromNum(cmRatio);
        // let u = u1 * cmNum.pow(current - 1);
        // let sum = u * ((cmNum.pow(n) - 1) / (cmRatio - 1));
        return sum;
    }

    GetUnUsingSum(sum, firstTerm, cmRatio, current = 1) {
        if (current <= 0)
        {
            throw ("Current must be bigger than zero!");
        }

        /*gameplay
        var u1 = firstTerm;
        var u = u1 * SMPNum.FromNum(cmRatio).Pow(current - 1);
        var first = (sum * (cmRatio - 1) / u + 1);
        var result = first.Log(cmRatio);
         */

        let u1 = firstTerm;

        let u = SMPNum.multSmpNum(
            u1,
            SMPNum.pow(
                SMPNum.fromNum(cmRatio),
                current - 1
            )
        ) ;
        let first = SMPNum.plus(
            SMPNum.divSmpNum(
                SMPNum.multSmpNum(
                    sum,
                    SMPNum.fromNum(cmRatio - 1)
                ),
                u
            ),
            SMPNum.fromNum(1)
        );

        /*in correct order of calculate that take from old kpi*/
        // let first = SMPNum.multSmpNum(
        //     sum ,
        //     SMPNum.plus(
        //         SMPNum.divSmpNum(SMPNum.fromNum(cmRatio - 1) , u) ,
        //         SMPNum.fromNum(1)
        //     )
        // );

        if(first.ToDoubleValue() >= cmRatio){
            let result = SMPNum.logBaseSmpNum(first, cmRatio);
            return result.ToDoubleIfPossible();
        } else {
            return 0;
        }
    }
}

module.exports = SMPMathCore;