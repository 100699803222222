<template>
  <v-card elevation="0" light width="1200">
      <HeaderPopup
            @save="validateToSaveOrCreateNewItem"
            @close="close()"
            @sibling="showSibling()"
            @pushing="doPushing()"
            @deleting="doDeleting()"
            @hardDeleting="doHardDeleting()"
            :p-show-sibling="isBTShowSibling"
            :pPushing="isBTShowPushing"
            :pDeleting="isBTShowDeleting"
            :pHardDeleting="isBTShowHardDeleting"
      >
          {{getFormTitle}}
      </HeaderPopup>
      <v-form ref="form" lazy-validation>
          <v-card elevation="0"
                  class="grey lighten-5 overflow-auto"
                  :max-height="computeWindowHeight">

              <v-row>
                  <v-col cols="2">
                      <v-img class="my-3 ml-3"
                             v-if="this.getPossibleBoarderIcon"
                             :src="getBoarderIcon()"
                             min-width="100"
                             max-width="100"
                             @click="clickToChangeIcon"
                      >
                          <v-row class="fill-height">
                              <v-row justify="center">
                                  <v-card-title>
                                      <v-img
                                              :src="getIcon()"
                                              max-width="60px"
                                              max-height="60px"
                                              dark
                                              class="mt-3"
                                      ></v-img>
                                  </v-card-title>
                              </v-row>
                          </v-row>
                      </v-img>
                      <v-img
                              v-if="!this.getPossibleBoarderIcon"
                              :src="getIcon()"
                              max-width="85px"
                              max-height="85px"
                              dark
                              @click="clickToChangeIcon"
                              class="my-3">
                      </v-img>
                  </v-col>
                  <v-col cols="2" align-self="center">
                      <v-chip class="mt-1" label small>
                          #{{this.itemData.category}}
                      </v-chip>
                      <v-chip class="mt-1" label small v-if="itemData.color">
                          #{{itemData.color}}
                      </v-chip>
                      <v-chip class="mt-1" label small v-if="this.renderMainBehaviourCat()">
                          #{{this.renderMainBehaviourCat()}}
                      </v-chip>
                      <v-chip class="mt-1" label small v-if="this.shouldRenderTargetSelect()">
                          #{{this.renderTargetSelect()}}
                      </v-chip>
                  </v-col>
                  <v-col class="pt-10" v-if="itemFormDescription!==''">
                      <v-card-text v-html="itemFormDescription"></v-card-text>
                  </v-col>
              </v-row>

              <div v-if="this.itemData.category==='weapon' || this.itemData.category==='glove'">
                <v-container v-if="supportedHeroes.length > 0">
                    Heroes can use this weapon:
                    <v-row>
                        <v-img
                            v-for="hID in supportedHeroes" :key="hID"
                                    :src="getHeroHeadIcon(hID)"
                                    max-width="50px"
                                    max-height="50px"
                                    dark
                                    class="mt-3"
                            ></v-img>
                      </v-row>
                  </v-container>
                  <v-container v-else>
                    <v-chip
                        class="ma-2"
                        color="red lighten-1"
                        text-color="white"
                        >
                        There is no hero data matching this weapon.
                    </v-chip>
                  </v-container>
    
              </div>
              <br/>

              <InputValidate :rules="[itemData.behaviours.length > 0 || 'Behaviour required!!!']">
                  Behaviour *
              </InputValidate>
              <v-container>
                  <ul>
                      <p v-for="behaviour in itemData.behaviours" :key="behaviour.behaviourBean.join">

                          <v-btn
                              class="ma-2"
                              text
                              icon
                              color="red lighten-2"
                              @click="removeBehaviour(behaviour.behaviourBean.join)"
                          >
                              <v-icon> mdi-delete-forever </v-icon>
                          </v-btn>

                          <v-btn
                              class="ma-2"
                              text
                              icon
                              color="green lighten-2"
                              @click="editBehaviour(behaviour.behaviourBean.join)"
                          >
                              <v-icon>mdi-table-edit</v-icon>
                          </v-btn>
                          {{ renderDetailBean(behaviour) }} {{renderGameplayImpact(behaviour)}}
                          <strong>{{renderVisualDescription(behaviour)}}</strong>
                      </p>
                  </ul>

                  <v-btn
                      class="ma-2"
                      text
                      icon
                      @click="hideOrShowFormBehaviour()"
                  >
                      <v-icon color="green lighten-2">mdi-note-plus</v-icon>
                  </v-btn>
              </v-container>


              <InputValidate
                      v-if="!isLifeTimeHide"
                      :rules="[itemData.lifeTime.type !== '' || 'Life required!!!']">
                  Life time - How long item can stay *
              </InputValidate>
              <v-container
                      v-if="!isLifeTimeHide">
                  <v-row>
                      <v-col>
                          <v-combobox
                              :disabled="itemData.behaviours.length <= 0"
                              :items="computeRefLifeTime"
                              v-model="itemData.lifeTime.bean"
                              label="Type"
                              dense
                              @change="didSelectLifeTimeType"
                              :rules="rulesNotEmpty('life type')"
                          ></v-combobox>
                      </v-col>
                  </v-row>
              </v-container>


              <InputValidate v-if="behaviourNeedTargetSelect" :rules="[itemData.targetSelect.length>0 || 'Target required!!!']">
                  Select Owner to equip or to use *
              </InputValidate>
              <v-container v-if="behaviourNeedTargetSelect">
                  <v-btn
                      class="mx-1 mb-3"
                      v-for="mode in refTargetSelects"
                      :key="mode"
                      @click="didSelectTarget(mode)"
                  >
                      <v-icon  :color="renderTargetSelectBookmark(mode)"  >mdi-bookmark</v-icon>
                      {{mode}}
                  </v-btn>
              </v-container>



              <InputValidate v-if="shouldShowModeSelect" :rules="[itemData.modes.length>0 || 'mode required!!!']">
                  Available to use in mode *
              </InputValidate>
              <v-container v-if="shouldShowModeSelect">
                  <v-btn
                          class="mx-1  mb-3"
                          v-for="mode in refModes"
                          :key="mode"
                          @click="didSelectMode(mode)"
                  >
                      <v-icon  :color="renderModeBookmark(mode)"  >mdi-bookmark</v-icon>
                      {{mode}}
                  </v-btn>
              </v-container>

              <InputValidate
                      v-if="itemData.gameLevel"
                      :rules="[itemData.gameLevel.levelStart>0 || 'Game level require'
                                , itemData.gameLevel.kpiLevel>0 || 'kpiLevel invalid'
                                , itemGroupData.config.endLevel>itemData.gameLevel.levelStart || 'disappear Game level require']">
                  Game Level - where item start appear on game play
              </InputValidate>
              <v-container
                      v-if="itemData.gameLevel">
                  <v-row>
                      <v-col>
                          <v-text-field
                                  v-model="itemData.gameLevel.levelStart"
                                  label="start game level appear"
                                  :rules="rulesNotEmpty('levelStart')"
                                  type="number"
                                  @input="onGameLevelInput()"
                          ></v-text-field>
                      </v-col>
                      <v-col>
                          <v-text-field
                                  v-model="itemGroupData.config.endLevel"
                                  label="end game level disappear"
                                  :rules="rulesNotEmpty('levelEnd')"
                                  type="number"
                                  @input="onEndGameLevelInput()"
                          ></v-text-field>
                      </v-col>
                      <v-col>
                          <v-text-field
                                  v-model="itemData.gameLevel.kpiLevel"
                                  label="kpi level"
                                  disabled
                          ></v-text-field>
                      </v-col>
                  </v-row>
              </v-container>

              Localize Data
              <v-container>
                  <LocalizeItemForm
                          :itemData="itemData"
                          @onUpdateLocalize="refreshSiblingBTStatus"
                          @onFailedLocalize="onFailedLocalize"
                  >
                  </LocalizeItemForm>
              </v-container>

              <SmpDialog
                      :should-show="isJsonModelView"
                      @close="isJsonModelView=false"
              >
                  <div v-html="itemModelJson"></div>
              </SmpDialog>

              <v-overlay :value="showFormBehaviour" v-if="formBehaviour">
                  <NewBehaviourForm
                          @close="hideOrShowFormBehaviour()"
                          @addVisualEffect="addVisualEffect"
                          :pFormBehaviour="formBehaviour"
                          :pItemCategory="itemData.category"
                  />
              </v-overlay>

              <v-overlay :value="showFormSibling" v-if="formSibling">
                  <SiblingItemPreviewForm
                          @close="hideOrShowFormSibling()"
                          @siblingGenerated="onSiblingGenerated()"
                          :pItemGroupData="this.groupDataForSibling"
                  />
              </v-overlay>

              <v-overlay :value="isLoading">
                  <v-progress-circular
                          indeterminate
                          size="64"
                  ></v-progress-circular>
              </v-overlay>

              <PopupMessage
                      v-if="textSnackbar!==''"
                      :pColor="colorSnackbar"
                      :pTimeout="timeoutSnackbar"
                      @onClose="textSnackbar=''"
              >
                  {{ textSnackbar }}
              </PopupMessage>
          </v-card>
      </v-form>
  </v-card>

</template>

<script>
    import SMPNum from "../../SMPNum";
    import lodash from 'lodash';
    import NewBehaviourForm from "@/item-editor/modules/BehaviourForm/screens/NewBehaviourForm";
    import EquipmentDataLoader from "@/item-editor/dao/EquipmentDataLoader";
    import HeroAndWeaponDataLoader from "@/item-editor/dao/HeroAndWeaponDataLoader";
    import ShieldDataLoader from "@/item-editor/dao/ShieldDataLoader";
    import ItemApi from "../dao/ItemApi.js"
    import LocalizeItemForm from "@/item-editor/modules/LocalizeItemForm";
    import {EVENT_ON_ITEM_SAVED, EVENT_TO_CHANGE_ICON_ITEM, EVENT_TO_CHANGE_ICON_SIBLING_ITEM, GlobalEvents, EVENT_UPDATED_AUTOMATE_ITEM_FORM} from "@/item-editor/events/GlobalEvents";
    import ItemColorConstants from "../constants/ItemColorConstants.js";
    import GamePlayImpactType from "@/item-editor/constants/GamePlayImpactType";
    import HeaderPopup from "./HeaderPopupComponent.vue";
    import ItemCategories, {ItemCategoryNames} from "@/item-editor/constants/ItemCategories";
    import ItemColors from "@/item-editor/constants/ItemColors";
    import InputValidate from "./InputVilidateComponent.vue";
    import ItemLifeTime, {ItemLifeTimeShortcut} from "@/item-editor/constants/ItemLifeTime";
    import BehaviourUtils from "@/item-editor/Utils/BehaviourUtils";
    import LifeTimeUtils from "@/item-editor/Utils/LifeTimeUtils";
    import ItemTargets from "@/item-editor/constants/ItemTargets";
    import SiblingItemPreviewForm from "./SiblingForm/screens/SiblingItemPreviewForm";
    import GamePlayGroupItem from "../classes/GamePlayGroupItem";
    import SiblingItemMultipleImpactUtils from "../Utils/SiblingItemMultipleImpactUtils";
    import UGSItemInventoryModel from "../classes/UGSItemInventoryModel";
    //import UnityWebApi from "../dao/UnityWebApi.js"
    import UGSVirtualPurchaseModel from "../classes/UGSVirtualPurchaseModel";
    import SmpDialog from "./SmpDialog";
    import PopupMessage from "./PopupMessage/PopupMessage";
    //import GameModeUtils from "../Utils/GameModeUtils";
    import UnityServiceApi from "@/unity-service-dashboard/api/UnityServiceApi";
    import UGSItemUtils from "./../Utils/UGSItemUtils";
    import ItemDeleteUtils from "./../Utils/ItemDeleteUtils";
    import ItemUGSPushUtils from "../Utils/ItemUGSPushUtils";

    let valueTypes = [];
    GamePlayImpactType.valueTypes.forEach(v => {
        valueTypes.push(v);
    });


export default {
    name: "NewItemForm",
    props: {
        'pItemGroupData':{
            type: Object,
            required: true
        },
        'pRenderId':{
            pRenderId: String,
            default: '',
            required: false
        },
    },
    data() {
        return {
            isNewItemForm: true,
            textSnackbar: '',
            colorSnackbar: 'success',
            timeoutSnackbar: 2000,
            isLoading: false,
            showFormBehaviour: false,
            refEquipmentData: [],
            refHeroAndWeaponData: [],
            refShieldData:[],
            refModes: ['Battle', 'Normal'],
            refTargetSelects: [],
            refCategories: ItemCategories,
            refColors: ItemColors,
            refLifeTimeType: ItemLifeTime,
            itemGroupData: null,
            itemData: null,
            backUpIconName: null,
            formBehaviour: null,
            itemFormDescription: "",
            isLifeTimeHide: false,
            showFormSibling: false,
            formSibling: null,
            lastItemStatus: "",
            groupDataForSibling: null,
            siblingUtils:null,
            isBTShowSibling:false,
            isInitialize: false,
            isFromAutoMate: false,
            lastTitleName: '',
            isBTShowPushing: false,
            isBTShowDeleting: false,
            isBTShowHardDeleting: false,
            isJsonModelView: false,
            itemModelJson: '',
        }
    },
    components: {
        SiblingItemPreviewForm,
        LocalizeItemForm,
        NewBehaviourForm,
        HeaderPopup,
        InputValidate,
        SmpDialog,
        PopupMessage
    },
    methods: {
        renderMainBehaviourCat (){
            let mainBehaviour = this.getMainBehaviour();
            if (mainBehaviour){
                return mainBehaviour.behaviourBean.cat;
            } else {
                return null;
            }
        },
        renderVisualDescription(behaviour){
            if (behaviour.gamePlayImpact.value < 0 || !behaviour.gamePlayImpact.valueType) {
                return '';
            }

            let effects = behaviour.gamePlayImpact.visualEffectOfTargets;
            let effName = "";
            if(effects){
                effects.forEach(eff => {
                    if(effName !== ""){
                        effName += ", ";
                    }
                    effName += eff.effect;
                    effName+="("+eff.duration+"s)";
                });
            }

            if(effName === ""){
                return "";
            }
            return ". VSE: "+ effName;
        },
        renderGameplayImpact: function (behaviour) {
            if (behaviour.gamePlayImpact.value < 0 || !behaviour.gamePlayImpact.valueType) {
                return '';
            }
            let des = "";
            let bonus = behaviour.gamePlayImpact.value;
            let bean = behaviour.gamePlayImpact.bean;
            if(bean !== null && bean.valueType !== null && bean.valueType.value === "DirectDisplayValue"){
                des += bean.valueType.text;
            } else if(behaviour.gamePlayImpact.valueType === "DirectValue"){
                des = SMPNum.fromPowerString(bonus).ToReadableAlphabetV2();
            } else {
                des = bonus.toFixed(2);
            }
            des += " "
                + GamePlayImpactType.valueTypeSymbols[behaviour.gamePlayImpact.valueType];

            if (behaviour.gamePlayImpact.periodValue > 0) {
                des += ' every ' + behaviour.gamePlayImpact.periodValue
                    + " "
                    + GamePlayImpactType.periodTypeSymbols[behaviour.gamePlayImpact.periodType];
            }

            return des;
        },
        renderDetailBean(behaviour){
            let detail = "" + behaviour.behaviourBean.des.details;
            if(behaviour.type === "inc_powerup_capacity"){
                let skillType = behaviour.gamePlayImpact.skillType;
                if(skillType !== null) {
                    if (detail.includes("Power up")) {
                        detail = detail.replace("Power up", skillType);
                    } else {
                        detail += " " + behaviour.gamePlayImpact.skillType;
                    }
                } else if (detail.includes("Power up")) {
                    detail = detail.replace("Power up", "all power-up");
                }
            }
            return detail;
        },

        initDefaultFormBehavior() {
            this.formBehaviour = {
                behaviourBean: null,
                type: "",
                gamePlayImpact: {
                    value: null,
                    valueType: null,
                    periodValue: null,
                    periodType: null,
                    skillType: null,
                    visualEffectOfTargets: [],
                    receivers: [],
                }
            };
        },
        getIcon() {
            let iconName = this.itemData.iconName + ".png";

            let result = null;
            try {
                result = require('../assets/weapons/textures/' + iconName);
            } catch(e){
                console.log("Not found image name : " + iconName);
                result = require('../assets/weapons/no-image.png');
            }

            return result;
        },
        getBoarderIcon() {
            let color = this.itemData.color;
            if (!color) {
                return this.getIcon();
            }
            let iconName = ItemColorConstants.iconColorNames[color];
            return require('../assets/icons/itemboarders/' + iconName);
        },
        getHeroHeadIcon(hID) {
            return require('../assets/heroes/id' + hID+'.png');
        },
        renderModeBookmark(target) {
            let color = 'grey';
            if (!this.itemData.modes) {
                color = 'green';
                return color;
            }
            this.itemData.modes.forEach(v => {
                if (v === target) {
                    color = 'green';
                }
            })
            return color;
        },
        renderTargetSelectBookmark(target) {
            let color = 'grey';
            this.itemData.targetSelect.forEach(v => {
                if (v === target) {
                    color = 'green';
                }
            })
            return color;
        },
        shouldRenderTargetSelect() {
            return this.itemData.targetSelect && this.itemData.targetSelect.length > 0;
        },
        renderTargetSelect() {
            let targets = '';
            let length = this.itemData.targetSelect.length;
            for (let i = 0; i < length; i++) {
                targets += this.itemData.targetSelect[i];
                if (i < length - 1) {
                    targets += ' | '
                }
            }

            return targets;
        },
        isPushReady(){
            return this.itemData.pushStatus.includes(this.$store.state.ugsSetting.environmentId);
        },
        assignPushStatus(){
            //to update current item on this form data, because this one just a copy not in store itemList
            let item = this.itemData;
            let envId = this.$store.state.ugsSetting.environmentId;
            if(!item.pushStatus){
                item.pushStatus = [];
                item.pushStatus.push(envId);
            } else if(!item.pushStatus.includes(envId)){
                item.pushStatus.push(envId);
            }
            this.isBTShowPushing = false;
            this.isBTShowDeleting = true;

            //to refresh item push status in itemList
            GlobalEvents.$emit(EVENT_ON_ITEM_SAVED);
        },
        removePushStatus(){
            //to update current item on this form data, because this one just a copy not in store itemList
            let item = this.itemData;
            let envId = this.$store.state.ugsSetting.environmentId;
            if(item.pushStatus && item.pushStatus.includes(envId)){
                item.pushStatus.splice(item.pushStatus.indexOf(envId), 1);
            }
            this.isBTShowPushing = true;
            this.isBTShowDeleting = false;

            //to refresh item push status in itemList
            GlobalEvents.$emit(EVENT_ON_ITEM_SAVED);
        },
        doHardDeleting(){
            let callBackSuccess =()=>{
                this.textSnackbar = "Item was deleted!";
                this.colorSnackbar = 'success';
                setTimeout(() => {
                    this.isLoading = false;
                    this.close();
                }, 1500);
            };
            let callBackFailed = (error)=>{
                this.textSnackbar = "Failed to delete item: " + error;
                this.colorSnackbar = 'red';

                setTimeout(() => {
                    this.isLoading = false;
                }, 200);
            };
            this.isLoading = true;
            ItemDeleteUtils.doHardDeleting(this.itemGroupData, this.itemData,callBackSuccess, callBackFailed);
        },
        doDeleting(){
            let itemInventory = new UGSItemInventoryModel(this.itemGroupData);
            let shopItem = new UGSVirtualPurchaseModel(this.itemGroupData, itemInventory);
            this.isLoading = true;
            let ugsSetting = this.$store.state.ugsSetting;
            UnityServiceApi.deleteItemById(itemInventory.id, shopItem.id, ugsSetting.environmentId,ugsSetting.isAutoPublish)
                .then(resultItem => {
                    console.log("Result : " + resultItem);

                    this.removePushStatus();

                    this.textSnackbar = "Item was deleted!";
                    this.colorSnackbar = 'success';

                    setTimeout(() => {
                        this.isLoading = false;
                    }, 1500);
                })
                .catch(error => {
                    this.textSnackbar = "Failed to delete item: " + error;
                    this.colorSnackbar = 'red';

                    setTimeout(() => {
                        this.isLoading = false;
                    }, 200);
                });
        },
        doPushing(){
            let callBackSuccess =()=>{
                this.assignPushStatus();

                this.textSnackbar = "Item was pushed!";
                this.colorSnackbar = 'success';

                setTimeout(() => {
                    this.isLoading = false;
                }, 1500);
            };
            let callBackFailed = (error)=>{
                this.textSnackbar = "Failed to push item: " + error;
                this.colorSnackbar = 'red';

                setTimeout(() => {
                    this.isLoading = false;
                }, 200);
            };
            this.isLoading = true;
            ItemUGSPushUtils.doPushing(this.itemGroupData, callBackSuccess, callBackFailed);

            /*
            let itemInventory = new UGSItemInventoryModel(this.itemGroupData);
            let shopItem = new UGSVirtualPurchaseModel(this.itemGroupData, itemInventory);

            this.isLoading = true;
            let ugsSetting = this.$store.state.ugsSetting;
            UnityServiceApi.pushAddResource(itemInventory, shopItem, ugsSetting.environmentId,ugsSetting.isAutoPublish)
                .then(resultItem => {
                    console.log("Result : " + resultItem);

                    this.assignPushStatus();

                    this.textSnackbar = "Item was pushed!";
                    this.colorSnackbar = 'success';

                    setTimeout(() => {
                        this.isLoading = false;
                    }, 1500);
                })
                .catch(error => {
                    this.textSnackbar = "Failed to push item: " + error;
                    this.colorSnackbar = 'red';

                    setTimeout(() => {
                        this.isLoading = false;
                    }, 200);
                });*/
        },
        showSibling(){
            if(this.isFromAutoMate){
                this.groupDataForSibling = this.itemGroupData;
                this.hideOrShowFormSibling();
            }else {
                let successful = this.$refs.form.validate();
                if (successful) {
                    let itemGroup = this.itemGroupData;
                    let cloneItemGroup = new GamePlayGroupItem(itemGroup, this.itemData);
                    this.groupDataForSibling = cloneItemGroup;
                    this.hideOrShowFormSibling();
                    this.checkTitleNameAndSync();
                }
            }
        },
        isShowSiblingBT() {
            if (this.isNewItemForm && !this.isUpdateSiblingForm()) {
                return false
            } else {
                return true;
            }
        },
        hideOrShowFormSibling: function () {
            if (!this.showFormSibling) {
                //test set not null;
                this.formSibling = {

                };
            }
            this.showFormSibling = (!this.showFormSibling);
        },
        onSiblingGenerated: function(){
            this.close();
        },

        clearModeSelect() {
            if(this.itemData.modes.length > 0){
                this.itemData.modes = [];
                this.validateItemFormDescription();

                //refresh sibling button every update
                this.refreshSiblingBTStatus();
            }
        },
        didSelectMode: function (target) {
            if (!lodash.includes(this.itemData.modes, target)) {
                this.itemData.modes.push(target);
            } else {
                let indexToRemove = lodash.findIndex(this.itemData.modes, function (n) {
                    return n === target;
                })
                this.itemData.modes.splice(indexToRemove, 1);
            }
            this.validateItemFormDescription();

            //refresh sibling button every update
            this.refreshSiblingBTStatus();
        },
        didSelectTarget: function (target) {
            if (!lodash.includes(this.itemData.targetSelect, target)) {
                this.itemData.targetSelect.push(target);
            } else {
                let indexToRemove = lodash.findIndex(this.itemData.targetSelect, function (n) {
                    return n === target;
                })
                this.itemData.targetSelect.splice(indexToRemove, 1);
            }

            this.validateItemFormDescription();

            //refresh sibling button every update
            this.refreshSiblingBTStatus();
        },
        onGameLevelInput(){
            let lvStart = Number.parseInt(this.itemData.gameLevel.levelStart);
            this.itemData.gameLevel.levelStart = lvStart;
            let rang = 10;
            if(this.itemGroupData.config && this.itemGroupData.config.levelRangeSelect){
                rang = Number.parseInt(this.itemGroupData.config.levelRangeSelect);
            }
            this.itemData.gameLevel.levelEnd = lvStart + rang;

            this.refreshKpiGameLevel();
        },
        onEndGameLevelInput(){
            let lv = Number.parseInt(this.itemGroupData.config.endLevel);
            this.itemGroupData.config.endLevel = lv;
        },

        hideOrShowFormBehaviour: function () {
            if (!this.showFormBehaviour) {
                this.initDefaultFormBehavior();
            }
            this.showFormBehaviour = (!this.showFormBehaviour);

            if(!this.showFormBehaviour){
                this.refreshSignLifeTimeDirect();

                this.refreshKpiGameLevel();
                this.refreshImpactConfig();

                //disable increase support speed
                // this.refreshGameMode();
            }

            this.validateItemFormDescription();
        },
        refreshKpiGameLevel(){
            let lvStart = Number.parseInt(this.itemData.gameLevel.levelStart);
            let mainBehaviour = this.getMainBehaviour();
            if (mainBehaviour){
                this.itemData.gameLevel.kpiLevel = UGSItemUtils.getKpiLevelByDirection(mainBehaviour.behaviourBean, lvStart);
            }
        },
        refreshImpactConfig(){
            let mainBehaviour = this.getMainBehaviour();
            if (mainBehaviour){
                this.itemGroupData.config.impactType = mainBehaviour.behaviourBean.type;
            } else {
                this.itemGroupData.config.impactType = undefined;
            }
        },
        refreshSignLifeTimeDirect(){
            this.isLifeTimeHide = false;
            let mainBehave = this.getMainBehaviour();
            if (mainBehave) {
                //add auto transfer impact duration to life time
                let refDirectLifeTime = LifeTimeUtils.getDirectLifeTime(mainBehave);
                if (refDirectLifeTime !== null) {
                    this.didAssignLifeTimeDirect(refDirectLifeTime);
                }
            }
        },
        didSelectLifeTimeType() {
            let lifeTime = ItemLifeTimeShortcut[this.itemData.lifeTime.bean.value];
            this.assignLifeTime(lifeTime);
        },
        didAssignLifeTimeDirect(lifeTime){
            this.isLifeTimeHide = true;
            this.itemData.lifeTime.bean = lifeTime.bean;
            this.assignLifeTime(lifeTime);
        },
        assignLifeTime(lifeTime){
            if (lifeTime){
                this.itemData.lifeTime.type = lifeTime.type;
                this.itemData.lifeTime.limit = lifeTime.value;
            }else {
                this.itemData.lifeTime.type = '';
                this.itemData.lifeTime.limit = 0;
            }
            this.validateItemFormDescription();

            //refresh sibling button every update
            this.refreshSiblingBTStatus();
        },
        clickToChangeIcon() {
            if(this.isUpdateSiblingForm()){
                GlobalEvents.$emit(EVENT_TO_CHANGE_ICON_SIBLING_ITEM,this.itemGroupData);
            } else {
                GlobalEvents.$emit(EVENT_TO_CHANGE_ICON_ITEM,this.itemGroupData);
            }
        },
        validateToSaveOrCreateNewItem(){
            if(this.$refs.form !== undefined) {
                let successful = this.$refs.form.validate();
                if (successful) {
                    this.saveOrCreateNewItem();
                }
            }
        },

        applyDefaulConfiForPush(item){
            //we remove equipmentType from model, because is duplicate with category
            //item.equipmentType = "gameplay";
            item.availableForSale = true;
            item.costPerUnit = "1";
            item.level = 1;
            item.status = "dev";
            item.qty = 100;

            item.costConfig = this.getMainBehaviour().behaviourBean.costConfig;

            if(item.costConfig === undefined){
                //just to add for avoid null on export to game play
                item.costConfig = {
                    kpiLevel : -1,
                    ratio : 1,
                    ghostKill : 10
                };
            }
        },

        saveOrCreateNewItem() {
            if(this.isFromAutoMate){
                this.isLoading = true;
                setTimeout(() => {
                    GlobalEvents.$emit(EVENT_UPDATED_AUTOMATE_ITEM_FORM, this.itemGroupData, this.pRenderId);
                    this.isLoading = false;
                    this.close();
                }, 100);
            } else {
                let itemGroup = this.itemGroupData;
                this.applyDefaulConfiForPush(this.itemData);
                this.isLoading = true;
                let cloneItemGroup = new GamePlayGroupItem(itemGroup, this.itemData);
                if(cloneItemGroup.config.genSiblingReady){
                    cloneItemGroup.items = this.siblingUtils.generateSiblingItemsByConfig(cloneItemGroup.config, cloneItemGroup.item);
                    if(cloneItemGroup.items.length === 0){
                        this.textSnackbar = "Failed on auto generate sibling list, after you've change impact type";
                        this.colorSnackbar = 'red';

                        setTimeout(() => {
                            this.isLoading = false;
                        }, 200);
                    } else {
                        this.updateItemGroups(cloneItemGroup);
                    }
                } else {
                    this.siblingUtils.defineDefaultConfig(cloneItemGroup.config, cloneItemGroup.item);
                    this.updateItemGroups(cloneItemGroup);
                }

                this.checkTitleNameAndSync();
            }
        },

        updateItemGroups(itemGroup) {
            let environmentIds = this.itemData.pushStatus;
            let dataPush = {
                backEndData: itemGroup,
                economyData: {
                    environmentIds: environmentIds,
                    itemEconomy: environmentIds.length === 0 ? undefined : new UGSItemInventoryModel(itemGroup)
                },
            };
            ItemApi.updateItemGroups(dataPush)
                .then(result => {
                    console.log("Result updateItemGroups", result);
                    this.textSnackbar = "Item was updated!";
                    this.colorSnackbar = 'success';

                    if(this.itemData.id === undefined){
                        this.itemData.id = result.id;
                    }

                    this.refreshSiblingBTStatus();

                    setTimeout(() => {
                        this.isLoading = false;
                        GlobalEvents.$emit(EVENT_ON_ITEM_SAVED);
                    }, 1500);

                })
                .catch(error => {
                    this.textSnackbar = "Failed to update item: " + error;
                    this.colorSnackbar = 'red';

                    setTimeout(() => {
                        this.isLoading = false;
                    }, 200);
                })
        },
        updateItem(item) {
            ItemApi.updateItem(item)
                .then(result => {
                    console.log("Result updateItem", result);
                    this.textSnackbar = "Item was updated!";
                    this.colorSnackbar = 'success';

                    if(this.itemData.id === undefined){
                        this.itemData.id = result.id;
                    }

                    this.refreshSiblingBTStatus();

                    setTimeout(() => {
                        this.isLoading = false;
                        GlobalEvents.$emit(EVENT_ON_ITEM_SAVED);
                    }, 1500);

                })
                .catch(error => {
                    this.textSnackbar = "Failed to update item: " + error;
                    this.colorSnackbar = 'red';

                    setTimeout(() => {
                        this.isLoading = false;
                    }, 200);
                })
        },

        removeBehaviour: function (join) {
            let filtered = this.itemData.behaviours.filter(function (behaviour) {
                return behaviour.behaviourBean.join !== join
            });
            this.itemData.behaviours = filtered;
            this.refreshItemColor();
            this.refreshItemTarget();
            this.refreshSignLifeTimeDirect();
            this.validateItemFormDescription();

            //refresh sibling button every update
            this.refreshSiblingBTStatus();

            this.refreshImpactConfig();

            //refresh lifetime
            this.itemData.lifeTime.bean = null;
        },
        editBehaviour: function (join) {
            let indexToEdit = lodash.findIndex(this.itemData.behaviours, function (bh) {
                return bh.behaviourBean.join === join;
            })

            this.formBehaviour = lodash.cloneDeep(this.itemData.behaviours[indexToEdit]);
            this.formBehaviour.isEdit = true;
            this.formBehaviour.indexToEdit = indexToEdit;
            this.showFormBehaviour = true;
            this.refreshSignLifeTimeDirect();
            this.validateItemFormDescription();

            //refresh sibling button every update
            this.refreshSiblingBTStatus();
        },
        close() {
            this.$emit('close');
        },

        //listening
        addVisualEffect(formBehaviour) {
            let isChangeDuration = false;
            if (formBehaviour.isEdit) {
                let indexToEdit = formBehaviour.indexToEdit;
                if (indexToEdit >= 0) {
                    formBehaviour.isEdit = null;
                    //this.formBehaviour.gamePlayImpact.duration
                    let behBeforeEdit = this.itemData.behaviours[indexToEdit];
                    isChangeDuration = behBeforeEdit && behBeforeEdit.gamePlayImpact.duration !== formBehaviour.gamePlayImpact.duration;
                    this.itemData.behaviours.splice(indexToEdit, 1, formBehaviour);
                }
            } else {
                this.itemData.behaviours.push(formBehaviour);
            }

            //refresh color
            this.refreshItemColor();

            //Item target
            this.refreshItemTarget();

            if(isChangeDuration){
                this.textSnackbar = "Make sour the life time will be okay with new impact duration update!!!";
                this.colorSnackbar = 'orange';
                this.timeoutSnackbar = 5000;
            }
            /*disable clear life time after change effect
           //refresh lifetime
           this.itemData.lifeTime.bean = null;
            */
        },
        getMainBehaviour(){
            if (this.itemData.behaviours.length > 0){
                let mainBehaviour = this.itemData.behaviours[0];
                return mainBehaviour;
            } else {
                return null;
            }
        },
        refreshItemColor(){
            let mainBehaviour = this.getMainBehaviour();
            if (mainBehaviour){
                this.itemData.color = BehaviourUtils.getColorNameByCat(mainBehaviour.behaviourBean.cat);
            } else {
                this.itemData.color = null;
            }
        },
        refreshItemTarget(){
            let mainBehaviour = this.getMainBehaviour();
            if (mainBehaviour) {
                this.itemData.targetSelect = mainBehaviour.behaviourBean.owners;
                this.itemData.usageRules = mainBehaviour.behaviourBean.usageRules;


            }
        },
        refreshImpactReceivers(){
            if (this.itemData.behaviours.length > 0){
                this.itemData.behaviours.forEach(behaviour =>{
                    behaviour.gamePlayImpact.receivers = [];
                    behaviour.behaviourBean.receivers.forEach(rc => {
                        behaviour.gamePlayImpact.receivers.push(rc.value);
                    });
                });
            }
        },

        initializeItemData() {
            this.itemGroupData = lodash.cloneDeep(this.pItemGroupData);
            this.itemData = this.itemGroupData.item;
            this.isNewItemForm = this.itemData.status === undefined || this.itemData.status === "new";
            this.isFromAutoMate = this.itemData.status !== undefined && this.itemData.status.includes('automate');

            this.validateIconNameMatchingBehaviours();
            this.backUpIconName = this.itemData.iconName;

            if(this.itemData.gameLevel && (!this.itemData.gameLevel.kpiLevel || this.itemData.gameLevel.kpiLevel < 0)){
                this.refreshKpiGameLevel();
            }

            this.refreshItemTarget();
            this.refreshImpactReceivers();
            this.refreshSignLifeTimeDirect();
            this.validateItemFormDescription();

            this.isBTShowSibling = this.isShowSiblingBT();
            this.isBTShowPushing = !this.isNewItemForm && !this.isFromAutoMate && !this.isPushReady();
            this.isBTShowDeleting = !this.isNewItemForm && !this.isFromAutoMate && this.isPushReady();
            this.isBTShowHardDeleting = !this.isNewItemForm && !this.isFromAutoMate;

            this.lastItemStatus = this.itemData.status;
            if(this.lastItemStatus === "showListSibling" || this.lastItemStatus === 'automateShowListSibling'){
                //this.showSibling();
            }

            if(!this.isNewItemForm){
                this.lastTitleName = this.itemData.title.en;
            }
        },
        isUpdateSiblingForm(){
            return this.lastItemStatus === "updateSibling";
        },
        validateIconNameMatchingBehaviours(){
            let mainBehaviour = this.getMainBehaviour();
            if (mainBehaviour && mainBehaviour.behaviourBean && mainBehaviour.behaviourBean.join){
                let shouldCancel = false;
                if (this.itemData.category === ItemCategoryNames.potion){
                    if (!mainBehaviour.behaviourBean.join.includes('potion')){
                        shouldCancel = true;
                    }
                }else {
                    if (mainBehaviour.behaviourBean.join.includes('potion')){
                        shouldCancel = true
                    }
                }
                if (shouldCancel){
                    this.textSnackbar = "New icon not match with the behaviours! Please choose new icon that match or remove the behaviours";
                    this.colorSnackbar = 'red accent-2';
                    this.timeoutSnackbar = 6000;

                    if (this.backUpIconName) {
                        this.itemData.iconName = this.backUpIconName;
                        this.setEquipmentCodeByWeaponName(this.itemData.iconName);
                    }
                }
            }
        },

        /*not auto disable battle for inc-speed
        refreshGameMode(){
            let mainBehaviour = this.getMainBehaviour();
            if(mainBehaviour){
                this.itemData.modes = GameModeUtils.getGameMode(mainBehaviour);
                console.log(this.itemData.modes);
            }
        },*/

        validateItemFormDescription() {
            let description = "";

            //impact
            let behavArray = [];

            this.itemData.behaviours.forEach(behaviour => {
                if (behaviour.behaviourBean.des && behaviour.behaviourBean.des.details){
                    behavArray.push(behaviour.behaviourBean.des.details + " " + this.renderGameplayImpact(behaviour));
                }
            });

            let descBehav = this.getSplitDescriptionOnArray(behavArray);
            if (descBehav !== "") {
                description += descBehav;
            }

            //mode
            let descMode = this.getSplitDescriptionOnArray(this.itemData.modes);
            if (descMode !== "") {
                description += " use on " + descMode + " mode.";
            }

            //target
            if (this.itemData.targetSelect) {
                let descTarget = this.getOrSplitDescriptionOnArray(this.itemData.targetSelect);
                if (descTarget !== "") {
                    let isNewLine = this.description !== "";
                    if (isNewLine) {
                        description += "<br/>";
                    }
                    if(this.itemData.category === ItemCategoryNames.potion){
                        description += "It can been used by " + descTarget;
                    }else{
                        description += "It can been equipped on " + descTarget;
                    }
                }
            }

            //life time
            let descLife = this.getLifeTimeDescription();
            if (descLife !== "") {
                description += " and " + descLife + ".";
            } else {
                description += ".";
            }

            this.itemFormDescription = description;
        },
        getSplitDescriptionOnArray(array) {
            let desc = "";
            for (let i = 0; i < array.length; i++) {
                if (desc !== "") {
                    if (i === array.length - 1) {
                        desc += " and ";
                    } else {
                        desc += ", ";
                    }
                }
                desc += array[i];
            }
            return desc;
        },
        getOrSplitDescriptionOnArray(array) {
            let desc = "";
            for (let i = 0; i < array.length; i++) {
                if (desc !== "") {
                    desc += " or ";
                }
                desc += array[i];
            }
            return desc;
        },
        getLifeTimeDescription() {
            let desc = "";
            let lifeTime = this.itemData.lifeTime;
            if (lifeTime !== null) {
                if (lifeTime.type === "OneShot") {
                    desc = "can use one time"
                } else if (lifeTime.type === "DurationInSeconds") {
                    desc = "break in " + lifeTime.limit + " seconds";
                } else if (lifeTime.type === "NumOfTaps") {
                    desc = "break in " + lifeTime.limit + " taps";
                } else if (lifeTime.type === "NumOfGotHits") {
                    desc = "break in " + lifeTime.limit + " hits";
                } else if (lifeTime.type === "Forever") {
                    desc = "never broken";
                } else if (lifeTime.type === "NumOfImpacts") {
                    desc = "break after impact " + lifeTime.limit + " times";
                }
            }
            return desc;
        },
        rulesNotEmpty (fieldName) {
            return [
                v => !!v || fieldName + ' is required!'
            ];
        },
        refreshSiblingBTStatus(){
            if(!this.isInitialize) return;

            let successful = this.$refs.form.validate();
            if (successful){
                this.isBTShowSibling = true;
            }

            this.isBTShowPushing = successful && !this.isFromAutoMate && this.itemData.id !== undefined && !this.isPushReady();
            this.isBTShowDeleting = successful && !this.isFromAutoMate && this.itemData.id !== undefined && this.isPushReady();
        },
        initSiblingUtil(){
            let gamePlayDataService = this.$store.state.gamePlayDataService;
            this.siblingUtils = new SiblingItemMultipleImpactUtils(gamePlayDataService);
            ItemDeleteUtils.initDataStore(this.$store);
            ItemUGSPushUtils.initDataStore(this.$store);
        },
        onFailedLocalize(){
            this.isLoading = true;
            this.textSnackbar = "Failed to auto convert localize from unicode to limon";
            this.colorSnackbar = 'red';

            setTimeout(() => {
                this.isLoading = false;
            }, 500);
        },
        setEquipmentCodeByWeaponName(name){
            let weaponId = "";
            let foundInWeapon = this.refEquipmentData.find(ref => ref.weaponName === name);
            if (foundInWeapon) {
                weaponId = foundInWeapon.weaponId;
            }
            else {
                let foundInShield = this.refShieldData.find(ref => ref.weaponName === name);
                if (foundInShield) {
                    weaponId = foundInShield.weaponId;
                }
            }
            this.itemData.equipmentCode = weaponId.toString();
        },
        checkTitleNameAndSync(){
            let titleName = this.itemData.title.en;
            if(this.lastTitleName !== titleName){
                let refWeaponNames = this.$store.state.itemWeaponNames;
                let refPotionNames = this.$store.state.itemPotionNames;
                let itemNameList = this.$store.state.itemNameList;
                //increase
                let name = itemNameList.find(itn => itn.name === titleName);
                if(name){
                    name.usage++;
                } else{
                    name = {
                        name: this.itemData.title.en,
                        name_fr: this.itemData.title.fr,
                        name_es: this.itemData.title.es,
                        name_km: this.itemData.title.km_u,
                        usage: 1,
                    };
                    itemNameList.push(name);
                }
                let wpName = refWeaponNames.find(itn => itn.name === titleName);
                if(wpName){
                    wpName.usage++;
                }
                let psName = refPotionNames.find(itn => itn.name === titleName);
                if(psName){
                    psName.usage++;
                }
                //decrease
                if(this.lastTitleName !== ''){
                    name = itemNameList.find(itn => itn.name === this.lastTitleName);
                    if(name && name.usage > 0){
                        name.usage--;
                    }
                    wpName = refWeaponNames.find(itn => itn.name === this.lastTitleName);
                    if(wpName && wpName.usage > 0){
                        wpName.usage--;
                    }
                    psName = refPotionNames.find(itn => itn.name === this.lastTitleName);
                    if(psName && psName.usage > 0){
                        psName.usage--;
                    }
                }
                this.$store.commit(this.$store.state.commitActions.PUSH_ITEM_NAME, itemNameList);
            }
        },
    },
    computed: {

        supportedHeroes () {
            let heroeIds = [];
            if (this.itemData.equipmentCode.length > 0){
                this.refHeroAndWeaponData.forEach(hd => {
                    let code = parseInt(this.itemData.equipmentCode);
                    if (hd.weaponIds.includes(code)){
                        heroeIds.push(hd.heroId);
                    }
                });
                return heroeIds;
            }else {
                return heroeIds;
            }
        },

        computeRefLifeTime () {
            let refLifeTimes = [];
            let mainBehave = this.getMainBehaviour();
            if (mainBehave){
                refLifeTimes = LifeTimeUtils.getLifeTimeShortcutList(mainBehave, false);
            }
            return refLifeTimes;
        },

        computeWindowHeight() {
            const height = window.innerHeight || document.documentElement.clientHeight ||
                document.body.clientHeight;
            return height * 0.8;
        },
        getFormTitle() {
            let title = "";
            if (this.isNewItemForm) {
                title += "NEW";
            } else {
                title += "UPDATE";
            }
            return title;
        },
        getPossibleBoarderIcon() {
            return this.itemData.color;
        },
        behaviourNeedTargetSelect(){
            return this.refTargetSelects.length > 1;
        },
        shouldShowModeSelect(){
            if(!this.itemData.targetSelect) return true;
            if (lodash.includes(this.itemData.targetSelect, ItemTargets.menu)) {
                this.clearModeSelect();
                return false;
            }
            return true;
        },
    },
    created() {
        this.initSiblingUtil();
        this.initializeItemData();
        this.isInitialize = true;
    },
    watch: {
        pItemGroupData() {
            this.initializeItemData();
            this.setEquipmentCodeByWeaponName(this.itemData.iconName);
        },
    },
    async beforeMount() {
        this.initDefaultFormBehavior();
        this.refEquipmentData = await EquipmentDataLoader.loadData();
        this.refHeroAndWeaponData = await HeroAndWeaponDataLoader.loadData();
        this.refShieldData = await ShieldDataLoader.loadData();
        this.setEquipmentCodeByWeaponName(this.itemData.iconName);
    }
}
</script>

<style scoped>
   #helpInfo .col { max-width: 11%; }
   #helpInfo .v-input--selection-controls { margin-top: 0;}
</style>