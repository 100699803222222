let SMPNum = require("../../SMPNum");

class SMPHeroGamePlay {

    UNLOCK_HERO_STAGE_COUNT = 10;
    MAX_UNLOCK_HERO = 8;

    constructor(mathGamePlay){
        this.mathGamePlay = mathGamePlay;
    }

    GetHeroCost(heroLevel){
        return this.mathGamePlay.GetUnBaseOnLevel(heroLevel, "CostHero");
    }

    GetHeroDMG(heroLevel) {
        return this.mathGamePlay.GetUnBaseOnLevel(heroLevel, "DMGHero");
    }

    GetHeroCountUnlockOnLevel(level) {
        let count = Math.floor(level / this.UNLOCK_HERO_STAGE_COUNT);
        if (count > this.MAX_UNLOCK_HERO) {
            count = this.MAX_UNLOCK_HERO;
        }
        return count;
    }

    GetCostUpdateHero(fromLevel, toLevel){
        let cost = this.mathGamePlay.SumBaseOnCurrentLvAndTargetLv(fromLevel, toLevel, "CostHero");
        cost.round();
        return cost;
    }

    GetMaxLevelOnBaseCost(currentlv, gold){
        let level = this.mathGamePlay.GetMaxLevelThatCanBuyWithExistingGold(gold, currentlv, "CostHero");
        return level;
    }

    // We don't use this method any more. Please check getHeroHp in GamePlayDataService
    GetHeroHp(heroId, heroLevel){
        //let heroHp = ((heroId - 1)*0.2 + 1) * heroLevel * new SMPNum(1.06).pow(heroLevel)  + 20;
        let smp1 = SMPNum.fromNum(((heroId - 1) * 0.2 + 1) * heroLevel);
        let smp2 = new SMPNum(1.06);
        let hp = SMPNum.multSmpNum(smp1, smp2.PowfromInt(heroLevel));
        let maxHp = SMPNum.plus(hp, SMPNum.fromNum(20));
        return maxHp;
    }
}

module.exports = SMPHeroGamePlay;