import {PeriodTypeNames} from "@/item-editor/constants/GamePlayImpactType";
import {ItemLifeTimeNames} from "@/item-editor/constants/ItemLifeTime";

let SMPNum = require('@/SMPNum');
const IS_APPLY_PERIOD_IMPACT = true;

class ItemSimulatorUtils  {

    constructor (itemImpactBonus, gamePlayService) {
        this.itemImpactBonus = itemImpactBonus;
        this.gamePlayService = gamePlayService;
        this.initItemUtils();
    }

    getItemCanApply(itemData){
        let isCanApply = false;
        itemData.behaviours.forEach(behaviour =>{
            if(this.isHasImplementImpact(behaviour.type)){
                isCanApply = true;
            }
        });
        return isCanApply;
    }

    getItemCanApplyModeOption(itemData){
        let isCanApply = false;
        itemData.behaviours.forEach(behaviour =>{
            if(this.isDPSBonusType(behaviour.type)){
                isCanApply = true;
            }
            if(this.isDMGBonusType(behaviour.type)){
                isCanApply = true;
            }
        });
        return isCanApply;
    }

    getBehaviourCanApply(behaviour){
        return this.isHasImplementImpact(behaviour.type);
    }

    clearImpactOfAnyItem(){
        this.itemImpactBonus.dmgImpactBonus.setNumDouble(0);
        this.itemImpactBonus.dpsPercentBonus = 0;
        this.itemImpactBonus.dpsImpactBonus.setNumDouble(0);
        this.itemImpactBonus.goldImpactBonus.setNumDouble(0);
        this.itemImpactBonus.goldEnemyDropBonus.setNumDouble(0);

        this.itemImpactBonus.hpByRefillImpact.setNumDouble(0);
        this.itemImpactBonus.hpByIncHpImpact.setNumDouble(0);
        this.itemImpactBonus.hpImpactBonus.setNumDouble(0);
        this.itemImpactBonus.hpPercentByRefill = 0;
        this.itemImpactBonus.hpPercentByIncHp = 0;
        this.itemImpactBonus.hpPercentBonus = 0;

        this.itemImpactBonus.supportSpeedPercentBonus = 0;

        this.itemImpactBonus.dmgImpactBonusBossOnly.setNumDouble(0);
        this.itemImpactBonus.dmgImpactBonusNormalOnly.setNumDouble(0);

        this.itemImpactBonus.dpsPercentBonusBossOnly = 0;
        this.itemImpactBonus.dpsPercentBonusNormalOnly = 0;
        this.itemImpactBonus.dpsImpactBonusBossOnly.setNumDouble(0);
        this.itemImpactBonus.dpsImpactBonusNormalOnly.setNumDouble(0);

        this.itemImpactBonus.reviveHeroItem = undefined;
        this.itemImpactBonus.reviveSupportItem = undefined;
    }

    applyImpactOfItemData(itemApplySelecteds, gameLevel, isCheckApply, itemRequirePayload){
        //clear
        this.clearImpactOfAnyItem();
        itemApplySelecteds.forEach(itemApplySelected => {
            if(itemApplySelected.isApply || !isCheckApply){
                itemApplySelected.itemData.behaviours.forEach(behaviour =>{
                    if(this.isHasImplementImpact(behaviour.type)){
                        if(this.isReviveHeroType(behaviour.type)){
                            this.itemImpactBonus.reviveHeroItem = 1;
                        } else if(this.isReviveSupportType(behaviour.type)){
                            this.itemImpactBonus.reviveSupportItem = 1;
                        } else if(this.isSupportSpeedBonusType(behaviour.type)){
                            let percent = 0;
                            if(behaviour.gamePlayImpact.valueType === 'DMGToPercent'){
                                let bossHp = this.gamePlayService.getBossHP(gameLevel);
                                let dmg = SMPNum.fromPowerString(behaviour.gamePlayImpact.dmgToPercent);
                                let reduceResult = SMPNum.divSmpNum(dmg, bossHp);
                                percent = SMPNum.multSmpNum(reduceResult, new SMPNum(100)).ToDoubleIfPossible();
                            } else {
                                percent = behaviour.gamePlayImpact.value;
                            }
                            percent = this.getImpactPercentBonusOnApplyPeriodOfItem(percent, behaviour.gamePlayImpact, itemApplySelected.itemData.lifeTime);
                            if(percent > this.itemImpactBonus.supportSpeedPercentBonus){
                                this.itemImpactBonus.supportSpeedPercentBonus = percent;
                            }
                        } else if(this.isDMGBonusType(behaviour.type)){
                            if(behaviour.gamePlayImpact.valueType === 'DirectValue'){
                                //damage type should find the maximum not plus together
                                let newDmg = SMPNum.fromPowerString(behaviour.gamePlayImpact.value);
                                newDmg = this.getImpactBonusOnApplyPeriodOfItem(newDmg, behaviour.gamePlayImpact, itemApplySelected.itemData.lifeTime);
                                if(SMPNum.greaterThan(newDmg, this.itemImpactBonus.dmgImpactBonus)) {
                                    this.itemImpactBonus.dmgImpactBonus.setFromSMPNum(newDmg);
                                    if (itemApplySelected.modes.Battle) {
                                        this.itemImpactBonus.dmgImpactBonusBossOnly.setFromSMPNum(newDmg);
                                    }
                                    if (itemApplySelected.modes.Normal) {
                                        this.itemImpactBonus.dmgImpactBonusNormalOnly.setFromSMPNum(newDmg);
                                    }

                                    if(itemRequirePayload && itemRequirePayload.dmgRequire && SMPNum.greaterThan(itemRequirePayload.dmgRequire.require, newDmg)){
                                        //to apply highlight for item not use full in market
                                        itemApplySelected.itemData.isUseLess = true;
                                    }
                                }
                            }
                        } else if(this.isDPSBonusType(behaviour.type)) {
                            if(behaviour.gamePlayImpact.valueType === 'DirectValue'){
                                let newDps = SMPNum.fromPowerString(behaviour.gamePlayImpact.value);
                                newDps = this.getImpactBonusOnApplyPeriodOfItem(newDps, behaviour.gamePlayImpact, itemApplySelected.itemData.lifeTime);
                                if(SMPNum.greaterThan(newDps, this.itemImpactBonus.dpsImpactBonus)){
                                    this.itemImpactBonus.dpsImpactBonus.setFromSMPNum(newDps);
                                    if(itemApplySelected.modes.Battle){
                                        this.itemImpactBonus.dpsImpactBonusBossOnly.setFromSMPNum(newDps);
                                    }
                                    if(itemApplySelected.modes.Normal){
                                        this.itemImpactBonus.dpsImpactBonusNormalOnly.setFromSMPNum(newDps);
                                    }
                                }
                            } else {
                                let newPercent = behaviour.gamePlayImpact.value;
                                newPercent = this.getImpactPercentBonusOnApplyPeriodOfItem(newPercent, behaviour.gamePlayImpact, itemApplySelected.itemData.lifeTime);
                                if(newPercent > this.itemImpactBonus.dpsPercentBonus){
                                    this.itemImpactBonus.dpsPercentBonus = newPercent;
                                    if(itemApplySelected.modes.Battle){
                                        this.itemImpactBonus.dpsPercentBonusBossOnly = newPercent;
                                    }
                                    if(itemApplySelected.modes.Normal){
                                        this.itemImpactBonus.dpsPercentBonusNormalOnly = newPercent;
                                    }
                                }
                            }
                        } else if(this.isDefineByCoinDrop(behaviour.type)){
                            let goldBonus = SMPNum.fromPowerString(behaviour.gamePlayImpact.value);
                            goldBonus = this.getImpactBonusOnApplyPeriodOfItem(goldBonus, behaviour.gamePlayImpact, itemApplySelected.itemData.lifeTime);
                            if(this.isIncEnemyGoldDrop(behaviour.type)){
                                let total = SMPNum.plus(this.itemImpactBonus.goldEnemyDropBonus, goldBonus);
                                this.itemImpactBonus.goldEnemyDropBonus.setFromSMPNum(total);
                            } else {
                                let total = SMPNum.plus(this.itemImpactBonus.goldImpactBonus, goldBonus);
                                this.itemImpactBonus.goldImpactBonus.setFromSMPNum(total);
                            }

                        } else if(this.isHPBonusType(behaviour.type)){
                            if(behaviour.gamePlayImpact.valueType === 'DirectValue'){
                                let newHp = SMPNum.fromPowerString(behaviour.gamePlayImpact.value);
                                newHp = this.getImpactBonusOnApplyPeriodOfItem(newHp, behaviour.gamePlayImpact, itemApplySelected.itemData.lifeTime);
                                if(behaviour.gamePlayImpact.periodType === PeriodTypeNames.NumOfTaps){
                                    let riskOfSwordSpanwLess = 0.2;
                                    let hitBossPerSecond = 10 * riskOfSwordSpanwLess;
                                    let limit = behaviour.gamePlayImpact.periodValue;
                                    let secondToReachLimit = limit / hitBossPerSecond;
                                    newHp = SMPNum.divSmpNum(newHp, new SMPNum(secondToReachLimit));
                                }

                                if(this.isHpRefillType(behaviour.type)){
                                    if(SMPNum.greaterThan(newHp, this.itemImpactBonus.hpByRefillImpact)){
                                       this.itemImpactBonus.hpByRefillImpact.setFromSMPNum(newHp);
                                    }
                                } else { //isIncHpType
                                    if(SMPNum.greaterThan(newHp, this.itemImpactBonus.hpByIncHpImpact)){
                                        this.itemImpactBonus.hpByIncHpImpact.setFromSMPNum(newHp);
                                    }
                                }

                            } else {
                                let newPercent = behaviour.gamePlayImpact.value;
                                newPercent = this.getImpactPercentBonusOnApplyPeriodOfItem(newPercent, behaviour.gamePlayImpact, itemApplySelected.itemData.lifeTime);
                                if(this.isHpRefillType(behaviour.type)){
                                    if(newPercent > this.itemImpactBonus.hpPercentByRefill){
                                        this.itemImpactBonus.hpPercentByRefill = newPercent;
                                    }
                                } else { //isIncHpType
                                    if(newPercent > this.itemImpactBonus.hpPercentByIncHp){
                                        this.itemImpactBonus.hpPercentByIncHp = newPercent;
                                    }
                                }
                            }
                            //total hp bonus
                            let totalHp = SMPNum.plus(this.itemImpactBonus.hpByRefillImpact, this.itemImpactBonus.hpByIncHpImpact);
                            this.itemImpactBonus.hpImpactBonus.setFromSMPNum(totalHp);
                            let totalPercent = this.itemImpactBonus.hpPercentByRefill + this.itemImpactBonus.hpPercentByIncHp;
                            this.itemImpactBonus.hpPercentBonus = totalPercent;

                            if(itemRequirePayload && itemRequirePayload.hpRequire && SMPNum.greaterThan(itemRequirePayload.hpRequire.require, totalHp)){
                                //to apply highlight for item not use full in market
                                itemApplySelected.itemData.isUseLess = true;
                            }
                        }
                    }
                });
            }
        });

    }

    getImpactBonusOnApplyPeriodOfItem(smpValue, gamePlayImpact, lifeTime){
        if(!IS_APPLY_PERIOD_IMPACT) return smpValue;

        //console.log('lifeTime', lifeTime);
        let periodInDuration = 0;
        if(gamePlayImpact.periodType === PeriodTypeNames.NumOfGotHits){
            let gotHitCount = gamePlayImpact.periodValue;
            let enemyAttackSpeed = this.gamePlayService.ratiosConfig.hitPerSeconds;
            periodInDuration = gotHitCount * enemyAttackSpeed;
            smpValue = SMPNum.divSmpNum(smpValue, new SMPNum(periodInDuration));
        } else if(gamePlayImpact.periodType === PeriodTypeNames.NumOfTaps){
            let tapCount = gamePlayImpact.periodValue;
            let hitPerSecond = this.gamePlayService.ratiosConfig.hitPerSeconds;
            periodInDuration = tapCount * 10 / hitPerSecond;
            smpValue = SMPNum.divSmpNum(smpValue, new SMPNum(periodInDuration));
        } else if(gamePlayImpact.periodTypes === PeriodTypeNames.DurationInSeconds){
            periodInDuration = gamePlayImpact.periodValue;
            smpValue = SMPNum.divSmpNum(smpValue, periodInDuration);
        }

        if(lifeTime){
            let lifeTimeDuration = periodInDuration;
            if(lifeTime.type === ItemLifeTimeNames.DurationInSeconds){
                lifeTimeDuration = lifeTime.limit;
            } else if(lifeTime.type === ItemLifeTimeNames.NumOfGotHits){
                let gotHitCount = lifeTime.limit;
                let enemyAttackSpeed = this.gamePlayService.ratiosConfig.hitPerSeconds;
                lifeTimeDuration = gotHitCount * enemyAttackSpeed;
            } else if(lifeTime.type === ItemLifeTimeNames.NumOfTaps){
                let tapCount = lifeTime.limit;
                let hitPerSecond = this.gamePlayService.ratiosConfig.hitPerSeconds;
                lifeTimeDuration = tapCount / hitPerSecond;
            }

            if(lifeTimeDuration < periodInDuration){
                let uselessRatio = periodInDuration - lifeTimeDuration;
                console.log('reduce by useless lifeTIme: '+smpValue.ToReadableAlphabetV2()+' => '+SMPNum.divSmpNum(smpValue, new SMPNum(uselessRatio)).ToReadableAlphabetV2())
                smpValue = SMPNum.divSmpNum(smpValue, new SMPNum(uselessRatio));
            }
        }

        return smpValue;
    }

    getImpactPercentBonusOnApplyPeriodOfItem(percent, gamePlayImpact, lifeTime){
        if(!IS_APPLY_PERIOD_IMPACT) return percent;

        //console.log('lifeTime',lifeTime);
        let periodInDuration = 0;
        if(gamePlayImpact.periodType === PeriodTypeNames.NumOfGotHits){
            let gotHitCount = gamePlayImpact.periodValue;
            let enemyAttackSpeed = this.gamePlayService.ratiosConfig.hitPerSeconds;
            periodInDuration = gotHitCount * enemyAttackSpeed;
            percent = percent / periodInDuration;
        } else if(gamePlayImpact.periodType === PeriodTypeNames.NumOfTaps){
            let tapCount = gamePlayImpact.periodValue;
            let hitPerSecond = this.gamePlayService.ratiosConfig.hitPerSeconds;
            periodInDuration = tapCount / hitPerSecond;
            percent = percent / periodInDuration;
        } else if(gamePlayImpact.periodTypes === PeriodTypeNames.DurationInSeconds){
            periodInDuration = gamePlayImpact.periodValue;
            percent = percent / periodInDuration;
        }

        if(lifeTime){
            let lifeTimeDuration = periodInDuration;
            if(lifeTime.type === ItemLifeTimeNames.DurationInSeconds){
                lifeTimeDuration = lifeTime.limit;
            } else if(lifeTime.type === ItemLifeTimeNames.NumOfGotHits){
                let gotHitCount = lifeTime.limit;
                let enemyAttackSpeed = this.gamePlayService.ratiosConfig.hitPerSeconds;
                lifeTimeDuration = gotHitCount * enemyAttackSpeed;
            } else if(lifeTime.type === ItemLifeTimeNames.NumOfTaps){
                let tapCount = lifeTime.limit;
                let hitPerSecond = this.gamePlayService.ratiosConfig.hitPerSeconds;
                lifeTimeDuration = tapCount / hitPerSecond;
            }

            if(lifeTimeDuration < periodInDuration){
                let uselessRatio = periodInDuration - lifeTimeDuration;
                console.log('reduce by useless lifeTIme: '+percent+' => '+ (percent / uselessRatio))
                percent = percent / uselessRatio;
            }
        }

        return percent;
    }

    //==============================================
    //=========local================================
    initItemUtils(){
        this.doneImplementImpact = [];

        //isDefineByBossHp
        this.doneImplementImpact.push("damage_enemy");

        //isDefineByCoinDrop
        this.doneImplementImpact.push("inc-gold-drop");
        this.doneImplementImpact.push("inc_gold_enemy_drop");

        //isDefineByDmgBalanceToKillEnemy
        this.doneImplementImpact.push("inc-speed-support");
        this.doneImplementImpact.push("inc_dps");

        //isDefineByBossDMG
        this.doneImplementImpact.push("inc_hp");
        this.doneImplementImpact.push("fill_hp");

        //revive
        this.doneImplementImpact.push('revive_hero');
        this.doneImplementImpact.push('revive_support');
    }

    isHasImplementImpact(impType){
        return this.doneImplementImpact.includes(impType);
    }

    isDMGBonusType(impType){
        return impType === 'damage_enemy';
    }

    isDPSBonusType(impType){
        return impType === 'inc_dps';
    }

    isIncEnemyGoldDrop(impType){
        if(impType === "inc_gold_enemy_drop"){
            return true;
        }
        return false;
    }

    isDefineByCoinDrop(impType){
        if(impType === "inc-gold-drop"
            || impType === "inc_gold_enemy_drop"){
            return true;
        }
        return false;
    }

    isHPBonusType(impType){
        if(this.isHpRefillType(impType)
            || this.isIncHpType(impType)){
            return true;
        }
        return false;
    }

    isHpRefillType(impType){
        return impType === "fill_hp";
    }

    isIncHpType(impType){
        return impType === "inc_hp";
    }

    isSupportSpeedBonusType(impType){
        return impType === 'inc-speed-support';
    }

    isDefineByDMGAndConvertToPercent(impType){
        if(impType === "inc-speed-support"){
            return true;
        }
        // else if(this.isPowerUpType(impType)){
        //     return true;
        // } else if(this.isDefineByNumOfTap(impType)){
        //     return true;
        // }
        return false;
    }

    isReviveHeroType(impType){
        return impType === "revive_hero";
    }

    isReviveSupportType(impType){
        return impType === 'revive_support';
    }
}

export default ItemSimulatorUtils;
