import axios from "axios";
import NetworkUtils from "@/NetworkUtils";
import store from "../../store";
const authorize =  `Bearer ${store.getters.getToken}`;
const config = {
    headers: { Authorization: authorize }
};

export default {
    loadData: async function (environmentId) {
        let dataResponse = {};
        let data = await axios.get(NetworkUtils.HOST + "/automate-item-create-data/getByEnvironmentId?environmentId="+environmentId, config);
        if (data.data.length > 0) {
            //console.log('load ',data.data[0]);
            if(data.data[0].simulatorOptions !== undefined){
                dataResponse.simulatorOptions = data.data[0].simulatorOptions;
            }
            dataResponse.list = data.data[0].list;
        } else {
            dataResponse.list = [];
        }
        if(dataResponse.simulatorOptions === undefined || dataResponse.simulatorOptions === null){
            dataResponse.simulatorOptions = {
                isIncludeOptional: false,
                optionalRatioItem: 0.5,
                optionalRatioGoldItem: 2,
                isGenBalanceItem: true,
                isCustomRevive: false,
            };
        }
        return dataResponse;
    },

    async pushAutomateItemCreateData(automateItemCreatedData, environmentId) {
        let config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: authorize
            },
        };
        let data = JSON.stringify(automateItemCreatedData);
        console.log('pushAutomateItemCreateData ', config);
        await axios.post(NetworkUtils.HOST+"/automate-item-create-data/insertOrUpdate?environmentId="+environmentId, data, config);
    },

}