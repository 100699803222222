import SMPNum from "@/SMPNum";

class Chain3 {
     constructor(service, successor) {
          this.service = service;
          this.successor = successor;
     }
     handleRequest(payload){
          //get only required param
          const {levelSimulateData, goldCanFarm, itemImpactBonus, waveValue, percentageGoldAllBonus} = payload;

          //cumulate gold won (first time => percentageGoldAllBonus = 0)
          // const cumulatedGoldWon = this.service.getTotalGoldOnLevelReach(
          //     cumulatedGoldWonAfterReachingTotalEnemies,
          //     cumulatedGoldFromQuest, percentageGoldAllBonus
          // );

          let gold = "number-is-zero";
          if(levelSimulateData !== undefined){
               gold = levelSimulateData.golddouble;
          }
          const beginGold = SMPNum.fromPowerStringOrZero(gold);

          let goldWon = SMPNum.plus(beginGold, goldCanFarm);

          let goldBonus = SMPNum.fromNum(0);
          if(!itemImpactBonus.goldImpactBonus.isZero){
               goldBonus = SMPNum.plus(goldBonus, itemImpactBonus.goldImpactBonus);
          }
          if(!itemImpactBonus.goldEnemyDropBonus.isZero){
               let bonusAllWave = SMPNum.multSmpNum(itemImpactBonus.goldEnemyDropBonus, SMPNum.fromNum(waveValue));
               goldBonus = SMPNum.plus(goldBonus, bonusAllWave);
          }
          const goldItemBonus = goldBonus;

          goldWon = SMPNum.plus(goldWon, goldItemBonus);

          //plus debug gold bonus
          if(percentageGoldAllBonus > 0){
               goldWon = SMPNum.plus(goldWon, SMPNum.multSmpNum(goldWon, new SMPNum(percentageGoldAllBonus)));
          }

          const cumulatedGoldWon = goldWon;


          // const goldItemBonus = itemImpactBonus.goldImpactBonus;

          //prepare new payload
          let newPayload = {
               cumulatedGoldWon,
               beginGold,
               goldItemBonus
          }

          //merge old and new to return payload
          let returnPayload = Object.assign({}, payload, newPayload)

          //forward to another successor or return
          if (this.successor) {
               return this.successor.handleRequest(returnPayload);
          } else {
               return returnPayload;
          }
     }
}

export default Chain3;