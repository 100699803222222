let gamePlayConstant = {
    //for boss
    bossAttackPerLevelCount: 10,//one level, boss possible attack 10 times

    //for hero and support
    kill_enemy_in_second: 1,//time to kill one in enemy in 1 seconds
    supportAttackCountBossDie: 5,//one boss, support need to attack 5 times

    //for item appear rule
    min_stage_hero_skill_unlock: 120,//automate static, first power up unlocked at stage 120
    min_stage_pet_unlock: 10,
    min_stage_boss_stunt_item: 100,
};

module.exports = gamePlayConstant;