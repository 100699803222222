<template>
    <v-card elevation="0" min-width="600" light>
        <HeaderPopup
            @save="addVisualEffect"
            @close="close()"
        >
        </HeaderPopup>

        <v-container class="grey lighten-5">
            <v-form ref="form" class="mx-2" lazy-validation>
                <div class="blue lighten-5 my-2 py-2 row-pointer"
                     @click="clickToSelectBehaviourType">
                        <v-row no-gutters dense justify="start">
                            <v-col cols="1">
                                <v-btn
                                    class="mx-2"
                                    text
                                    icon
                                    color="green lighten-2"
                                >
                                    <v-icon>mdi-table-edit</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col align-self="center">
                                {{renderGameplayImpact()}}
                                <InputValidate :rules="[!!formBehaviour.behaviourBean || 'Please choose a behaviour!']" />
                            </v-col>
                        </v-row>


                </div>


                <SmpDialog :should-show="shouldShowBehaviourTypeSelector"
                @close="whenNotChooseAnyBehaviour"
                >
                    <BehaviourTypeSelector
                        :pItemCategory="pItemCategory"
                        @didSelectBehaviour="didSelectBehaviour"
                        v-if="shouldShowBehaviourTypeSelector">

                    </BehaviourTypeSelector>
                </SmpDialog>


                <VisualEffectOfTargets
                    :pEffectSlots="this.getEffectSlots()"
                    :pVisualEffectOfTargets="this.getVisualEffectOfTargets()"
                    :pBehaviourBean="this.formBehaviour.behaviourBean"
                    @eventRefreshVisualAndTargets="eventRefreshVisualAndTargets"
                />

                <div v-if="shouldShowGameImpactByBehaviour()">
                    <h4>Gameplay Impact</h4>
                    <v-row no-gutters dense class="my-3 ml-3">
                        <v-col>
                            <v-combobox
                                v-if="!isShowSliderPercentage"
                                label="Type of value(valueType)"
                                :items="valueTypeGroupByBehaviour()"
                                @change="onValueTypeChanged"
                                v-model="formBehaviour.gamePlayImpact.bean.valueType"
                                clearable
                                hide-selected
                                dense
                                :rules="[!!formBehaviour.gamePlayImpact.valueType || 'Value type is required!']"
                            ></v-combobox>
                        </v-col>
                    </v-row>

                    <v-row no-gutters dense class="ml-3">
                        <v-col md="6">
                            <v-slider
                                    v-if="isShowSliderPercentage"
                                    v-model="sliderPercentage.val"
                                    :label="sliderPercentage.label"
                                    :thumb-color="sliderPercentage.color"
                                    @change="onValueSliderPercentageChanged"
                                    thumb-label
                                    max=100
                                    step="0.25"
                            >
                                <template v-slot:append>
                                    <v-text-field
                                            v-model="sliderPercentage.val"
                                            @input="onValueSliderPercentageChanged"
                                            class="mt-0 pt-0"
                                            type="number"
                                            min="0"
                                            max=100
                                    ></v-text-field>
                                </template>

                            </v-slider>
                        </v-col>
                    </v-row>

                    <v-row no-gutters dense class="ml-3 mb-3">
                        <v-col>
                            <v-combobox
                                label="Type of period(periodType)"
                                v-if="shouldShowComboboxPeriodType()"
                                :items="refPeriodTypes"
                                @change="onPeriodTypeChanged"
                                v-model="formBehaviour.gamePlayImpact.bean.periodType"
                                clearable
                                hide-selected
                                dense
                                :rules="[
                                    shouldValidateRequirePeriodType
                                    || !formBehaviour.gamePlayImpact.periodValue
                                    || !!formBehaviour.gamePlayImpact.periodType
                                    || 'Required or keep both empty!' ]"
                            ></v-combobox>
                        </v-col>
                    </v-row>

                    <v-row no-gutters dense class="ml-3">
                        <v-col md="6">
                            <v-combobox
                                label="Type of skill(skillType)"
                                v-if="shouldShowComboboxSkillType()"
                                :items="refActiveSkillTypes"
                                v-model="formBehaviour.gamePlayImpact.skillType"
                                clearable
                                hide-selected
                                dense
                            ></v-combobox>
                        </v-col>
                    </v-row>
                </div>
            </v-form>
        </v-container>
        <PopupMessage v-if="messageAlert!==''"
            pColor="orange"
            @onClose="messageAlert=''"
        >
            {{messageAlert}}
        </PopupMessage>
    </v-card>
</template>

<script>
import SMPNum from "../../../../SMPNum";
import ActiveSkillTypes from "@/item-editor/constants/ActiveSkillType";
import lodash from 'lodash';
import ValueTypeGroupByBehaviourType from "@/item-editor/constants/data-rules/BehaviourWithValueAndPeriod";
import GamePlayImpactType, {PeriodTypeShortcut, ValueTypeShortcut} from "@/item-editor/constants/GamePlayImpactType";
import HeaderPopup from "../../HeaderPopupComponent.vue";
import BehaviourTypeSelector from "@/item-editor/modules/BehaviourForm/screens/BehaviourTypeSelector";
import SmpDialog from "@/item-editor/modules/SmpDialog";
import InputValidate from "@/item-editor/modules/InputVilidateComponent";
import VisualEffectOfTargets from "@/item-editor/modules/BehaviourForm/components/VisualEffectOfTargets";
import {ItemCategoryNames} from "@/item-editor/constants/ItemCategories";
import BehaviourAndPeriodUtils from "../../../Utils/BehaviourAndPeriodUtils";
import PopupMessage from "../../PopupMessage/PopupMessage";

export default {
    name: "NewBehaviourForm",
    props: ['pFormBehaviour','pItemCategory'],
    components: {
        VisualEffectOfTargets, PopupMessage,
        InputValidate, SmpDialog, BehaviourTypeSelector, HeaderPopup},
    data() {
        return {
            refActiveSkillTypes: ActiveSkillTypes,
            refPeriodTypes: [],
            shouldShowBehaviourTypeSelector: false,
            formBehaviour: null,
            sliderPercentage: {label: 'Value(%)', val: 100, color: 'green'},
            isShowSliderPercentage: false,
            messageAlert: '',
        }
    },
    methods: {
        shouldShowEffectSlots () {
            return !!(this.formBehaviour
                && this.formBehaviour.behaviourBean
                && this.formBehaviour.behaviourBean.effectSlots);
        },
        shouldShowComboboxPeriodType() {
            if (!this.formBehaviour.behaviourBean) return false;
            return ValueTypeGroupByBehaviourType[this.formBehaviour.behaviourBean.type]?.periodTypes;
        },
        shouldValidateRequirePeriodType() {
            if (!this.formBehaviour.gamePlayImpact) return false;
            if (!this.shouldShowComboboxPeriodType()) return false;
            if (this.formBehaviour.behaviourBean.type == null) return false;
            let periodValue = this.formBehaviour.gamePlayImpact.periodValue;
            let isRequirePeriodValue = ValueTypeGroupByBehaviourType[this.formBehaviour.behaviourBean.type]?.requirePeriodForEquip ?? false;
            return !isRequirePeriodValue || this.pItemCategory === ItemCategoryNames.potion || periodValue != null;
        },
        shouldShowComboboxSkillType() {
            if (!this.formBehaviour.behaviourBean) return false;
            return ValueTypeGroupByBehaviourType[this.formBehaviour.behaviourBean.type]?.skillTypes;
        },
        shouldShowGameImpactByBehaviour() {
            if (!this.formBehaviour.behaviourBean) return false;
            let valueTypes = ValueTypeGroupByBehaviourType[this.formBehaviour.behaviourBean.type]?.valueTypes;
            return valueTypes && valueTypes.length > 0;
        },
        renderGameplayImpact: function () {
            let des = this.formBehaviour.behaviourBean?.des.details ?? 'Choose a behaviour';
            if (this.formBehaviour.gamePlayImpact.value < 0 || !this.formBehaviour.gamePlayImpact.valueType) {
                return des;
            }
            des += " ";
            let bonus = this.formBehaviour.gamePlayImpact.value;
            let bean = this.formBehaviour.gamePlayImpact.bean;
            if(bean !== null && bean.valueType !== null && bean.valueType.value === "DirectDisplayValue"){
                des += bean.valueType.text;
            } else if(this.formBehaviour.gamePlayImpact.valueType === "DirectValue"){
                des += SMPNum.fromPowerString(bonus).ToReadableAlphabetV2();
            } else {
                des += bonus.toFixed(2);
            }
            des += " "
                + GamePlayImpactType.valueTypeSymbols[this.formBehaviour.gamePlayImpact.valueType];

            if (this.formBehaviour.gamePlayImpact.periodValue > 0) {
                des += ' every ' + this.formBehaviour.gamePlayImpact.periodValue
                    + " "
                    + GamePlayImpactType.periodTypeSymbols[this.formBehaviour.gamePlayImpact.periodType];
            }
            if (this.formBehaviour.gamePlayImpact.skillType) {
                des += ' for ' + this.formBehaviour.gamePlayImpact.skillType
            }

            return des;
        },

        getEffectSlots () {
            return this.formBehaviour?.behaviourBean?.effectSlots;
        },
        getVisualEffectOfTargets () {
            return this.formBehaviour?.gamePlayImpact?.visualEffectOfTargets
        },

        clickToSelectBehaviourType(){
            this.shouldShowBehaviourTypeSelector = true;
        },

        didSelectBehaviour(item) {
            this.shouldShowBehaviourTypeSelector = false;
            this.formBehaviour.behaviourBean = lodash.cloneDeep(item);
            this.onBehaviourChanged();
            this.initSliderPercentage();
        },
        whenNotChooseAnyBehaviour () {
            this.shouldShowBehaviourTypeSelector = false;
            if (!this.formBehaviour.behaviourBean){
                this.close();
            }
        },

        valueTypeGroupByBehaviour() {
            if (!this.formBehaviour.behaviourBean) return [];
            let rules = ValueTypeGroupByBehaviourType[this.formBehaviour.behaviourBean.type]?.valueTypes;

            let refs = [];
            for (const valueTypeShortcutKey in ValueTypeShortcut) {
                let valueShortcut = ValueTypeShortcut[valueTypeShortcutKey];
                if (rules
                    && rules.find(r => r === valueShortcut.type)){
                    refs.push({text: valueShortcut.value + ' ' + valueShortcut.unit, value:valueTypeShortcutKey});
                }
            }
            return refs;
        },

        addVisualEffect() {
            let successful = this.$refs.form.validate();
            if (successful){
                this.formBehaviour.type = this.formBehaviour.behaviourBean.type;
                this.formBehaviour.gamePlayImpact.receivers = [];
                this.formBehaviour.behaviourBean.receivers.forEach(rc => {
                    this.formBehaviour.gamePlayImpact.receivers.push(rc.value);
                });
                this.$emit('addVisualEffect', this.formBehaviour);
                this.$emit('close');
            }
        },

        close() {
            this.$emit('close');
        },

        //listening
        eventRefreshVisualAndTargets(visualEffectOfTargets) {
            this.formBehaviour.gamePlayImpact.visualEffectOfTargets = visualEffectOfTargets;
            let biggest = 0.0;
            visualEffectOfTargets.forEach(vf => {
                if (vf.duration > biggest) biggest = vf.duration;
            });
            this.formBehaviour.gamePlayImpact.duration = biggest;

            let periodType = this.formBehaviour.gamePlayImpact.periodType;
            if(periodType !== null){
                this.messageAlert = "Make sour effect duration was ok with period or life time of item !!!";
            }

            /*disable clear period on update effect
            this.formBehaviour.gamePlayImpact.bean.periodType = null;
            this.formBehaviour.gamePlayImpact.bean.periodValue = null;
            this.formBehaviour.gamePlayImpact.periodType = null;
            this.formBehaviour.gamePlayImpact.periodValue = null;
            this.resetPeriodRef();
             */
        },
        onBehaviourChanged() {
            this.formBehaviour.gamePlayImpact.valueType = null;
            this.formBehaviour.gamePlayImpact.value = null;
            this.formBehaviour.gamePlayImpact.periodType = null;
            this.formBehaviour.gamePlayImpact.periodValue = null;
            this.formBehaviour.gamePlayImpact.skillType = null;
            this.formBehaviour.gamePlayImpact.bean = {};
            this.formBehaviour.gamePlayImpact.visualEffectOfTargets = null;
            this.formBehaviour.gamePlayImpact.duration = null;
        },
        onValueTypeChanged() {
            this.formBehaviour.gamePlayImpact.valueType = null;
            this.formBehaviour.gamePlayImpact.value = null;

            if (this.formBehaviour.gamePlayImpact.bean.valueType) {
                let valueTypeShortcut = ValueTypeShortcut[this.formBehaviour.gamePlayImpact.bean.valueType.value];
                if (valueTypeShortcut) {
                    this.formBehaviour.gamePlayImpact.valueType = valueTypeShortcut.type;
                    this.formBehaviour.gamePlayImpact.value = valueTypeShortcut.value;
                }
            }

            this.formBehaviour.gamePlayImpact.bean.periodType = null;
            this.formBehaviour.gamePlayImpact.bean.periodValue = null;
            this.formBehaviour.gamePlayImpact.periodType = null;
            this.formBehaviour.gamePlayImpact.periodValue = null;
            this.initSliderPercentage();
            this.resetPeriodRef();
        },
        onPeriodTypeChanged(){
            this.formBehaviour.gamePlayImpact.periodType = null;
            this.formBehaviour.gamePlayImpact.periodValue = null;

            if (this.formBehaviour.gamePlayImpact.bean.periodType) {
                let periodTypeShortcut = PeriodTypeShortcut[this.formBehaviour.gamePlayImpact.bean.periodType.value];
                if (periodTypeShortcut){
                    this.formBehaviour.gamePlayImpact.periodType = periodTypeShortcut.type;
                    this.formBehaviour.gamePlayImpact.periodValue = periodTypeShortcut.value;
                }
            }
        },
        resetPeriodRef() {
            this.refPeriodTypes = BehaviourAndPeriodUtils.getPeriodTypeList(this.formBehaviour);
        },

        initSliderPercentage(){
            this.isShowSliderPercentage = false;
            if(this.formBehaviour){
                let isPercentage = this.formBehaviour.gamePlayImpact.valueType === "Percentage";
                if(isPercentage){
                    this.sliderPercentage.val = this.formBehaviour.gamePlayImpact.value;
                    this.isShowSliderPercentage = true;
                } else {
                    if (this.formBehaviour.gamePlayImpact.valueType === null) {
                        let refValueTypes = this.valueTypeGroupByBehaviour();
                        refValueTypes.forEach(r => {
                            if (r.value.includes("Percentage")) {
                                isPercentage = true;
                                this.formBehaviour.gamePlayImpact.bean.valueType = r;
                                return;
                            }
                        });
                        this.isShowSliderPercentage = isPercentage;
                        if (isPercentage) {

                            this.formBehaviour.gamePlayImpact.valueType = "Percentage";
                            this.onValueSliderPercentageChanged();
                            this.resetPeriodRef();
                        }
                    }
                }
            }
        },
        onValueSliderPercentageChanged() {
            let value = parseFloat(this.sliderPercentage.val);
            if (value > 100) {
                value = 100;
                this.sliderPercentage.val = 100;
            }
            this.formBehaviour.gamePlayImpact.value = value;
        },
    },

    beforeMount() {
        this.formBehaviour = lodash.cloneDeep(this.pFormBehaviour);
        this.initSliderPercentage();
        this.resetPeriodRef();
    },
    mounted() {
        if (!this.pFormBehaviour || !this.pFormBehaviour.behaviourBean){
            this.shouldShowBehaviourTypeSelector = true;
        }
    },
}
</script>

<style scoped>
.row-pointer {
    cursor: pointer;
}
</style>