export default [
     {text: "Duration in seconds", value: "DurationInSeconds"},
     {text: "Number of taps", value: "NumOfTaps"},
     {text: "Number of got hits", value: "NumOfGotHits"},
     {text: "Number of impacts", value: "NumOfImpacts"},
     {text: "One shot", value: "OneShot"},
     {text: "Forever", value: "Forever"}
]

export let ItemLifeTimeNames = {
     "DurationInSeconds": "DurationInSeconds",
     "NumOfTaps": "NumOfTaps",
     "NumOfGotHits": "NumOfGotHits",
     "NumOfImpacts": "NumOfImpacts",
     "OneShot": "OneShot",
     "Forever": "Forever",
}

export let ItemLifeTimeShortcut = {
     /*
     DurationExShort: {
          type:'DurationInSeconds',
          key:'DurationExShort',
          value: 5,
          text:'Duration extra short',
          unit: 'seconds'
     },
     DurationShort: {
          type:'DurationInSeconds',
          key:'DurationShort',
          value: 10,
          text:'Duration short',
          unit: 'seconds'
     },
     DurationMedium: {
          type:'DurationInSeconds',
          key:'DurationMedium',
          value: 60,
          text:'Duration medium',
          unit: 'seconds'
     },
     DurationLong: {
          type:'DurationInSeconds',
          key:'DurationLong',
          value: 70,
          text:'Duration long',
          unit: 'seconds'
     },*/
     DurationExLong: {
          type:'DurationInSeconds',
          key:'DurationExLong',
          value: 80,
          text:'Duration extra long',
          unit: 'seconds'
     },
     DurationBigExLong: {
          type:'DurationInSeconds',
          key:'DurationExLong',
          value: 100,
          text:'Duration extra long',
          unit: 'seconds'
     },
     DurationLargeExLong: {
          type:'DurationInSeconds',
          key:'DurationExLong',
          value: 120,
          text:'Duration extra long',
          unit: 'seconds'
     },
     DurationExLargeExLong: {
          type:'DurationInSeconds',
          key:'DurationExLong',
          value: 200,
          text:'Duration extra long',
          unit: 'seconds'
     },

     /*
     NumTapExShort: {
          type:'NumOfTaps',
          key:'NumTapExShort',
          value: 10,
          text:'Number of taps extra short',
          unit: 'taps'
     },
     NumTapShort: {
          type:'NumOfTaps',
          key:'NumTapShort',
          value: 20,
          text:'Number of taps short',
          unit: 'taps'
     },
     NumTapMedium: {
          type:'NumOfTaps',
          key:'NumTapMedium',
          value: 40,
          text:'Number of taps medium',
          unit: 'taps'
     },*/
     NumTapLong: {
          type:'NumOfTaps',
          key:'NumTapLong',
          value: 120,
          text:'Number of taps long',
          unit: 'taps'
     },
     NumTapExLong: {
          type:'NumOfTaps',
          key:'NumTapExLong',
          value: 200,
          text:'Number of taps extra long',
          unit: 'taps'
     },
     NumTapExSLong: {
          type:'NumOfTaps',
          key:'NumTapExLong',
          value: 300,
          text:'Number of taps extra long',
          unit: 'taps'
     },
     NumTapExMLong: {
          type:'NumOfTaps',
          key:'NumTapExLong',
          value: 500,
          text:'Number of taps extra long',
          unit: 'taps'
     },

     /*
     NumGotHitExShort: {
          type:'NumOfGotHits',
          key:'NumGotHitExShort',
          value: 5,
          text:'Number of got hit extra short',
          unit: 'got-hits'
     },*/
     NumGotHitShort: {
          type:'NumOfGotHits',
          key:'NumGotHitShort',
          value: 10,
          text:'Number of got hit short',
          unit: 'got-hits'
     },
     NumGotHitMedium: {
          type:'NumOfGotHits',
          key:'NumGotHitMedium',
          value: 20,
          text:'Number of got hit medium',
          unit: 'got-hits'
     },
     NumGotHitLong: {
          type:'NumOfGotHits',
          key:'NumGotHitLong',
          value: 40,
          text:'Number of got hit long',
          unit: 'got-hits'
     },
     NumGotHitExLong: {
          type:'NumOfGotHits',
          key:'NumGotHitExLong',
          value: 80,
          text:'Number of got hit extra long',
          unit: 'got-hits'
     },
     /*
     NumImpactOnce: {
          type:'NumOfImpacts',
          key:'NumImpactOnce',
          value: 1,
          text:'Number of impact once',
          unit: 'impact'
     },
     NumImpactExShort: {
          type:'NumOfImpacts',
          key:'NumImpactExShort',
          value: 3,
          text:'Number of impacts extra short',
          unit: 'impacts'
     },
     NumImpactShort: {
          type:'NumOfImpacts',
          key:'NumImpactShort',
          value: 5,
          text:'Number of impacts short',
          unit: 'impacts'
     },*/
     NumImpactMedium: {
          type:'NumOfImpacts',
          key:'NumImpactMedium',
          value: 10,
          text:'Number of impacts medium',
          unit: 'impacts'
     },
     NumImpactLong: {
          type:'NumOfImpacts',
          key:'NumImpactLong',
          value: 15,
          text:'Number of impacts long',
          unit: 'impacts'
     },
     NumImpactExLong: {
          type:'NumOfImpacts',
          key:'NumImpactExLong',
          value: 20,
          text:'Number of impacts extra long',
          unit: 'impacts'
     },

     OneShot: {
          type:'OneShot',
          key:'OneShot',
          value: 1,
          text:'One shot',
          unit: 'shot'
     },
     /*
     Forever: {
          type:'Forever',
          key:'Forever',
          value: -1,
          text:'Forever',
          unit: 'Forever'
     },*/

}