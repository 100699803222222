var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"0","min-width":"1000","light":""}},[_c('HeaderPopup',{on:{"save":function($event){return _vm.save()},"close":function($event){return _vm.close()},"reset":function($event){return _vm.reset()},"option":function($event){return _vm.showOption()},"bagSlotOption":function($event){return _vm.bagSlotOption()}}},[_vm._v(" AUTOMATE ITEMS ")]),_c('v-container',{staticClass:"grey lighten-5"},[_c('v-form',{ref:"form",staticClass:"mx-2",attrs:{"lazy-validation":""}},[_c('AutomateConfigDataList',{attrs:{"automateItemCreatedData":_vm.computeAutomateConfigDatas,"categorySmallRef":_vm.categorySmallRef}}),(_vm.generateMsg)?_c('v-row',[_c('v-col',{attrs:{"cols":"0"}},[_c('v-card-text',[_vm._v(" "+_vm._s(_vm.generateMsg)+" ")])],1)],1):_vm._e(),_c('v-row',{staticClass:"mt-2 mb-2"},[_c('v-col',[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"success"},on:{"click":function($event){return _vm.autoGenerateItem()}}},[_vm._v(" Generate Items ")]),_c('v-label',[_vm._v(" On Save, it will sync Inventory Item and Shop with UGS on Environment name "+_vm._s(this.$store.state.ugsSetting.environmentName)+" ")])],1)],1),_c('v-card',{staticClass:"mt-1 mb-0 pb-0"},[_c('v-row',[_c('v-col',{staticClass:"ml-3",attrs:{"cols":"2"}},[_vm._v(" Generated Result: ")]),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"4"}},[_c('v-combobox',{attrs:{"items":_vm.impactSelectList,"small-chips":"","clearable":"","label":"Result by impact","prepend-icon":"mdi-filter-variant","solo":""},on:{"change":_vm.onFilterChangeChange},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"close":""},on:{"click":select,"click:close":function($event){return _vm.removeImpactFilterChosen(item)}}},'v-chip',attrs,false),[_c('strong',[_vm._v(_vm._s(item))]),_vm._v("  ")])]}}]),model:{value:(_vm.impactFilterChosen),callback:function ($$v) {_vm.impactFilterChosen=$$v},expression:"impactFilterChosen"}})],1)],1),_c('v-virtual-scroll',{attrs:{"item-height":"250","height":300,"items":this.optimizedList},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [(item.length > 0)?_c('v-row',{staticClass:"mt-2 pl-3",attrs:{"dense":"","no-gutters":""}},_vm._l((item),function(it){return _c('v-col',{key:it.id,staticClass:"mx-0",attrs:{"cols":"0","sm":"0","md":"0"}},[_c('AutomateCard',{attrs:{"pItemGroupData":it.itemData,"pRenderId":it.renderId}})],1)}),1):_vm._e()]}}])})],1)],1)],1),_c('SmpDialog',{attrs:{"should-show":this.isAutomateOptionShow,"width":"550"},on:{"close":function($event){return _vm.automateOptionClose()}}},[_c('AutomateOptionPopup',{attrs:{"pAutomateConfig":this.automateCreateItemOptions},on:{"applyConfig":function($event){return _vm.defineAutomateItemConfig()},"resetCounter":function($event){return _vm.reset()}}})],1),_c('SmpDialog',{attrs:{"should-show":_vm.isBagSlotOptionShow,"width":"550"},on:{"close":function($event){_vm.isBagSlotOptionShow=false}}},[_c('BagSlotOptionPopup',{attrs:{"pBagSlotConfig":this.bagSlotConfig}})],1),_c('v-overlay',{attrs:{"value":_vm.isLoading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-snackbar',{attrs:{"timeout":_vm.timeoutSnackbar,"color":_vm.colorSnackbar,"centered":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"blue","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.textSnackbar)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }