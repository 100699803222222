import SMPNum from "@/SMPNum";

class Chain8 {
     constructor(service, successor) {
          this.service = service;
          this.successor = successor;
     }
     handleRequest(payload){
          //get only required param
          const {
               // supportCount,
               // supportSkillLevel,
               supportDps,
               isUseSupportSkillDmg,
               isUsePetSkillDmg,
               heroLevelAfterFarm,
               hitPerSecond,
               petLevel,
               gameLevel,
              isUsingPet,
              itemImpactBonus,
               supportList,
               petList,
               heroSkillPayload,
          } = payload;

          const supportPayload2 = {supportList, supportDps, isUseSupportSkillDmg}
          const petPayload = {petList, isUsePetSkillDmg};

          const heroDmg = this.service.kpiGameplayPlayerTapDamage(supportPayload2, petPayload, heroLevelAfterFarm, null, null, heroSkillPayload);
          const heroDps = this.service.computeHeroDps(heroDmg, hitPerSecond);

          let petAvgDps = new SMPNum(0);
          if(isUsingPet){
               petAvgDps = this.service.kpiPetDps(heroDmg, petList, hitPerSecond, heroSkillPayload);
          }

          let currentDps = SMPNum.plus(supportDps, heroDps);
          currentDps = SMPNum.plus(currentDps, petAvgDps);

          let petStandardDmg = new SMPNum(0);
          if(isUsingPet){
               petStandardDmg = this.service.kpiPetGamePlayStandardDMG(heroDmg, petLevel, heroSkillPayload);
          }

          let petPassiveDmg = this.service.kpiPetTotalPassiveDMG(heroDmg, petList);
          let hitsPerGhost = this.service.computeHitPerGhosts(gameLevel, heroLevelAfterFarm);

          const dmgImpactBonus = itemImpactBonus.dmgImpactBonus;
          const totalDpsItemBonus = this.service.getTotalDpsItemBonus(itemImpactBonus, SMPNum.plus(heroDps, supportDps));

          //prepare new payload
          let newPayload = {
               currentDps,
               heroDmg,
               heroDps,
               petStandardDmg,
               petPassiveDmg,
               petAvgDps,
               hitsPerGhost,
               dmgImpactBonus,
               totalDpsItemBonus,
          }

          //merge old and new to return payload
          let returnPayload = Object.assign({}, payload, newPayload)

          //forward to another successor or return
          if (this.successor) {
               return this.successor.handleRequest(returnPayload);
          } else {
               return returnPayload;
          }
     }
}

export default Chain8;